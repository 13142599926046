<template>
  <template v-if="info">
    <div v-if="info.isOffDay" class='date-cell isOffDay'>
      {{info.name}}
    </div>
    <div v-else class="date-cell">
      补班
    </div>
  </template>
</template>

<script>
export default {
  props: {
    info: {
      type: [Object, undefined]
    }
  }
}
</script>

<style scoped>
.date-cell{
  font-size: 12px;
  text-align: center;
  border: 1px solid #eee;
  /* width: 100%; */
  /* height: 100%; */
}
.isOffDay{
  background-color: azure;
}
</style>