export function initDirectives (app) {
  app.directive('focus', {
    // 当被绑定的元素插入到 DOM 中时……
    mounted (el) {
      // console.log('当被绑定的元素插入到 DOM 中时', el)
      el.querySelector('input').focus()
    }
  })

  // 绕过自定义右键菜单
  app.directive('forbidden', {
    // 当被绑定的元素插入到 DOM 中时……
    mounted (el) {
      // console.log('el', el)
      // console.log('当被绑定的元素插入到 DOM 中时', el)
      el.addEventListener('contextmenu', () => {
        console.log('oncontextmenu')
        window.$$VContextmenuCounteract = true
      })
      // el.oncontextmenu = () => {
      //   console.log('oncontextmenu')
      //   window.$$VContextmenuCounteract = true
      // }
    }
  })
}