<template>
  <div>
    <a-modal
      v-model:visible="configModelVisible"
      :width="800"
      title="设置"
      :footer="null"
    >
      <div class="more" ref="more">
        <a-tabs
          class="tabs"
          tab-position="left"
          size="small"
          @change="onTabsChange"
          okText="确认"
          :tabBarStyle="{'text-align': 'left'}"
        >
          <a-tab-pane key="1" tab="账户">
            <a-row type="flex" :gutter="12" >
              <a-col :xs="24" :md="12" :lg="24" :xl="24">
                <AuthCard ref="authCard" @show-reset="resetCardVisible=true"></AuthCard>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="12" >
              <a-col :xs="24" :md="12" :lg="24" :xl="24">
                <transition name="fade">
                  <Reset v-if="resetCardVisible" @cancle="resetCardVisible=false"></Reset>
                </transition>
                <!-- <div class="card"> -->
                <!-- 1 -->
                <!-- </div> -->
              </a-col>
            </a-row>
          </a-tab-pane>

          <a-tab-pane key="2" tab="偏好设置">
            <!-- :label-col="labelCol" :wrapper-col="wrapperCol" -->
            <div >
              <a-form :model="appConfig" :label-col="labelCol">
                <a-form-item label="点按网站图标时">
                  <a-radio-group v-model:value="appConfig.openHabit">
                    <a-radio value="inPlace">
                      在当前页面打开
                    </a-radio>
                    <a-radio value="newPage">
                      在新页面打开
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="保留搜索历史记录" help="搜索记录不会上传，仅在本地保存">
                  <a-switch v-model:checked="appConfig.saveSearchHistory" />
                </a-form-item>
                <a-form-item label="静音模式">
                  <a-switch v-model:checked="appConfig.isSilentMode" />
                </a-form-item>
                <!-- <a-form-item label="开发者模式" help="开启后允许添加自定义组件">
                  <a-switch v-model:checked="appConfig.isDeveloperMode" />
                </a-form-item> -->
              </a-form>
            </div>
          </a-tab-pane>

          <a-tab-pane key="3" tab="更新内容">

            <!-- <a-button size="small" class="margin-left-xs" @click="onNaviToRoadmap"> -->
            <!-- <CalendarOutlined /> -->
            <h2 class="margin-left-xl">
              <a target="_blank" href="https://zhukeqing.notion.site/d7a6a6d76c6a4915a9ea576e7c6b1a51">产品路线</a>
            </h2>
            <!-- </a-button> -->
            <!-- <v-md-preview :text="text" height="200px"></v-md-preview> -->
          </a-tab-pane>

          <!-- <a-tab-pane key="4" tab="更多功能">
            <ImportBookmarks></ImportBookmarks>
          </a-tab-pane> -->

          <a-tab-pane key="5" tab="关于" class="margin-top">
            <a-button type="primary" @click="navitoCommunity"><TeamOutlined />我要反馈</a-button>
          </a-tab-pane>
          <!--
          <a-tab-pane key="5" tab="测试" class="margin-top">
            <test></test>
          </a-tab-pane> -->

        </a-tabs>

      </div>
    </a-modal>
    <div class="top-operate-center" ref="topOperateCenter">

      <div class="bottom">

        <div class="plugin-wrap fr">
          <div id="he-plugin-simple"> - </div>
        </div>

        <div class="rhombus-decorate margin-left"></div>
        <div class="rhombus-decorate"></div>
        <div class="rhombus-decorate"></div>

        <!-- <div id="tp-weather-widget" class="margin-left tp-weather-widget"></div> -->

        <!-- v-model:visible="visible"  -->
        <div class="top-drop-shadow">
          <a-popover trigger="click">
            <template #content>
              <WidgetManager></WidgetManager>
            </template>
            <a-button
              title="包含你已经收起的磁贴"
              class="margin-left hover-effect"
              size="small"><AppstoreAddOutlined />
              小组件</a-button>
          </a-popover>

          <a-button title="设置" size="small" class="margin-left hover-effect" @click="tottleMoreMenuStatus">
            <SettingOutlined ref="SettingOutlined" />设置
          </a-button>
          <a-button v-if="false" @click="showAsyncComponent = true">
            异步加载组件
          </a-button>

          <!-- <div class="add-website-wrap"> -->
          <!-- <a-modal v-model:visible="addWebsiteModalVisible" :title="title" :footer="null"> -->
          <!-- <div ref="editWebsiteCard" class="EditWebsiteCard EditWebsiteCard-animation"> -->
          <EditWebsiteCard ref="EditWebsiteCard" :isCardStyle="false" :tempAddress="tempAddress"></EditWebsiteCard>
          <!-- </div> -->
          <!-- </a-modal> -->
          <!-- <div class="add-website-bth" ref="addWebsiteBtn"> -->
          <!-- class="pure-button" -->
          <a-button
            class="margin-left hover-effect"
            @click="showAddWebsiteModal()"
            size="small"><PlusOutlined />添加站点</a-button>
          <a-button
            class="margin-left hover-effect"
            @click="onAddCollection"
            size="small"><PlusOutlined />添加集合</a-button>
        </div>
        <!-- <AsyncComponent v-if="showAsyncComponent"></AsyncComponent> -->
        <div class="rhombus-decorate margin-left"></div>
        <div class="rhombus-decorate"></div>
        <div class="rhombus-decorate"></div>

        <div class="user-status margin-left-xl fr rect-area" >
          <div v-if="!appState.loginState" style="cursor: pointer">
            <!-- <a-badge status="warning" /> -->
            <a-tooltip :defaultVisible="true">
              <span>
                <span class="pure-button hover-effect" @click="onLogin">登录</span>
                /
                <span class="pure-button hover-effect" @click="onRegister">注册</span>
              </span>
              <template #title>登录后，即可开启多设备云同步</template>
              <!-- Tooltip will show when mouse enter. -->
            </a-tooltip>
          </div>
          <template v-else>
            <!-- <a-badge status="success" /> -->
            <span class="hover-effect" style="padding: 2px" @click="tottleMoreMenuStatus">{{ appState.loginState.user.email || appState.loginState.user.email.nickName }}</span>
          </template>
        </div>

        <!-- <span class="brand rect-area  margin-left" title="跳转到社区" ref="brand" @click="navitoCommunity">
          方格
        </span> -->
      </div>

    </div>
  </div>
</template>

<script>
import { PlusOutlined,SettingOutlined,AppstoreAddOutlined,TeamOutlined,ImportOutlined } from '@ant-design/icons-vue'
// import * as g from 'gsap'
// import { TimelineLite,TweenLite, Power3 } from 'gsap'
import EditWebsiteCard from './EditWebsite/EditWebsiteCard.vue'
import AuthCard from './Auth/AuthCard.vue'
import Reset from './Reset.vue'
// import Test from './Test.vue'
// import AsyncComponent from '@/components/AsyncComponent/AsyncComponent'
import WidgetManager from '@/components/WidgetManager/WidgetManager'
// import ImportBookmarks from './ImportBookmarks/ImportBookmarks'
import { getChangelog } from '@/api/base'

// const timeline = new TimelineLite({paused: true})
// const timeline = window.gsap.timeline({paused: true})
// const gsap = window.gsap
// let websiteBtnTL
// let websiteCardTL

export default {
  inject: ['appState', 'appEventBus'],
  components: {
    PlusOutlined,SettingOutlined,AppstoreAddOutlined,
    EditWebsiteCard, AuthCard, TeamOutlined, WidgetManager, Reset,ImportOutlined,
    // ImportBookmarks
  },
  data() {
    return {
      // 是否是折叠状态
      isFold: true,
      // 网站图标添加器
      addWebsiteModalVisible: false,
      showAsyncComponent: false,
      labelCol: { span: 6 },
      text: '正在获取更新信息...',
      tempAddress: '',

      source: null,

      addWebsiteModalOptions: null,
      configModelVisible: false,

      // 重置账号密码卡片
      resetCardVisible: false
    }
  },
  computed: {
      // 应用配置
    appConfig () {
      return this.appState.appConfig
    },
  },
  mounted () {
    // this.initTopMenuSlide()
    this.appEventBus.on('showAddWebsiteModal', this.$refs.EditWebsiteCard.show)
  // mounted () {
    console.log('auth component init')

    // 判断是否是注册场景
    if (!this.appState.loginState && document.referrer) {
      const referrerURL = new URL(document.referrer)
      console.log('未登录状态', referrerURL)
      const registerInfoStr= localStorage.getItem('registerInfo')
      // redirect.cloudbase.net
      // localhost
      if (referrerURL.hostname === 'redirect.cloudbase.net' && registerInfoStr) {
        this.onLogin(true)
      }
    }
  // },
  },
  watch: {
    // addWebsiteModalVisible (val) {
    //   this.toggleWebsiteBtn3dAnimation(val)
    //   this.toggleWebsiteCardAnimation(val)
    // }
  },
  methods: {
    // initTopMenuSlide(){
    //   timeline.add(gsap.to(this.$refs.more, 0.6, {
    //     y: 332,
    //     ease: 'Power3.easeInOut'
    //   }), '0')

    //   timeline.add(gsap.to(this.$refs.SettingOutlined, 0.6, {
    //     ease: 'Power3.easeInOut',
    //     rotation: '180'
    //   }), '0')
    // },
    onTabsChange(key){
      if (key === '3') {
        getChangelog()
          .then(res => {
            this.text = res
          })
      }
    },
    // onEditWebsiteCancle(){
    //   this.addWebsiteModalVisible = false
    //   this.tempAddress = ''
    // },
    /**
    * target: false
    *
    * @param {boolean} target 对isFold的期望
    * @return {undefined}
    */
    tottleMoreMenuStatus(){
      console.log('tottleMoreMenuStatus')
      this.configModelVisible = !this.configModelVisible
      // if (target === this.isFold) {return}
      // if (this.isFold) {
      //   timeline.play()
      // } else {
      //   timeline.reverse()
      // }
      // this.isFold = !this.isFold
    },

    // ------ 添加网站图标 ------
    showAddWebsiteModal(){
      return this.$refs.EditWebsiteCard.show()
    },
    // initWebsiteBtn3dAnimation(){
    //   if (!websiteBtnTL) {
    //     websiteBtnTL = new window.TimelineLite({paused: true})
    //   }
    //   websiteBtnTL.add(
    //     gsap.to(this.$refs.addWebsiteBtn, 0.6,{
    //       ease: 'Power3.easeInOut',
    //       rotationX: 45,
    //     })
    //   )
    // },
    // 切换添加按钮的动效
    // toggleWebsiteBtn3dAnimation(status){
    //   if (!websiteBtnTL) {
    //     this.initWebsiteBtn3dAnimation()
    //   }
    //   // console.log('toggleWebsiteBtn3dAnimation')
    //   if (status) {
    //     gsap.to(this.$refs.addWebsiteBtn, 0,{
    //       className:'+=drop-shadow add-website-bth',
    //     })
    //     websiteBtnTL.play()
    //   } else{

    //     setTimeout(() => {
    //       gsap.to(this.$refs.addWebsiteBtn, 0,{
    //         className:'+=add-website-bth',
    //       })

    //       websiteBtnTL.reverse()
    //     }, 200)
    //   }
    // },
    // 切换卡片动效
    // toggleWebsiteCardAnimation(status){
    //   if (!websiteCardTL) {
    //     websiteCardTL = new window.TimelineLite({paused: true})
    //     websiteCardTL.add(
    //       gsap.to(this.$refs.editWebsiteCard, 0.1,{
    //         opacity: 1
    //       })
    //     )
    //     websiteCardTL.add(
    //       gsap.to(this.$refs.editWebsiteCard, 0.2,{
    //         y: 10,
    //         scale: 0.1,
    //         opacity: 1
    //       })
    //     )
    //     websiteCardTL.add(
    //       gsap.to(this.$refs.editWebsiteCard, 0.3,{
    //         y: 40,
    //         scale: 1,
    //       })
    //     )
    //   }
    //   if(status) {
    //     console.log('play')
    //     setTimeout(() => {
    //       websiteCardTL.play()
    //     }, 200)
    //   }else{
    //     websiteCardTL.reverse()
    //   }
    // },
    /**
     * 展示登录卡片
     *
     * @param {boolean} isRegisterBack=false - 是否是注册后从邮箱返回的场景，是 则执行自动登录
     * @return {undefined}
    */
    onLogin(isRegisterBack = false){
      this.tottleMoreMenuStatus(false)
      setTimeout(() => {
        const authCard = this.$refs.authCard
        authCard.showReverse('login')
        if (isRegisterBack) {
          setTimeout(() => {
            console.log('fastLogin')
            authCard.fastLogin()
          }, 200)
        }
      }, 300)
    },
    onRegister(){
      this.tottleMoreMenuStatus(false)
      setTimeout(() => {
        this.$refs.authCard.showReverse('register')
      }, 300)
    },

    // ------ 添加收藏夹 ------
    onAddCollection(){
      this.appEventBus.emit('addCollectionTile')
    },

    // 小组件逻辑
    // onWidgetVisibleChange,
    navitoCommunity(){
      this.appEventBus.emit('navitoCommunity')
    },
    // onNaviToRoadmap(){
    //   window.open('https://zhukeqing.notion.site/d7a6a6d76c6a4915a9ea576e7c6b1a51')
    // },
  }
}
</script>

<style lang="less">
// @config-bg: rgba(255,255,255, 0.95);
@config-bg: rgba(255,255,255, 0);


.more{
  position: relative;
  perspective: 6200px;

  max-height: 500px;
  overflow: auto;
  // background-color: rgba(240,240,240,0.8);
  color: var(--font-color) !important;
  // background-color: rgba(230, 239, 245, 0.96);

  // background-color: #fff;
  // background-color: rgba(0,0,0,0.5);
  padding: 10px;
  overflow: auto;


  // backdrop-filter: blur(5px);
  z-index: 3;

  // 通用的卡片组件样式
  .card{
    // height: 200px;
    margin: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 1px rgba(200,200,200,0.4);
    background-color: #F1F1F1;
    .left-icon{
      font-size: 30px;
    }
    .inner{
      padding: 10px 16px;
    }
  }
}
.top-operate-center .ant-tabs .ant-tabs-left-content {
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: hsla(220, 4%, 58%, .3);
  }
}

.drop-shadow{
  filter: drop-shadow(1px 4px 2px #ccc);
}
// .top-drop-shadow{
//   // filter: drop-shadow(0px 0px 1px #fff);
// }
.top-operate-center{
  // position: absolute;
  // transform: translateX(-50%);
  transition: width 0.4s;
  // bottom: 0;
  // width: 60%;
  z-index: 1;
  // height: 336px;
  border-radius:0 0 10px 10px;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .ant-tabs .ant-tabs-left-bar{
    border-right: 1px solid #ccc;
    background-color: rgba(249,249,249, 0.5);
  }

  .ant-tabs-content{
    padding: 20px 20px 20px 0;
  }
  .ant-tabs .ant-tabs-left-content{
    // border-left: 1px solid #ccc;
    height: 100%;
    overflow: auto;
    border-left: none;
  }
  .tabs{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .bottom{
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    border-radius:0 0 2px 2px;
    background-color: @config-bg;
    height: 36px;
    // perspective: 6200px;
    padding: 0 10px;
    color: #ADADAD;
    z-index: 1;
    margin-top: -1px;
    color: #F1F8FF;
    // text-shadow: 0px 0px 2px #c1e2f1;

    &>div{
      flex-shrink: 0;
    }

    .brand{
      font-weight: bold;
      /* text-shadow: -1px 1px 3px #D6D8D8; */
      flex-shrink: 0;
      cursor: pointer;
    }
    .add-website-wrap{
      position: relative;
      padding-left: 15px;

      .EditWebsiteCard{
        position: absolute;
        // width: 200px;
        // height: 400px;
        // top: 40px;
      }
    }

    .add-website-bth{
      // transition: filter 0.6s;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      transform-origin: top;
      // transform: rotateX(40deg);
    }
    .user-status{
      width: 180px;
      // overflow:hidden; //超出的文本隐藏
      text-overflow:ellipsis; //溢出用省略号显示
      white-space:nowrap; //溢出不换行
    }

    .top-drop-shadow > button, .pure-button{
      border: none;
      background-color: rgba(0,0,0,0);
      color: #F1F8FF;
      text-shadow: 0px 0px 2px #c1e2f1;
    }

  }
}
.EditWebsiteCard-animation{
  opacity: 0;
  transform: translate(-138px, -130px) scale(0.1);
}

@media screen and (max-width: 1000px) {
  .top-operate-center{
    width: 100%;
    border-radius:0;
    .bottom{
      border-radius:0;
    }
  }
}

// .tp-weather-widget{
//   cursor: default;
// }

#he-plugin-simple{
  .s-sticker{
    cursor: default !important;
  }
  .wv-lt-refresh{
    opacity: 0.5;
  }
}

.plugin-wrap{
  text-align: center;
  min-width: 152px;
}

// .rhombus-area{
  // padding: 0 20px !important;
  // clip-path: polygon(10px 0%, 100% 0%,  calc(100% - 10px) 100%, 0% 100%,);
  // background-color: #293752;
  // background: linear-gradient(90deg, #0F74B9 0%, rgba(15,116,185,0.05) 100%) !important;
  // background: linear-gradient(180deg, rgb(52, 95, 141) 0%, #3D5474 100%) !important;
  // background: rgba(61,137,210, 0.8) !important;
// }
.rect-area{
  position: relative;
  // border-left: 3px solid #C0CCDC;
  padding: 2px 10px;
  // background: #2e71af;

  &::after {
    position: absolute;
    height: 100%;
    left: -5px ;
    top: 0;
    content: "";
    background-color: #e6f7f8;
    box-shadow: 1px 0px 6px 2px rgba(206, 219, 230, 0.9);
    flex-basis: 100%;
    border-radius: 1px;
    width: 4px;
    z-index: 1;
  }
}
.rhombus-decorate{
  height: 14px;
  width: 8px;
  background-color: RGBA(228,233,236,0.6);
  clip-path: polygon(10px 0%, 100% 0%,  calc(100% - 10px) 100%, 0% 100%,);
}


</style>