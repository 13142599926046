<template>
  <div title="添加站点" class="tile-plus flex-center" @click="showAddWebsiteModal">
    +
    <!-- <PlusSquareOutlined /> -->
  </div>
</template>

<script>
// import { PlusSquareOutlined } from '@ant-design/icons-vue'

export default {
  inject: ['appEventBus'],
  // components: {
    // PlusSquareOutlined
  // },
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  methods: {
    showAddWebsiteModal(){
      this.appEventBus.emit('showAddWebsiteModal')
    }
  }
}
</script>

<style lang="less" scoped>
.tile-plus{
  margin-top: -10px;
  // transform: translateY(-20px);
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
  // height: 100%;
  // vertical-align: top;
  color: #fff;
  // font-weight: bold;
  font-size: 50px;
  opacity: 0.95;
  /* border: 2px solid #eee; */
  // opacity: 0.9;
  // background-color: rgba(0,0,0, 0.2);
  // backdrop-filter: ;
  // background-color: rbg(0,0,0);
  border-radius: 2px;
  user-select:none;
  cursor: pointer;
}
</style>