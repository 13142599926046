<template>
  <div class="top-operate">
    <Clock></Clock>
    <div>
      <div style="margin: 0 auto;width: 0px;">
        <TopOperateCenter></TopOperateCenter>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import TopOperateCenter from './TopOperateCenter.vue'
import Clock from '@/components/Clock/Clock.vue'

export default {
  name: 'TopOperate',
  components: {
    TopOperateCenter,
     Clock
  }
}
</script>

<style lang="less">
.top-operate{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  // justify-content: space-between;
  height: 36px;
  margin-bottom: 6px;
  // background: linear-gradient(90deg, rgba(61,137,210,1) 0%,rgba(61,137,210,0.99) 1%,rgba(255,255,255,0) 100%) ;
  // background: linear-gradient(90deg, rgba(61,137,210,1) 0%,rgba(61,137,210,0.99) 1%,rgba(255,255,255,0) 100%) ;
  background: linear-gradient(30deg, rgba(61,84,116,1) 0%,rgba(61,84,116,0.99) 1%,rgba(255,255,255,0) 80%), rgba(5,16,35, 0);
  box-shadow: 0 0  3px 3px rgba(0,0,0,0.15);
  // background-image: url("/noise.png");
  // backdrop-filter: blur(2px);
}
</style>
