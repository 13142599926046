<template>
  <div class="card-header">
    <div class="draggable-handle-wrap">
      <div class="draggable-handle"/>
    </div>
    <!-- <SettingFilled /> -->
  </div>
</template>

<script>
// import {
  // SettingFilled
// } from '@ant-design/icons-vue'

export default {
  components: {
    // SettingFilled
  }
}
</script>

<style scoped lang="less">
.card-header{
  position: absolute;
  z-index: 1;
  /* top: 6px; */
  top: 2px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 0 10px;
  mix-blend-mode: multiply;
  line-height: 0;
  /* opacity: 1; */
  /* background-color:#41B783; */
}
.draggable-handle-wrap{
  height: 0;
  /* height: 18px; */
  /* line-height: 18px; */
  flex-grow: 2;
  text-align: center;
  vertical-align: top;

  /* text-align: center;
  height: 30px;
  flex-grow: 2;
  color: #fff;
  font-weight: bolder;
  font-size: 30px;
  cursor: move; */
}
.draggable-handle{
  opacity: 0;
  position: relative;
  // bottom: 8px;
  display: inline-block;
  height: 4px;
  width: 20%;
  // background-color: #fefefe;
  // background-color: rgba(61,125,187, 1);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 4px 1px rgba(0, 125, 202, 0.5);

  transition: height 0.3s, width 0.1s, opacity 0.1s;
  // transition-delay: 0.3s;

  border-radius: 1px;
  cursor: move;

  &:hover{
    height: 10px;
    // box-shadow: 0 1px 4px 1px rgba(0, 125, 202, 0.5);
    width: 50%;
    animation: flicker ease-out 0.4s;
  }

}

</style>