<template>
  <div class="card" style="margin: 10px;padding: 10px 16px;">
    <div class="inner">
      <a-steps :current="current" class="margin-bottom">
        <a-step title="确认账号">
          确认账号
        </a-step>
        <a-step title="发送重置邮件" />
        <a-step title="重置成功" />
      </a-steps>
      <a-alert class="margin-bottom" v-if="tip" :message="tip" show-icon />
      <a-input v-model:value="email" v-if="current===0" placeholder="请输入绑定的邮箱" class="margin-bottom" :maxlength="99"></a-input>
      <a-button
        :loading="loading"
        @click="onConfirm"
        type="primary">
        确认
      </a-button>
      <a-button v-if="current===0" @click="close" class="margin-left">
        取消
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['cloudApp', 'appState', 'appEventBus'],
  data() {
    return {
      email: '',
      tip: '',
      loading: false,
      current: 0
    }
  },
  methods: {
    onConfirm(){
      if (this.current !== 0 ){
        this.$emit('cancle')
        return
      }
      this.loading="true"
      //  this.cloudApp.auth().sendPasswordResetEmail(this.email).then(() => {
      this.appState.auth.sendPasswordResetEmail(this.email).then(() => {
      // 发送重置密码邮件成功
        this.tip = '发送重置密码邮件成功，请到邮箱中查看'
        this.current = 1
      })
        .catch(e => {
          console.log(e)
          const error = JSON.parse(e.message)
          switch (error.msg) {
            case "[INVALID_PARAM] [102003] mail user not exist":
              this.tip = '该用户不存在'
              break

            case "[INVALID_PARAM] [100003] mail addr format invalid ":
              this.tip = '邮箱格式错误'
              break

            default:
              break
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading=false
          }, 3000)
        })
    },
    close(){
      this.current = 0
      this.$emit('cancle')
    }
  }
}
</script>

<style>

</style>