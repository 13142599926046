// import axios from 'axios'
const axios = window.axios
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { message as Message } from 'ant-design-vue'

// 默认超时时间
axios.defaults.timeout = 10000

let NProgressTimer
function changeNProgressStatus (target = true) {
  clearTimeout(NProgressTimer)
  if (target) {
    NProgressTimer = setTimeout(() => {
      NProgress.start()
    }, 750)
  } else {
    NProgress.done()
  }
}

// http request拦截
axios.interceptors.request.use((config) => {
  changeNProgressStatus()
  return config
}, error => {
  return Promise.reject(error)
})

// http response 拦截
axios.interceptors.response.use(res => {
  const config = res.config
  console.log('config', config)

  // 关闭 progress bar
  // NProgress.done()
  changeNProgressStatus(false)
  // 获取状态码
  const status = res.data.code || res.status
  const message = res.data.msg || res.data.error_description || '未知错误'
  if (config.headers['interceptors-free']) {
    return res
  }
  // 如果是401则跳转到登录页面
  if (status === 401) {
    console.log('401')
  }
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    Message({
      content: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }
  return res.data
}, error => {
  changeNProgressStatus(false)
  return Promise.reject(new Error(error))
})

export default axios
