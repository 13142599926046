<template>
  <div class="card auth-card" ref="authCard">
    <!-- <transition name="fade"> -->
    <div class="inner" ref="entrance" v-if="!isReverse">
      <div>
        <a-badge class="margin-right" :status="appState.loginState?undefined:'warning'">
          <UserOutlined class="left-icon"/>
        </a-badge>
      </div>

      <div v-if="!appState.userInfo">
        <a-button type="primary" class="margin-right margin-bottom-xs" @click="showReverse('login')" >登录</a-button>
        <a-button @click="showReverse('register')">注册</a-button>
        <div>登录后，可开启云同步，自动同步数据到云端，多设备共享配置。</div>
      </div>
      <div v-else>
        <a-descriptions title="" :column="1">
          <a-descriptions-item v-if="appState.userInfo.nickName" label="昵称">
            {{ appState.userInfo.nickName }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="">
            <a-tag color="#D9B74F">
              <template #icon>
                <HeartOutlined />
              </template>
              内测用户
            </a-tag>
          </a-descriptions-item> -->
          <a-descriptions-item label="邮箱">
            {{ appState.userInfo.email }}
          </a-descriptions-item>
        </a-descriptions>

        <a-button type="link" size="small" @click="logout"><LogoutOutlined />退出</a-button>
      </div>
    </div>

    <div class="inner register" ref="register" v-else>
      <a-alert :class="{'scale-big11': statusData.alertAnimationFlag}" class="margin-bottom" :message="statusData.tip" :type="statusData.status" show-icon />

      <a-form ref="authForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-form-item name="email">
          <a-input v-model:value="form.email" placeholder="邮箱" :maxlength="99">
            <template #prefix><UserOutlined style="color:rgba(0,0,0,.25)"/></template>
          </a-input>
        </a-form-item>
        <a-form-item name="password" :help="formType==='register'?'密码长度不小于 8 位，不大于 32 位，需要包含字母和数字':''">
          <a-input-password v-model:value="form.password" placeholder="密码" :maxlength="32">
            <template #prefix><LockOutlined style="color:rgba(0,0,0,.25)"/></template>
          </a-input-password>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 24 }">
          <a-button
            :disabled="statusData.disabled"
            @click="onConfirm"
            type="primary"
            :loading="statusData.confirmLoading">
            {{ formType==='login'?'登录':'注册' }}
            <!-- 确认 -->
          </a-button>
          <a-button
            style="margin-left: 10px;"
            @click="onCancle">
            取消
          </a-button>
          <template v-if="formType==='login'">
            <a-divider type="vertical" />
            <a-button
              @click="showReverse('register')">
              注册
            </a-button>
            <a-button
              style="margin-left: 10px;"
              @click="showResetCard">
              忘记密码
            </a-button>
          </template>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { UserOutlined, LockOutlined,LogoutOutlined } from '@ant-design/icons-vue'
// import { TimelineLite,TweenLite, Power3 } from 'gsap'
// , HeartOutlined
let overturnTL
// console.log('cloudApp', cloudApp)

export default {
  inject: ['cloudApp', 'appState', 'appEventBus'],
  components: {
    UserOutlined, LockOutlined, LogoutOutlined
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      form: {
        email: '',
        password: '',
      },
      // true 翻转状态，进入登录或注册 ，false 表示正常入口状态
      isReverse: false,
      // 登录注册模块的实际状态 register/login
      formType: 'login',

      statusData: {
        tip: '正在通过邮箱登录',
        status: 'info',
        confirmLoading: false,
        disabled: false,
        alertAnimationFlag: false
      },
      resetCardVisible: false,
      rules: {
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }, {type: 'email', message: '请输入正确格式的邮箱'}],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      }
    }
  },
  // watch: {
    // isReverse (val) {
    //   this.toggleOverturnStatus(val)
    // }
  // },
  methods: {
    // 状态重置
    statusReset(){
      this.statusData = {
        tip: '正在通过邮箱账号注册',
        status: 'info',
        confirmLoading: false,
        disabled: false,
        alertAnimationFlag: false
      }
    },
    onRegister(){
    },
    // 注册后从邮箱返回的场景执行快速登录
    fastLogin(){
      const registerInfoStr= localStorage.getItem('registerInfo')
      console.log('registerInfoStr', registerInfoStr)
      if (!registerInfoStr) {return}
      const registerInfo =JSON.parse(registerInfoStr)
      this.form = registerInfo
      this.login()
      // 销毁注册时留下的账密
      localStorage.setItem('registerInfo', '')
    },
    onCancle(){
      this.showFront()
    },
    // 展示正面
    showFront(){
      this.registerVisible = false
      this.isReverse = false
    },
    /**
    * 展示登录页或者注册页
    *
    * @param {string} type login: 展示登录 register 展示注册
    * @return {undefined}
    */
    showReverse(type = 'login'){
      // this.statusReset()
      this.isReverse = true
      this.formType = type
      if (type === 'register'){
        this.statusData.tip = '正在通过邮箱注册'
      } else {
        this.statusData.tip = '正在通过邮箱登录'
      }
    },

    // 执行注册 / 登录
    onConfirm(){
      console.log('this.form', this.form)
      this.$refs.authForm
        .validate()
        .then(() => {
          console.log('this.form', this.form)
          if (this.formType === 'register') {
            this.register()
          } else {
            this.login()
          }
        })
    },
    register(){
      this.statusData.confirmLoading = true
      this.cloudApp
        .auth({persistence: 'local'})
        .signUpWithEmailAndPassword(this.form.email,this.form.password)
        .then(() => {
          // 保存registerInfo以便从邮件链接返回时进行自动登录
          const registerInfo = {
            email: this.form.email,
            password: this.form.password,
            time: new Date().getTime()
          }
          localStorage.setItem('registerInfo', JSON.stringify(registerInfo))

          // 发送验证邮件成功
          this.statusData.tip = '发送验证邮件成功，请到收件箱点击激活链接'
          this.statusData.disabled = true
          this.appEventBus.emit('register')
          setTimeout(() => {
            this.statusData.disabled = false
          }, 5000)
        })
        .catch(e => {
          console.log(e)
          const error = JSON.parse(e.message)
          // console.log('error', error)
          // console.log('e.code', e.code)
          const statusData = this.statusData
          switch (error.msg) {
            case "[AUTH_EMAIL_HAS_BEEN_BOUND] [102002] mail user exist":
              statusData.tip = '该账号已注册'
              break

            case "[INVALID_PARAM] [100003] pwd length too short":
              statusData.tip = '密码长度太短'
              break

            case "[INVALID_PARAM] [100003] pwd is weak":
              statusData.tip = '密码太简单'
              break

            case "[INVALID_PARAM] [100003] mail addr is invalid":
              statusData.tip = '邮箱格式不正确'
              break

            default:
              break
          }
        })
        .finally(() => {
          this.statusData.confirmLoading = false
          // setTimeout(() => {
          //   this.showFront()
          // }, 1000);
        })
    },
    login(){
      this.statusData.confirmLoading = true
      this.cloudApp
        .auth({persistence: 'local'})
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((loginState) => {
          // 用户名密码登录成功
          // console.log('登录成功', loginState)
          this.statusData.tip = '登录成功，欢迎回来！'
          this.appState.loginState = loginState
          this.statusData.status = 'success'
          this.appEventBus.emit('login')
          setTimeout(() => {
            this.showFront()
          }, 1000)
        }, (e) => {
          console.log('-----e-----', e)
          // console.log(JSON.stringify(e))
          // console.log('e.message', JSON.parse(e.message).code === 'OPERATION_FAIL')

          // console.log('e.name', e.name)
          // console.log('e.code', e.code)
          if (JSON.parse(e.message).code === 'OPERATION_FAIL') {
            this.statusData.tip = '登录失败，请检查邮箱地址与密码是否匹配，连续错误次数过多会造成账号锁定'
            this.statusData.status = 'warning'
            this.statusData.alertAnimationFlag = true
            setTimeout(() => {
              this.statusData.alertAnimationFlag = false
            }, 500)
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.statusData.confirmLoading = false
          }, 3000)
        })
    },
    // 执行退出
    logout(){
      this.cloudApp.auth().signOut()
        .then(() => {
          this.appState.loginState = null
        })
    },

    toggleOverturnStatus (val) {
      if (!overturnTL) {
        overturnTL = new window.TimelineLite({paused: true})
        overturnTL.add(
          window.TweenLite.to(this.$refs.authCard, 0.6,{
            ease: 'Power3.easeInOut',
            rotationY: 180,
          })
        )

        overturnTL.add(window.TweenLite.set(
          this.$refs.entrance, {
            display: 'none'
          }
        ), 0.3)

        overturnTL.add(window.TweenLite.set(
          this.$refs.register, {
            display: 'block'
          }
        ), 0.3)
      }
      val ? overturnTL.play() : overturnTL.reverse()
    },
    showResetCard(){
      this.$emit('show-reset')
    },
  }
}
</script>

<style lang="less">
.auth-card{

  // .ant-form-item{
  //   margin-bottom: 8px;
  // }
  // margin: 10px;

  // .ant-descriptions-header{
  //   margin-bottom: 0;
  // }

  .inner{
    display: flex;
    // align-items: center;
    margin-right: 10px;

    // transform-style: preserve-3d;
    // backface-visibility: hidden;
    // transform-origin: left;

    &.register{
      justify-content: center;
      flex-direction: column;
      // display: none;
      // transform: rotationY(-180);
      // backface-visibility: visible;
      // transform: rotateY(-180deg);
      z-index: 1;
    }
  }
}
</style>