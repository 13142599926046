import { getZhihuSuggestion } from '@/api/base'

let env

function updateSugBoxStatus() {
  env.suggestionBoxVisible = true
  // env.changeSugLoadingStatus()
}

function addSugScript(sugurl) {
  //动态添加JS脚本
  var script = document.createElement('script')
  script.src = sugurl
  script.onerror = () => {
    env.changeSugLoadingStatus(false)
  }
  script.onload = () => {
    var scriptParent = script.parentNode
    scriptParent.removeChild(script)
  }
  document.getElementsByTagName('head')[0].appendChild(script)
}

export const engineInfo = {
  baidu: {
    /**
     * 展示搜索建议
     *
     * @param {string} searchText 用户输入
     * @return {undefined}
     */
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    onSearch(searchText) {
      window.open(`https://www.baidu.com/s?wd=${searchText}`)
    },
    iconUrl: 'https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/public-assets/icon/baidu.svg',
    // 跳转到搜索页
    naviToSearch(text) {
      window.open(`https://www.baidu.com/s?wd=${text}`)
    },
    pingTestUrl: 'https://www.baidu.com',
    name: '百度搜索',
  },
  google: {
    iconUrl: 'https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/public-assets/icon/google.svg',
    onSearch(searchText) {
      // console.log('onSearch')
      window.open(`https://www.google.com/search?q=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestqueries.google.com/complete/search?client=Youtube&q=${searchText}&jsonp=window.google.sug`
      !window.google &&
        (window.google = {
          sug: (json) => {
            // console.log('json', json)
            json[1].forEach((val, index) => {
              json[1][index] = val[0]
            })
            env.s = json[1].slice(0, 10)
            env.changeSugLoadingStatus(false)
            // env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    naviToSearch(text) {
      window.open(`https://www.google.com/search?q=${text}`)
    },
    pingTestUrl: 'https://www.google.com',
    name: '谷歌搜索',
  },
  zhihu: {
    iconUrl: 'https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/public-assets/icon/zhihu.svg',
    onSearch(searchText) {
      window.open(`https://www.zhihu.com/search?type=content&q=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()
      getZhihuSuggestion(searchText)
        .then((res) => {
          res.suggest.forEach((val, index) => {
            res.suggest[index] = val.query
          })
          env.s = res.suggest
        })
        .finally(() => {
          env.changeSugLoadingStatus(false)
        })
    },
    pingTestUrl: 'https://www.zhihu.com',
    name: '知乎搜索',
  },
  bilibili: {
    iconUrl: 'https://www.bilibili.com/favicon.ico?v=1',
    onSearch(searchText) {
      window.open(`https://search.bilibili.com/all?keyword=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://www.bilibili.com',
    name: '哔哩哔哩搜索',
  },
  bing: {
    iconUrl: 'https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/public-assets/icon/bing.svg',
    onSearch(searchText) {
      window.open(`https://cn.bing.com/search?q=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://cn.bing.com',
    name: '必应搜索',
  },
  juejin: {
    iconUrl: 'https://lf3-cdn-tos.bytescm.com/obj/static/xitu_juejin_web//static/favicons/favicon-32x32.png',
    onSearch(searchText) {
      window.open(`https://juejin.cn/search?query=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://juejin.cn/',
    name: '掘金',
  },
  weibo: {
    iconUrl: 'https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/img/weibo.svg',
    onSearch(searchText) {
      window.open(`https://s.weibo.com/weibo/${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://weibo.com/',
    name: '微博',
  },
  weixin: {
    iconUrl: 'https://www.sogou.com/images/logo/new/favicon.ico?v=4',
    onSearch(searchText) {
      window.open(`https://weixin.sogou.com/weixin?query=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://weibo.com/',
    name: '微信',
  },
  Youtube: {
    iconUrl: 'https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/icon/youtube.ico',
    onSearch(searchText) {
      window.open(`https://www.Youtube.com/results?search_query=${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://weibo.com/',
    name: 'Youtube',
  },
  Douyin: {
    iconUrl:
      'https://gimg3.baidu.com/topone/src=https%3A%2F%2Fbkimg.cdn.bcebos.com%2Fpic%2F80cb39dbb6fd5266d0168896e952802bd40735fa9855%3Fx-bce-process%3Dimage%2Fresize%2Cm_pad%2Cw_348%2Ch_348%2Ccolor_ffffff&refer=http%3A%2F%2Fwww.baidu.com&app=2011&size=f200,200&n=0&g=0n&er=404&q=75&fmt=auto&maxorilen2heic=2000000?sec=1691341200&t=2983d4d494a0b16afa2611c63f2d88c8',
    onSearch(searchText) {
      window.open(`https://www.douyin.com/search/${searchText}`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://www.douyin.com',
    name: '抖音',
  },
  xiaohongshu: {
    iconUrl:
      'https://ecmb.bdimg.com/tam-ogel/861753057_-284371310_270_270.png',
    onSearch(searchText) {
      window.open(`https://www.xiaohongshu.com/search_result?keyword=${searchText}&source=web_search_result_notes`)
    },
    showSuggestion(searchText) {
      updateSugBoxStatus()

      //组装查询地址
      var sugurl = `https://suggestion.baidu.com/su?wd=${searchText}&cb=window.baidu.sug`
      !window.baidu &&
        (window.baidu = {
          sug: (json) => {
            env.s = json.s
            env.changeSugLoadingStatus(false)
          },
        })

      addSugScript(sugurl)
    },
    pingTestUrl: 'https://www.xiaohongshu.com/',
    name: '小红书',
  },
}

// 总的搜索引擎数据集
export const engineList = ['baidu', 'google', 'zhihu', 'bilibili', 'bing', 'juejin', 'weibo', 'weixin', 'Youtube', 'Douyin', 'xiaohongshu']

export let engineListActiveMap = {
  baidu: true,
  google: true,
  zhihu: true,
  bilibili: true,
  bing: true,
  juejin: true,
  weibo: true,
  weixin: true,
  Youtube: true,
  Douyin: true,
  xiaohongshu: true,
}

export let activeEngineList = engineList
rercoverConfig()

function rercoverConfig() {
  const engineListStr = localStorage.getItem('engineList')
  const engineListActiveMapStr = localStorage.getItem('engineListActiveMap')

  if (engineListActiveMapStr) {
    engineListActiveMap = JSON.parse(engineListActiveMapStr)
  }
  if (engineListStr) {
    activeEngineList = []
    const localEngineList = JSON.parse(engineListStr)
    localEngineList.forEach((engineName) => {
      if (engineListActiveMap[engineName]) {
        activeEngineList.push(engineName)
      }
    })
  }
}

export function initSearch(outEnv) {
  env = outEnv
}

/**
 * 策略函数的接收参数
 *
 * @param {object} e input事件
 * @param {object} searchCom search组件的this
 * @return {undefined}
 */
export const keyDownStrategies = {
  27(e) {
    env.suggestionBoxVisible = false
    e.preventDefault()
  },
  // tab
  9(e, searchCom) {
    e.preventDefault()
    // 切换搜索引擎
    const curEngineName = searchCom.config.curEngineName
    const index = searchCom.engineList.findIndex((val) => val === curEngineName)
    if (!e.shiftKey) {
      if (index !== searchCom.engineList.length - 1) {
        searchCom.config.curEngineName = searchCom.engineList[index + 1]
      } else {
        searchCom.config.curEngineName = searchCom.engineList[0]
      }
    } else {
      if (index !== 0) {
        searchCom.config.curEngineName = searchCom.engineList[index - 1]
      } else {
        searchCom.config.curEngineName = searchCom.engineList[searchCom.engineList.length - 1]
      }
    }
    searchCom.dropdownVisible = true
    e.preventDefault()
  },
}
