/*global localforage:true*/

// eslint-disable-next-line no-undef
// localforage.setItem('1', { i: '1', h: 2 }).then(function (value) {
//   // 当值被存储后，可执行其他操作
//   console.log(value)
// }).catch(function (err) {
//   // 当出错时，此处代码运行
//   console.log(err)
// })

// eslint-disable-next-line no-undef
// localforage.getItem('1').then(function (value) {
//   // 当离线仓库中的值被载入时，此处代码运行
//   console.log(value)
// }).catch(function (err) {
//   // 当出错时，此处代码运行
//   console.log(err)
// })
// export const kvStore = localforage.createInstance({
//   name: 'kvStore'
// })


export const appConfigStore = localforage.createInstance({
  storeName: 'appConfig',
})

export const CollectionStore = localforage.createInstance({
  storeName: 'Collection',
})

export function safeSetItem (key, value, storeIns) {
  // try {
  // localforage.setItem(key, JSON.parse(JSON.stringify(_gridChange_[key])))
  const store = storeIns || localforage
  return store.setItem(key, JSON.parse(JSON.stringify(value)))
  // .catch(e => {
  //   console.error(e)
  // })
  // } catch (e) {
  //   console.log('e', e)
  //   return Promise.reject('保存异常')
  // }
}

export function saveLsDataToForage (layout) {
  localforage.clear()
    .then(() => {
      layout.forEach(val => {
        localforage.setItem(val.i, val)
      })
    })
}

export function getAllAppConfig () {
  const allAppConfig = {}
  return appConfigStore.iterate((value, key) => {
    console.log('key', key)
    allAppConfig[key] = value
  })
    .then(() => {
      console.log('allAppConfig', allAppConfig)
      return allAppConfig
    })
}

// 获取某个实例的所有数据
export function getInsAllData (instance) {
  const result = {}
  return instance.iterate((value, key) => {
    result[key] = value
  })
    .then(() => {
      console.log('result', result)
      return result
    })
}

export function getAllLayoutData () {
  const allLayoutData = []
  return localforage.iterate((value) => {
    allLayoutData.push(value)
  })
    .then(() => {
      // console.log('allLayoutData', allLayoutData)
      return allLayoutData
    })
}

// 将某个对象的数据第一层全量存储到本地db中
export function saveObjectAllKeys (obj, db) {
  const tasks = []
  return new Promise((resolve) => {
    Object.keys(obj).forEach(key => {
      tasks.push(safeSetItem(key, obj[key], db))
    })
    Promise.all(tasks)
      .finally(resolve)
  })
}


// localforage.iterate((value, key) => {
//   // 此回调函数将对所有 key/value 键值对运行
//   console.log([key, value])
// }).then(function () {
//   console.log('Iteration has completed')
// }).catch(function (err) {
//   // 当出错时，此处代码运行
//   console.log(err)
// })