/**
 * 判断是否为空
 */
export function validatenull (val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length == 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true
    return false
  }
  return false
}

/**
* 网址补全，返回规范的网址信息
*
* @param {string}
* @return {string} 符合规范的url
*/
export function formatUrl (url) {
  let result = url

  // 检测是否满足为url基本格式
  var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
  if (!reg.test(url)) {
    result = 'http://' + url
  }
  return result
}

/**
* 解析出url中的域名
*
* @param {string} url
* @return {string} domain
*/
export function getDomain (url = '') {
  var domain = url.split('/') //以“/”进行分割
  if (domain[2]) {
    domain = domain[2]
  } else {
    domain = '' //如果url不正确就取空
  }
  return domain
}

/**
* 通过url尝试获取favicon的地址
*
* @param {string} url
* @return {string} domain
*/
export function getFavicon (url = '') {
  let favIconUrl = ''
  try {
    const urlObj = new URL(url)
    const origin = urlObj.origin
    if (origin && origin !== 'null') {
      // favIconUrl = origin + '/favicon.ico'
      favIconUrl = url
    }
  } catch (error) {
    console.log('error', error)
  }
  return favIconUrl
}