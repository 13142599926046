/*eslint-disable*/
// 全局的数据处理、含持久化、同步逻辑控制
import { reactive, computed, watch } from 'vue'
// import cloudbase from '@cloudbase/js-sdk'
import { getStore, setStore } from './storeUtil'
// import { initialSearchConfig } from './config'
import { merge } from 'lodash-es'
import { defaultWallpaper } from '@/service/wallpaperData'
import { initwallpaperService } from '@/service/wallpaper'
import { widgetDisplayList, widgetStatus } from '@/service/widget'
import { initLayoutService, removeLayoutItem, coverTotalLayoutData, initialLayout, layoutArrToObj } from '@/service/layout'
import { initCollectionService, recoverCollectionLocalData } from '@/service/Collection'
import { launch } from '@/service/launch'
import {
  getAllLayoutData,
  safeSetItem, appConfigStore, getAllAppConfig, saveObjectAllKeys
} from '@/service/localforage'
import { appEventBus } from '@/service/appEventBus'
import {activeEngineList} from '@/components/search/search'
console.log('activeEngineList', activeEngineList)

export { appEventBus }

// 不存储至云的数据
// let onlyLocalStoreKeys = ['curWallpaper']

const syncDataKeys = ['appConfig', 'layout']
const initialappState = {
  auth: null,
  // 通过右键激活的卡片vue实例
  activeCard: null,

  // 登录态
  loginState: null,

  // 壁纸更换模态框
  wallpaperSelectModalVisible: false,

  // 小组件
  widgetDisplayList,

  // appconfig、layout中的数据将在云端、本地持久化
  appConfig: {
    // inPlace newPage
    openHabit: 'newPage',
    isDeveloperMode: false,
    // 保留搜索历史记录
    saveSearchHistory: true,
    // 小组件状态
    widgetStatus,
    // 控制全局音效
    isSilentMode: true,

    wallpaperConfig: {
      // keep dailyUpdate
      updateBehavior: 'dailyUpdate',
      behavior: 'image',
      // 图片来源、grid、bing
      repositoryType: 'bing',
      // wallpaper720
      // wallpaper4k
      // wallpaper2k
      quality: '1080p',
      isBlur: false,

      // 当前壁纸
      curWallpaper: {},
    }
  },
  // 最后更新时间
  appConfigLastUpdateTime: 0,
  layout: initialLayout,
  layoutLastUpdateTime: 0,
  curFullscreenCard: null,
  // 收藏夹的最后更新时间，由所有收藏夹共享
  collectionsLastUpdateTime: 0,

  // 是否处于开发模式
  isDevMode: false,
  // 搜索引擎配置，不进行同步
  // searchConfig: initialSearchConfig,

  // 当前选中的网站磁贴
  curSelectedTiles: {},
  // 当前选中的网站磁贴数量
  curSelectedTilesCount: 0,
  // grid组件实例
  $grid: null,
  $tileControlBar: null,
  // 所处的运行环境： normal - 正常页面，extension - 新标签页
  env: 'normal',

  // 一些不被多处共享的全局数据
  module: {
    // 活动的搜索引擎列表
    activeEngineList: activeEngineList
  }
}

CheckBeforeStarting()

// 是否已经执行过云存储更新时间比对
let recoverFromCloudStorageFlag = false

// 1.  登录检查
export const cloudApp = cloudbase.init({
  env: 'c14h10-5gp0rwgge2a4e02a'
})
let db
let _
let userStorageCollection
// eslint-disable-next-line no-unused-vars
let updateTimeRecordCollection

// 1.1 appState进入响应状态
// export const appState = reactive(initialappState)
export let appState = {}

// 2. 从本地存储中恢复
localforage.ready().then(recoverFromLocalStorage)

// 3. 从云存储中恢复
appEventBus.on('recoverFromLocalStorageEnd', () => {
  console.log('从本地存储中恢复结束')
  appState = reactive(initialappState)

  if (process.env.NODE_ENV === 'development') {
    appState.isDevMode = true
  }

  // 5. 启动vue
  appEventBus.emit('appLauch')
  recoverFromCloudStorage()
})

// 使用本地数据进行抢救的代码，仅限手动使用
// appEventBus.on('recoverFromLocalStorageEnd', () => {
//   appState.auth =  cloudApp.auth({ persistence: 'local' })
//   const loginState = appState.loginState = appState.auth.hasLoginState()
//   db = cloudApp.database()
//   userStorageCollection = db.collection('user-storage')

//   userStorageCollection.doc(appState.loginState.user.uid)
//     .update({
  // 从本地获取的数据
//     })
// })


appEventBus.on('login', recoverFromCloudStorage)
appEventBus.on('register', recoverFromCloudStorage)

// 页面可见性变动监听与数据同步
initVisibilityChangeListener()

// 4. 监听 需要数据库持久化的数据 、 启动页面可见性监听
// 4.1. 出现变动，进行本地保存
// 4.2. 如果已登录，推送至服务端进行保存
// 完成同步后，开始监听本地数据变动
function initappStateWatch () {
  console.log('初始持久化数据的监听')
  watch(
    appState.appConfig,
    (val) => {
      console.log('appConfig变动', appState.appConfig)
      const dateTime = new Date().getTime()
      saveObjectAllKeys(appState.appConfig, appConfigStore)
      // setStore({ name: 'appConfig', content: val, dateTime })
      appState.appConfigLastUpdateTime = dateTime
      if (!appState.loginState) { return }
      updateCloudDataTimeRecord('appConfig', dateTime)
      userStorageCollection.doc(appState.loginState.user.uid)
        .update({
          appConfig: _.set(val)
        })

      updateTimeRecordCollection.doc(appState.loginState.user.uid)
        .update({
          updateTimeRecord: {
            appConfigLastUpdateTime: dateTime
          }
        })
      appConfigStore.setItem('layoutLastUpdateTime', dateTime)
    }
  )
  initGetters()

  appEventBus.on('layoutUpdated', () => {
    console.log('监听到layout变动')
    let cloudUpdateObj = {}
    const dateTime = new Date().getTime()
    // setStore({ name: 'layout', content: val, dateTime })
    Object.keys(_gridChange_).forEach(key => {
      if (_gridChange_[key]) {
        console.log('变动的key', key)
        cloudUpdateObj[key] = _gridChange_[key]

        // indexedDB不支持proxy对象
        // localforage.setItem(key, _gridChange_[key])
        localforage.setItem(key, JSON.parse(JSON.stringify(_gridChange_[key])))
          .then(() => {
            delete _gridChange_[key]
          })
      } else {
        removeLayoutItem(key)
      }
    })

    appConfigStore.setItem('layoutLastUpdateTime', dateTime)
    appState.layoutLastUpdateTime = dateTime

    // 保存至云端
    if (!appState.loginState) { return }
    console.log('保存至云端 cloudUpdateObj', cloudUpdateObj)
    let keysArr = Object.keys(cloudUpdateObj)
    if (keysArr.length === 0 ){return}
    userStorageCollection.doc(appState.loginState.user.uid)
      .update({
        layout: cloudUpdateObj
      })
    updateTimeRecordCollection.doc(appState.loginState.user.uid)
      .update({
        updateTimeRecord: {
          layoutLastUpdateTime: dateTime
        }
      })
  })

  // onlyLocalStoreKeys.forEach(key => {
  //   // console.log('onlyLocalStoreKeys', key)
  //   watch(() => appState[key], (val) => {
  //     setStore({ name: key, content: val })
  //   })
  // })
}

// 2. 从本地存储中恢复
function recoverFromLocalStorage () {
  console.log('从本地存储中恢复')
  // console.log('localforage', localforage)
  // console.log("localforage.getItem('appConfig')", localforage.getItem('appConfig'))
  let configTask = getAllAppConfig().then(appConfigStore => {
    // if (appConfig) {
    // appState.appConfig = merge(appState.appConfig, appConfigStorageObj)
    initialappState.appConfig = merge(initialappState.appConfig, appConfigStore)
    initialappState.appConfigLastUpdateTime = appConfigStore.appConfigLastUpdateTime || 0
    // }
  })
  let collectionTask = recoverCollectionLocalData()

  // const layoutStorageObj = getStore({ name: 'layout', debug: true })
  let layoutTask = getAllLayoutData()
    .then(res => {
      console.log('从本地存储中恢复 getAllLayoutData', res)
      if (res.length) {
        initialappState.layout = res
        return appConfigStore.getItem('layoutLastUpdateTime')
          .then(val => {
            console.log('layoutLastUpdateTime', val)
            val && (initialappState.layoutLastUpdateTime = val)
          })
      } else {
        coverTotalLayoutData(initialappState.layout)
      }
    })

  let task = Promise.all([
    configTask,
    layoutTask,
    collectionTask
  ])
    .finally(() => {
      appEventBus.emit('recoverFromLocalStorageEnd')
    })

  // if (layoutStorageObj) {
  // appState.layout = layoutStorageObj.content
  // appState.layoutLastUpdateTime = layoutStorageObj.datetime
  // appState.layout = track(layoutStorageObj.content)
  // cutomTrackLayout = track(layoutStorageObj.content)
  // }

  // onlyLocalStoreKeys.forEach(key => {
  //   let result = getStore({ name: key })
  //   if (result) {
  //     initialappState[key] = result
  //   }
  // })
}

// 3. 对比本地与云存储中的数据更新时间，尝试从云存储中恢复
function recoverFromCloudStorage () {
  // 是否已经执行过初始化
  console.log('开始比对本地数据版本')

  appState.auth =  cloudApp.auth({ persistence: 'local' })
  const loginState = appState.loginState = appState.auth.hasLoginState()
  console.log('loginState', loginState)
  db = cloudApp.database()
  launch(db)
  if (!loginState) {
    initappStateWatch()
    initCollectionService(db, appState)
    return
  }

  _ = db.command

  // 初始化需要登录的服务
  initLayoutService(db, appState)
  initCollectionService(db, appState)
  userStorageCollection = db.collection('user-storage')
  updateTimeRecordCollection = userStorageCollection

  updateTimeRecordCollection
    .doc(appState.loginState.user.uid)
    .field({
      updateTimeRecord: true,
    })
    // .doc({
    //   _id: _.eq(appState.loginState.user.uid)
    // })
    .get()
    .then((res) => {
      console.log('获取更新时间数据', res)
      if (!res.data.length) {
        // initUserUpdateTimeRecord(updateTimeRecordCollection)
        initUserCloudStorage(userStorageCollection)
        return
      }
      const record = res.data[0].updateTimeRecord

      let i = syncDataKeys.length
      while (i--) {
        console.log('云上数据', syncDataKeys[i], record[syncDataKeys[i] + 'LastUpdateTime'])
        console.log('本地数据', syncDataKeys[i], appState[syncDataKeys[i] + 'LastUpdateTime'])
        if (record[syncDataKeys[i] + 'LastUpdateTime'] !== appState[syncDataKeys[i] + 'LastUpdateTime']) {
          updateLocalData(1, record, syncDataKeys[i])
            .finally(() => {
              !recoverFromCloudStorageFlag && initappStateWatch()
              recoverFromCloudStorageFlag = true
            })
          break
        } else if (i === 0) {
          console.log('本地数据与云上数据一致')
          !recoverFromCloudStorageFlag && initappStateWatch()
          recoverFromCloudStorageFlag = true
          // launch(db)
        }
      }
    })
}

/**
* 数据同步
*
* @param {number} type 1: 全量拉取数据并更新 2:本地数据推送至服务器（暂弃）
* @param {object} record 云端的更新时间戳
* @param {string} key 需要更新的键名
* @return {undefined}
*/
// eslint-disable-next-line no-unused-vars
function updateLocalData (type, record, key) {
  if (type === 1) {
    // 尝试拉取用户云存储中的数据
    console.log('全量拉取数据并覆盖本地')
    return userStorageCollection
      .where({
        _id: _.eq(appState.loginState.user.uid)
      })
      .get()
      .then((res) => {
        let result = res.data[0]
        console.log('尝试拉取用户云存储中的配置数据', res)
        // 未拉取到数据，则初始化appConfig、layout
        if (!result) {
          initUserCloudStorage(userStorageCollection)
          return
        }
        syncDataKeys.forEach(key => {
          let cloudTime = record[key + 'LastUpdateTime']
          if (key === 'layout') {
            let layoutData = Object.values(result[key])
            coverTotalLayoutData(layoutData)
              .then(() => {
                updateLocalDataTimeRecord(key, cloudTime)
              })
              .finally(() => {
                appState[key] = layoutData
                // 发出layout同步结束信号
                console.log('layout同步结束')
                appEventBus.emit('layoutSyncEnd')
              })
          } else {
            let mergeResult = merge(appState[key], result[key])
            // localforage.setItem(key, appState[key])
            saveObjectAllKeys(mergeResult, appConfigStore)

            // safeSetItem(key, appState[key], appConfigStore)
            //   .finally(() => {
            //     appState[key] = mergeResult
            //   })
            updateLocalDataTimeRecord(key, cloudTime)
          }
          console.log('云存储中的配置数据合并至本地动作', cloudTime)
        })
      })
      .finally(() => {
        if (key === 'appConfig') {
          launch(db)
        }
      })
  } else {
    console.log('本地数据推送至服务器')
  }
}

// 新增用户
function initUserCloudStorage (userStorageCollection) {
  console.log('未拉取到数据 initUserCloudStorage', appState.layout)
  // userStorageCollection.doc(appState.loginState.user.uid)
  //   .update({
  //     layout: _.set(layoutArrToObj(appState.layout))
  //   })
  userStorageCollection.add(
    {
      _id: appState.loginState.user.uid,
      appConfig: appState.appConfig,
      layout: layoutArrToObj(appState.layout),
      updateTimeRecord: {
        appConfigLastUpdateTime: 0,
        layoutLastUpdateTime: 0
      }
    }
  )
}

// function initUserUpdateTimeRecord (userStorageCollection) {
//   console.log('未拉取到数据 initUserUpdateTimeRecord')
//   console.log('appState.appConfigLastUpdateTime', appState.appConfigLastUpdateTime)
//   userStorageCollection.add(
//     {
//       _id: appState.loginState.user.uid,
//       appConfigLastUpdateTime: appState.appConfigLastUpdateTime,
//       layoutLastUpdateTime: appState.layoutLastUpdateTime,
//     }
//   )
// }


function initVisibilityChangeListener () {
  console.log('5.页面可见性变动监听与数据同步')
  // 设置隐藏属性和改变可见属性的事件的名称
  var hidden, visibilityChange;
  if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  let leaveTime = 0
  function handleVisibilityChange () {
    if (document[hidden]) {
      // 保存离开时间
      leaveTime = new Date().getTime()
    } else {
      let departureTime = new Date().getTime() - leaveTime
      if (departureTime > 100000) {
        appEventBus.emit('LongLeaveBack')
      } else if (departureTime > 10000) {
        // 对比云端数据
        recoverFromCloudStorage()
      }
      appEventBus.emit('show')
      // appEventBus.emit('focusOnSearchCom')
    }
  }

  // 如果浏览器不支持addEventListener 或 Page Visibility API 给出警告
  if (typeof document.addEventListener === "undefined" || typeof document[hidden] === "undefined") {
    console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
  } else {
    // 处理页面可见属性的改变
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }
}

// 更新本地及云端数据最后更新时间记录，用于本地数据变动时
export function updateCloudDataTimeRecord (key, time) {
  let conventionalKey = key + 'LastUpdateTime'
  let updateTime = time || new Date().getTime()
  appState[conventionalKey] = updateTime
  appConfigStore.setItem(conventionalKey, updateTime)
  console.log('updateCloudDataTimeRecord', conventionalKey, updateTime)
  if (appState.isLogin) {
    updateTimeRecordCollection.doc(appState.loginState.user.uid)
      .update({
        updateTimeRecord: {
          [conventionalKey]: updateTime
        }
      })
  }
}

// 根据云端数据更新本地时间记录，用于云端数据覆盖本地
export function updateLocalDataTimeRecord (key, time) {
  let conventionalKey = key + 'LastUpdateTime'
  appState[conventionalKey] = time
  appConfigStore.setItem(conventionalKey, time)
}

function initGetters () {

  // 以下为getters
  appState.userInfo = computed(
    () => {
      return appState.loginState ? appState.loginState.user : null
    }
  )
  appState.isLogin = computed(
    () => {
      return appState.loginState ? true : false
    }
  )
  appState.curWallpaper = computed(
    () => {
      return appState.appConfig.wallpaperConfig.curWallpaper
    }
  )
  appState.curWallpaperSourceUrl = computed(
    () => {
      if (!appState.curWallpaper) { return '' }
      let quality = appState.appConfig.wallpaperConfig.quality
      let result
      if (quality === '1080p') {
        result = appState.curWallpaper ? appState.curWallpaper.sourceUrl : ''
      }
      else {
        result = appState.curWallpaper['wallpaper' + appState.appConfig.wallpaperConfig.quality]
        !result && (result = appState.curWallpaper.sourceUrl)
      }
      return result
      // return appState.curWallpaper ? appState.curWallpaper.sourceUrl || ''
    }
  )
}

// 启动前检查
function CheckBeforeStarting() {
  // 确认登录环境
  if (window?.chrome?.tabs) {
    initialappState.env = 'extension'
  }
}