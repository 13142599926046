import { cloudApp, appState } from '@/store/store'

export function uploadFile (fileInfo) {
  let extensionName = fileInfo.file.name.split(".").pop()
  return cloudApp
    .uploadFile({
      cloudPath: `user-upload-store/${appState.loginState.user.uid}/${new Date().getTime()}.${extensionName}`,
      filePath: fileInfo.file,
      onUploadProgress: function (progressEvent) {
        console.log(progressEvent);
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log('percentCompleted', percentCompleted)
      }
    })
}