<template>
  <!-- <a-modal v-model:visible="visible" title="编辑" @ok="handleOk" :footer="null"> -->
  <EditWebsiteCard ref="EditWebsiteCardRef" :outForm="form" @confirm="onConfirm" @cancle="onCancle" :isCardStyle="false"></EditWebsiteCard>
  <!-- </a-modal> -->
</template>
<script>
import EditWebsiteCard from './EditWebsiteCard.vue'
import {recordTileChange} from '@/service/grid'

export default {
  inject: ['appState', 'appEventBus'],
  components: {
    EditWebsiteCard
  },
  data() {
    return {
      visible: false,
      form: null,
      // 当前modal指向的磁贴组件实例
      websiteTileCom: null,
    }
  },
  methods: {
    /**
    * 展示网站信息编辑弹窗
    *
    * @param {Object} websiteTileCom 网站磁贴vue实例
    * @return {undefined}
    */
    showModal(websiteTileCom) {
      this.visible = true
      this.form = websiteTileCom.boxData
      this.websiteTileCom = websiteTileCom

      this.$nextTick(() => {
        this.$refs.EditWebsiteCardRef.show({ websiteTileCom, title: '编辑站点', source: 'editWebsite'})
      })
    },
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
    onCancle(){
      this.visible = false
    },
    onConfirm(form){
      recordTileChange(this.websiteTileCom.selfLayoutInfo)
      this.websiteTileCom.selfLayoutInfo.boxData = form
      this.visible = false
      this.appEventBus.emit('layoutUpdated', this.appState.layout)
    }
  },
}
</script>