import { updateCloudDataTimeRecord } from '@/store/store'
let db
let appState

export function coverTotalLayoutData (layoutDataArr) {
  return new Promise((resolve) => {
    window.localforage.clear()
      .then(() => {
        const tasks = []
        layoutDataArr.forEach(val => {
          val && tasks.push(window.localforage.setItem(val.i, val))
        })
        Promise.all(tasks)
          .finally(resolve)
      })
  })
}

export function initLayoutService (dbIns, appStatePara) {
  db = dbIns
  appState = appStatePara
}

export function removeLayoutItem (key) {
  console.log('removeLayoutItem', key)
  window.localforage.removeItem(key)
  if (!appState) {
    return
  }
  const updateObj = {
    layout: {
      [key]: db.command.remove()
    }
  }
  db.collection('user-storage')
    .doc(appState.loginState.user.uid)
    .update(updateObj)
    .then(() => {
      updateCloudDataTimeRecord('layout')
      delete window._gridChange_[key]
    })
}

export const officialTileData = {
  search: {
    layout: { 'x': 0, 'y': 0, 'w': 4, 'h': 7, 'i': '0', type: 'search' },
    icon: ''
  }
}

/**
* 将layoutdata转化为受监听的数据 -----弃用------
*
* @param {string} p1 参数1的说明
* @return {undefined}
*/
export function track (layoutData) {
  console.log('将layoutdata转化为受监听的数据 track')
  var arrHandler = {
    set: (obj, prop, value) => {
      console.log('arrHandler set prop', prop, 'value', value)
      if (value && value.i && !value._listening_) {
        value = itemTrack(value)
      }
      obj[prop] = value
      return true
    },
    get: (obj, prop) => {
      // console.log('arrHandler get', prop)
      return obj[prop]
    }
  }
  layoutData.forEach((val, index) => {
    // Object.defineProperty(val, '_listening_', {
    //   value: true,
    //   enumerable: false
    // })
    layoutData[index] = itemTrack(val)
  })
  console.log('layoutData', layoutData)
  var arrProxy = new Proxy(layoutData, arrHandler)
  return arrProxy
}

// -----弃用------
function itemTrack (obj) {
  // console.log('itemTrack')
  if (obj._listening_) { return obj }
  Object.defineProperty(obj, '_listening_', {
    value: true,
    enumerable: false
  })
  // console.log('itemTrack')
  var handler = {
    set: (obj, prop, value) => {
      console.log('itemTrack prop', prop)
      // console.log('set prop', prop, 'value', value)
      if (prop !== 'moved') {
        // console.log('原始数据', obj[prop], obj.i)
        obj[prop] = value
        window._gridChange_[obj.i] = obj
      }
      return true
    },
    get: (target, prop) => {
      // console.log('get', prop)
      return target[prop]
    }
  }
  return new Proxy(obj, handler)
}

export function arrayTest () {
  // var arr = [{ i: 1 }, { i: 2 }, { i: 3 }, { i: 4 }, { i: 5 }]

  // var arrHandler = {
  //   set: (obj, prop, value) => {
  //     console.log('arrHandler set prop', prop, 'value', value)
  //     if (value.i && !value._listening_) {
  //       value = itemTrack(value)
  //     }
  //     obj[prop] = value
  //     return true
  //   },
  //   get: (obj, prop) => {
  //     console.log('arrHandler get', prop)
  //     return obj[prop]
  //   }
  // }
  // arr.forEach((val, index) => {
  //   Object.defineProperty(val, '_listening_', {
  //     value: true,
  //     enumerable: false
  //   })
  //   arr[index] = itemTrack(val)
  // })
  // var arrProxy = new Proxy(arr, arrHandler)

  // arrProxy.push({ i: 9 })

}

export const initialLayout = [
  { 'x': 0, 'y': 0, 'w': 8, 'h': 4, 'i': '0', type: 'search' },
  {
    "boxData": {
      "address": "https://www.zhihu.com/",
      "iconUrl": "https://static.zhihu.com/static/favicon.ico",
      "name": "知乎"
    },
    "h": 3,
    "i": "1",
    "moved": false,
    "type": "websiteBox",
    "w": 1,
    "x": 0,
    "y": 8
  },
  {
    "boxData": {
      "address": "https://www.bilibili.com/",
      "iconUrl": "http://www.bilibili.com/favicon.ico",
      "name": "哔哩哔哩 "
    },
    "h": 3,
    "i": "3",
    "type": "websiteBox",
    "w": 1,
    "x": 1,
    "y": 3
  },
  {
    "h": 16,
    "i": "5",
    "type": "trending",
    "w": 8,
    "x": 12,
    "y": 0,
  },
  // {
  //   "h": 3,
  //   "i": "6",
  //   "type": "dida",
  //   "w": 6,
  //   "x": 6,
  //   "y": 3,
  //   "h": 20,
  // }
]

// 数组形态的layout数据转化为对象形态
export function layoutArrToObj (arr){
  const result = {}
  arr.forEach(val => {
    result[val.i] = val
  })
  return result
}