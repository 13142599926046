<template>
  <div class="search" v-if="config">
    <div class="delay-panel">
      <div style="color: var(--font-second-color);">
        网络延迟检测
      </div>
      <span v-if="netDelay === -1" class="text-red">连接异常</span>
      <span class="delay-show-500" v-else-if="netDelay === ''">
        <span class="breathe-opacity" >正在连接...</span>
      </span>
      <span v-else>{{ netDelay }}ms</span>
    </div>
    <a-input-search
      v-focus
      v-forbidden
      ref="inputSearch"
      v-model:value="searchText"
      :placeholder="placeholder"
      enter-button
      @focus="onFocus"
      @change="onChange"
      @search="(text) => onSearch(text)"
      size="large"
      @blur="handleBlur"
      @keydown="onKeydown"
      @click="searchText && (suggestionBoxVisible = true)"
      class="input-search"
      showCount
    >
      <!-- allowClear -->
      <!-- :class="{'search-shadow': suggestionBoxVisible}" -->
      <!-- :loading="sugLoading" -->
      <template #prefix v-if="!config.isMutiResult">

        <a-dropdown
          trigger="click"
          ref="searchEngineDropdown"
          :visible="dropdownVisible"
          @click="dropdownVisible = !dropdownVisible">
          <a @click="e => e.preventDefault()" style="padding-bottom: 4px">
            <img width="20" height="20" :src="curEngineInfo.iconUrl" :alt="curEngineInfo.name">
            <CaretDownOutlined style="transform: translateY(3px)"/>
          </a>
          <template #overlay>
            <a-menu class="engine-list-dropdown">
              <a-menu-item
                v-for="name in engineList"
                class="hover-effect engine-item"
                :class="{active: curEngineInfo.name === engineInfo[name].name}"
                :key="name"
                @click="onInputEngineSwitch(name)">
                <img
                  width="18"
                  height="18"
                  :src="engineInfo[name].iconUrl"
                  :alt="engineInfo[name].name">
              </a-menu-item>
              <a-divider style="margin: 10px 0"/>
              <a-menu-item class="hover-effect engine-item">
                <SettingOutlined @click="showSearchEngineModal" class="flex flex-center" style="font-size: 16px;width: 18px;margin:0;"></SettingOutlined>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-input-search>

    <div v-if="config.isMutiResult" ref="mutiWrap" class="muti-wrap border-radius2" :class="{muti: config.isMutiResult}">
      <img
        v-for="engineName in engineList"
        :key="engineName"
        width="22"
        height="22"
        @click="toggleEngineStatus(engineName)"
        :src="engineInfo[engineName].iconUrl"
        alt=""
        :class="{active: config.engineStatusStore[engineName]}">
    </div>
    <div class="search-history" v-if="searchHistory.length&&appState.appConfig.saveSearchHistory">
      <!-- style="width: 25px" -->
      <div class="text-right edit-btn" >
        <FormOutlined
          title="删除搜索历史"
          v-if="!searchHistoryTagDeleteVisible"
          style="cursor: pointer; color: #fff;"
          @click="searchHistoryTagDeleteVisible = true"
          class="margin-top-xs"
        />
        <div v-else class="text-sm">
          <a type="link" @click="clearSearchHistory">全部删除</a>
          <a-divider type="vertical" style="background-color: #888;"></a-divider>
          <a type="link" @click="searchHistoryTagDeleteVisible = false">完成</a>
        </div>
      </div>
      <div>
        <a-tag
          v-for="(item, index) in searchHistory"
          :title="item.searchText"
          :key="item.id"
          @click="onSearch(item.searchText, {record: false, fromTag: true})"
          @close="deleteHistory(index)"
          :closable="searchHistoryTagDeleteVisible"
        >
          {{ item.searchText }}
        </a-tag>
      </div>
    </div>
    <div class="suggestion-wrap shadow" v-show="suggestionBoxVisible">
      <div class="inner">
        <div
          v-for="(suggestion, index) in s"
          :key="suggestion"
          @click="curEngineInfo.onSearch(suggestion)"
          class="suggestion-item"
          :class="{active: activeSugIndex===index}">
          {{ suggestion }}
        </div>
      </div>
    </div>

    <SearchConfigModal ref="SearchConfigModal"></SearchConfigModal>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import {throttle} from 'lodash-es'
import {initSearch, engineInfo,  keyDownStrategies} from './search'
import { FormOutlined, SettingOutlined } from '@ant-design/icons-vue'

// eslint-disable-next-line no-unused-vars
// import { initSearchConfig } from '@/store/store'
import { CaretDownOutlined } from '@ant-design/icons-vue'
import Ping from 'ping.js'
import { v4 as uuidv4 } from 'uuid'
import SearchConfigModal from './SearchConfigModal'

// import { gsap, TweenLite,TimelineLite } from 'gsap'
// const timeline = window.gsap.timeline()

const contextmenuConfig = [
  {title: '开启多引擎搜索结果', key: 'OpenMutiResult'},
  // {title: '自定义背景', key: 'customBackground', disabled: true},
]

var p = new Ping()

// 初始化搜索组件配置
// export function initSearchConfig () {
//   console.log('初始化搜索组件配置')
//   appState.searchConfig = initialSearchConfig
// }
export default {
  name: 'Search',
  components: {
    CaretDownOutlined,
    FormOutlined, SettingOutlined, SearchConfigModal
  },
  inject: ['storeUtil', 'App','appState', 'appEventBus'],
  data() {
    return {
      searchText: '',
      searchTextStore: '',
      s: [],
      suggestionBoxVisible: false,
      activeSugIndex: -1,

      // 推荐数据loading
      sugLoading: false,
      changeSugLoadingStatusTimer: null,

      engineInfo,

      contextmenuConfig,
      // ------START 搜索历史 ------
      searchHistory: [],
      searchHistoryTagDeleteVisible: false,
      // 是否允许searchHistory watch执行
      allowSHWatch: true,
      searchHistoryChanged: false,
      // ------END 搜索历史 ------
      // 网络延迟
      netDelay: '',
      // 最新一次的延迟检测时间戳
      latestDetectionTime: 0,
      config: {
        curEngineName: 'baidu',
        // 是否开启多搜索引擎结果
        isMutiResult: false,
        engineStatusStore: {
          baidu: true
        }
      },
      dropdownVisible: false
    }
  },
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  computed: {
    curEngineInfo () {
      return engineInfo[this.config.curEngineName]
    },
    sLength () { return this.s.length },
    placeholder () {
      return this.config.isMutiResult ? '输入并搜索 ': '输入并搜索 ，tab切换搜索引擎. . .'
    },
    engineList(){
      return this.appState?.module?.activeEngineList||[]
    }
    // config () {
    //   // if (!this.appState.searchConfig) {
    //   //   initSearchConfig()
    //   // }
    //   return this.appState.searchConfig
    // },
  },
  watch: {
    config: {
      handler(val) {
        console.log('this.$options.name', this.$options.name)
        this.storeUtil.setStore({name: this.$options.name, content: val})
        // console.log('config watch', val)
      },
      deep: true
    },
    searchHistory: {
      handler(val) {
        if (!this.allowSHWatch) {
          this.allowSHWatch = true
          return
        }
        // console.log('searchHistory watch')
        this.storeUtil.setStore({name: 'searchHistory', content: val})
      },
      deep: true
    }
  },
  beforeCreate(){
    initSearch(this)
  },
  created () {
    this.initStore()
    // this.showSuggestion = throttle(this.showSuggestion, 1000)
    this.initContextmenu()
    requestIdleCallback(this.preloadSearchIcons)
  },
  mounted () {
    this.appEventBus.on('focusOnSearchCom', () => {
      this.$refs.inputSearch?.focus()
    })
    this.initRecordSearchMessageHandler()
    console.log('this.$refs.searchEngineDropdown', this.$refs.searchEngineDropdown)
  },
  methods: {
    showSearchEngineModal(){
      // this.appEventBus.emit('showSearchEngineModal')
      this.$refs.SearchConfigModal.show()
      this.dropdownVisible = false
    },
    uuidv4,
    onChange () {
      // console.log('onchange', e)
      // .trim()
      const searchText = this.searchTextStore = this.searchText
      if (!searchText) {
        this.clearSuggestionBoxData()
        return
      }
      this.showSuggestion(searchText)
      this.resetActiveSugIndex()
      this.dropdownVisible = false
    },
    initStore(){
      // console.log('this.name', this.$options.name)
      const localConfig = this.storeUtil.getStore({name: this.$options.name})
      if (localConfig) {
        this.config = localConfig
      }
      this.recoverSearchHistory()
    },
    initContextmenu(){
      if (this.config.isMutiResult) {
        contextmenuConfig[0].key = 'CloseMutiResult'
        contextmenuConfig[0].title = '关闭多引擎搜索结果'
      } else {
        contextmenuConfig[0].key = 'OpenMutiResult'
        contextmenuConfig[0].title = '开启多引擎搜索结果'
      }
      // this.updateContextmenu()
    },
    showSuggestion (searchText) {
      if (searchText) {
        this.curEngineInfo.showSuggestion(searchText)
      }
    },
    /**
    * 更改sugLoading状态
    *
    * @param {boolean} loading 状态目标
    * @return {undefined}
    */
    changeSugLoadingStatus () {
      // target = true
      // clearTimeout(this.changeSugLoadingStatusTimer)
      // if (target) {
      //   this.changeSugLoadingStatusTimer = setTimeout(() => {
      //     this.sugLoading = true
      //   }, 500)
      // } else {
      //   this.sugLoading = false
      // }
    },
    onKeydown (e) {
      // onArrowDown
      const activeSugIndex = this.activeSugIndex
      // if (!this.activeSugIndex) {
        // this.activeSugIndex = -1
      // }
      // console.log('e', e)
      keyDownStrategies[e.keyCode]?.(e, this)

      if (e.keyCode === 40) {
        (activeSugIndex < (this.sLength - 1)) && this.activeSugIndex++
        this.updateCurSearchText.call(this)
        e.preventDefault()
      // onArrowUp
      } else if (e.keyCode === 38) {
        if (activeSugIndex > 0)  {
          this.activeSugIndex--
          this.updateCurSearchText.call(this)
        } else {
          this.activeSugIndex = -1
          this.updateCurSearchText.call(this, true)
        }
        e.preventDefault()
      }
    },
    updateCurSearchText(reset){
      if (reset) {
        this.searchText = this.searchTextStore
        return
      }
      this.searchText = this.s[this.activeSugIndex]
    },
    resetActiveSugIndex() {
      this.activeSugIndex = -1
    },
    /**
    * 触发关键字查询
    *
    * @param {string} searchText 查询关键字
    * @param {object} options.record 是否记录到历史记录
    * @return {undefined}
    */
    onSearch(searchText, options) {
      // console.log('onsearch', searchText)
      // console.log('onsearch', options)

      if (!searchText) {return}
      !options && (options = {
        record: true,
        fromTag: false,
      })
      const encodedSearchText = encodeURIComponent(searchText.trim())

      // if (!searchText.trim()) {
      //   return
      // }
      // console.log(' options.record',  options.record)
      options.record && this.appState.appConfig.saveSearchHistory && searchText && this.recordSearch(searchText)

      // console.log('onSearch')
      if(this.config.isMutiResult){
        this.engineList.forEach(name => {
          if (this.config.engineStatusStore[name]) {
            setTimeout(() => {
              engineInfo[name].onSearch(encodedSearchText)
              if (!options.fromTag) {
                this.searchText = ''
              }
            }, 20)
          }
        })
      } else {
        setTimeout(() => {
          this.curEngineInfo.onSearch(encodedSearchText)
          if (!options.fromTag) {
            this.searchText = ''
          }
        }, 20)
      }

      this.dropdownVisible = false
    },
    // 跳转到搜索页
    // naviToSearch (text) {
    //   window.open(`https://www.baidu.com/s?wd=${text}`)
    // },
    handleBlur () {
      setTimeout(() => {
        this.suggestionBoxVisible = false
      }, 100)
    },

    // --- 搜索建议相关逻辑 ---
    clearSuggestionBoxData () {
      this.suggestionBoxVisible = false
      this.s = []
    },

    // --- 搜索引擎切换相关逻辑 ---
    onInputEngineSwitch(engineName){
      if (engineName === this.config.curEngineName) {
        return
      }
      this.config.curEngineName = engineName
      this.$nextTick(this.$refs.inputSearch.focus)
      this.showSuggestion(this.searchText)
      this.dropdownVisible = false
    },
    // showMutiWrap(noAnimation){
    //   const mutiWrap =  this.$refs.mutiWrap
    //   if (noAnimation) {
    //     timeline.to(mutiWrap, {
    //       opacity: 1,
    //       duration: 0,
    //     })
    //     return
    //   }
    //   timeline.fromTo(mutiWrap, {
    //     opacity: 0,
    //     y: 10
    //   }, {
    //     opacity: 1,
    //     y: 0,
    //     duration: 0.3,
    //   })
    // },
    // hideMutiWrap(){
    //   const mutiWrap =  this.$refs.mutiWrap
    //   timeline.to(mutiWrap, {
    //     // display: 'none',
    //     opacity: 0,
    //     y: 10,
    //     duration: 0.3,
    //   })
    // },
    // 引擎状态变更
    toggleEngineStatus(name){
      const engineStatusStore = this.config.engineStatusStore
      if (this.config.isMutiResult) {
        engineStatusStore[name] = !engineStatusStore[name]
      } else {
        this.onInputEngineSwitch(name)
        this.engineList.forEach(val => {
          engineStatusStore[val] = false
        })
        engineStatusStore[name] = true
      }
    },

    // ------START 搜索历史逻辑 ------
    deleteHistory(index){
      this.searchHistory.splice(index, 1)
    },
    recoverSearchHistory(){
      console.log('recoverSearchHistory')
      const searchHistory = this.storeUtil.getStore({name: 'searchHistory'})
      if (searchHistory) {
        this.searchHistory = searchHistory
      }
    },
    // 记录搜索历史
    recordSearch(searchText){
      this.searchHistory.unshift({searchText, id: uuidv4()})
      this.searchHistory = this.searchHistory.splice(0,100)

      // 弃用postMessage方案，仅适合点对点
      // postMessage({type: 'recordSearch', value: searchText}, '*')
      // postMessage('hello', '*')
    },
    initRecordSearchMessageHandler(){
      // console.log('初始化接收其他窗口的历史记录更新通知')
      // window.addEventListener("message", this.recordSearchMessageHandler, false)
      // 等待页面可见后再同步搜索历史
      this.appEventBus.on('show', () => {
        // console.log('this.searchHistoryChanged', this.searchHistoryChanged)
        if (this.searchHistoryChanged) {
          this.searchHistoryChanged = false
          this.recoverSearchHistory()
        }
      })

      window.addEventListener('storage', (e) => {
        if (e.key === 'cyansearchHistory' ) {
          console.log('searchHistory storage change')
          if (document.hidden) {
            this.searchHistoryChanged = true
          } else {
            const data = JSON.parse(e.newValue)
            this.allowSHWatch = false
            this.searchHistory = data.content
            console.log('data', data)
          }
        }
      })
    },
    // 处理其他窗口的历史记录更新通知
    recordSearchMessageHandler(event){
      // if (event.data)
      console.log('event', event)
    },
    clearSearchHistory(){
      this.searchHistory = []
      this.searchHistoryTagDeleteVisible = false
    },
    // ------END 搜索历史逻辑 ------


    // --- 处理右键菜单逻辑 ---
    // 开启查看多搜索引擎结果功能
    onOpenMutiResult () {
      console.log('onMutiResult')
      this.config.isMutiResult = true
      contextmenuConfig[0].key = 'CloseMutiResult'
      contextmenuConfig[0].title = '关闭多引擎搜索结果'
      this.updateContextmenu()
    },
    onCloseMutiResult () {
      this.config.isMutiResult = false
      contextmenuConfig[0].key = 'OpenMutiResult'
      contextmenuConfig[0].title = '开启多引擎搜索结果'
      this.updateContextmenu()
    },
    updateContextmenu(){
      console.log('updateContextmenu')
      // this.App.onContextmenuUpdate(contextmenuConfig)
    },
    // onRightClick(e){
    //   if (this.$$VContextmenuCounteract) {
    //     // this.$$VContextmenuCounteract = false
    //     return
    //   } else {
    //     console.log('e', e)
    //     // e && e.preventDefault()
    //   }
    //   console.log('this.appState', this.appState)
    //   this.appState.activeCard = this
    // }

    // ------------------start 搜索聚焦 ------------------
    onFocus(){
      this.netDelay = ''
      const latestDetectionTime = this.latestDetectionTime = new Date().getTime()
      p.ping(this.curEngineInfo.pingTestUrl)
        .then(data => {
          console.log("Successful ping: " + data)
          if (latestDetectionTime === this.latestDetectionTime) {
            this.netDelay = data
          }
        })
        .catch(data => {
          console.error("Ping failed: " + data)
          if (latestDetectionTime === this.latestDetectionTime) {
            this.netDelay = -1
          }
        })

    },
    // ------------------end 搜索聚焦 ------------------
    // ------START 性能优化 ------
    preloadSearchIcons(){
      let image
      for (const key in engineInfo) {
        image = new Image()
        image.src = engineInfo[key].iconUrl
      }
    }
    // ------END 性能优化 ------
  }
}
</script>

<style lang="less" >

.engine-item{
  position: relative;
  // background-color: #ebeef1 !important;
  // background: rgba(255,255,255,0.5) !important;
  &.active{
    // background: var(--active-color) !important;
    // background: linear-gradient(90deg, var(--main-color) 0%,var(--main-color) 7%,#fff 8%,#fff 100%) !important;
    transition: all 1.3s !important;
    img{
      transform: scale(1.2, 1.2);
      transition: all .3s;
    }
  }
  &.active::before {
    position: absolute;
    top: 40%;
    left: 2px;
    content: " ";
    height: 10px;
    width: 1.5px;
    background-color: var(--active-color);
    z-index: 10;
    animation: breathe-opacity-ltx ease 1.5s infinite;
    border-radius: 50%;
  }
  &.active::after {
    position: absolute;
    top: 40%;
    right: 2px;
    content: " ";
    height: 10px;
    width: 1.5px;
    background-color: var(--active-color);
    z-index: 10;
    animation: breathe-opacity-rtx ease 1.5s infinite;
    border-radius: 50%;
  }
}

@keyframes breathe-opacity-ltx {
  0% {
    opacity: 0;
    transform: translateX(-4px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(3px);
  }
}
@keyframes breathe-opacity-rtx {
  0% {
    opacity: 0;
    transform: translateX(4px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-3px);
  }
}
</style>
<style lang="less" scoped>
.search-history::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.search-history::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.search-history::-webkit-scrollbar-thumb:hover{
  background-color: hsla(220, 4%, 58%, .4);
}
.search-history::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsla(220, 4%, 58%, .2);
}
.muti-wrap{
  // opacity: 0;
  // display: none;
  display: inline-block;
  // padding: 4px 0 4px 5px;
  background-color: #fff;
  line-height: 0;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  // border-bottom: 2px solid#3D5474;
  border-bottom: 3px solid  #d9d9d9;

  &.muti{
    // border-bottom: 3px solid #80DA9D;
    // background-color:
// background: linear-gradient(180deg, rgba(56, 100, 173, 0.4) 0%, rgb(255, 255, 255) 80%);
    border-bottom: 3px solid #3D5474;
  }
  img{
    transition: all 0.2s ease-in-out;
    border-top: 3px solid transparent;
    &:hover{
      background-color: #F0F1F5;
    }
    &.active{
      filter: grayscale(0);
      border-top: 3px solid #3D5474;
      // background-color:  rgba(56,100,173, 0.5);
      // animation: scaleBig ease-in-out 0.3s;
    }
    filter: grayscale(1);
    cursor:pointer;
    box-sizing: content-box;
    padding: 4px 4px;
  }
}
.shadow{
  box-shadow: -1px -3px 20px -6px #C6C6C4;
}
.search{
  z-index: 1;
  // padding: 10px;
  /* background-color: #0ABFEE; */
  height: 100%;
  overflow: hidden;

  .ant-tag{
    position: relative;
    max-width: 120px;
    overflow:hidden;
    text-overflow:ellipsis; //溢出用省略号显示
    cursor: pointer;
    color: #fff;
    font-size: 12px !important;
    background: var(--theme-bg-color-active);
    // background-color: rgba(120,120,120,0.7);
    border: unset;
  }
  .ant-tag /deep/.anticon{
    color: #fff;
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: var(--theme-bg-color-active);
    // border-radius: 40px;
    padding: 3px;
  }
  .search-history{
    position: relative;
    height: calc(100% - 50px);
    margin-top: 6px;
    padding-right: 6px;
    // padding-bottom: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.input-search{
  transition: box-shadow 0.5s;
  border: 1px solid transparent;
  font-family: "PingFang SC", system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, sans-serif;
}
// .search-shadow{
// }
.suggestion-wrap{
  background-color: #fff;
  // border: 1px solid #ccc;
  // z-index: 100;
  transform: translateX(1px);
  position: absolute;
  left: 0;
  right: 0;
  top: 42px;
  width: 100%;

  .suggestion-item{
    padding: 0 10px;
    line-height: 36px;
    cursor: pointer;
    color: var(--font-black-color);

    &:hover{
      background-color: #EEEEEE;
    }
    &.active{
      background-color:#EEEEEE;
    }

  }
}
.inner{
  position: absolute;
  background-color: #fff;
  transform: translateY(8px);
  right: 0;
  left: 0;
  box-shadow: -1px -3px 20px -6px rgba(198, 198, 196, 0.80);
  border-radius: 4px;
}
.delay-panel{
  text-align: right;
  position: absolute;
  // width: 100px;
  height: 24px;
  right: 49px;
  padding-right: 2px;
  top: 4px;
  // background-color: #f00;
  z-index: 2;
  font-weight: 100;
  color: var(--font-second-color);
  span{
    font-size: 12px;
    // color: var(--main-color);
  }
  // .breathe-opacity{
  //   animation: breathe-opacity ease-in-out 1s;
  // }
}
.engine-list-dropdown /deep/ .ant-dropdown-menu{
  background-color: unset !important;
}
.edit-btn{
  // position: absolute;
  float: right;
  width: 100px;
  // top: 0;
  // right: 0;
}
</style>