import { appEventBus } from '../store/store'

const search = {
  key: 'search',
  name: '搜索',
  description: '支持多种搜索引擎',
  iconHref: 'https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/icon/search.svg',
  initialLayout: {
    'w': 6, 'h': 3,
    type: 'search'
  }
}

const dida = {
  key: 'dida',
  name: '滴答清单',
  iconHref: '//cdn.dida365.com/static/img/favicon.ico',
  description: '一个帮你高效完成任务和规划时间的应用',
}

// const tilePlus = {
//   key: 'tilePlus',
//   name: '新增磁贴',
//   iconHref: 'https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/add-select.svg',
//   description: '桌面的新增磁贴入口',
//   initialLayout: {
//     'w': 1, 'h': 3,
//     type: 'tilePlus'
//   }
// }

const trending = {
  key: 'trending',
  name: '今日热榜',
  iconHref: 'https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/%E5%8A%A8%E6%80%81.svg',
  description: '今日热榜',
  // isDynamicCom: true,
  sourceHref: 'https://grid-new-tab-1256341180.file.myqcloud.com/widget/myLib.umd.min.js',
  initialLayout: {
    'w': 4, 'h': 10,
    type: 'trending'
  }
}

// const weather = {
//   key: 'weather',
//   name: '和风天气',
//   iconHref: '//www.qweather.com/favicon.ico?v=2021010553',
//   description: '和风天气®',
//   initialLayout: {
//     'w': 4, 'h': 10,
//     type: 'weather'
//   }
// }
// const siteCollection = {
//   key: 'siteCollection',
//   name: '收藏夹',
//   iconHref: 'https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/folder.svg',
//   description: '今日热榜',
//   initialLayout: {
//     'w': 4, 'h': 10,
//     type: 'siteCollection'
//   }
// }

export const widgetDisplayList = [
  search,
  dida,
  // tilePlus,
  trending,
  // weather
  // siteCollection
]
export const widgetStatus = {
  dida: { visible: false },
  // tilePlus: { visible: true },
  search: { visible: true },
  trending: { visible: true },
  // weather: { visible: false },
  // siteCollection: { visible: true },
}
export const widgetsInfo = {
  dida, search, trending,
  //  weather
  // tilePlus,
}
export function onWidgetVisibleChange (checked, widgetKey) {
  console.log('onWidgetVisibleChange', checked, widgetKey)
  if (checked) {
    appEventBus.emit('addWidget', widgetKey)
    // widgetsInfo[widgetKey].onShow?.()
  } else {
    appEventBus.emit('removeTile', widgetKey)
  }
}