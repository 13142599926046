<template>
  <div id="timedate" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
    <span id="mo" class="date">0</span><span class="date">月</span>
    <span id="dy" class="date">0</span><span class="date">日</span>
    <span> · </span>
    <span id="week" class="date">星期四</span>
    <span id="h" class="margin-left">0</span> <span>: </span>
    <span id="m">0</span>
    <!-- <div class="date-wrap"> -->
    <!-- </div> -->

    <div v-if="!firstRenderFlag" v-show="calendarVisible" id="grid-calendar-wrap">
      <Calendar ></Calendar>
    </div>
  </div>
</template>


<script>
import Calendar from '@/components/Calendar/Calendar'
const weekMap = [
  '星期天',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
]

var tags = [ 'h', 'm', 'mo', 'dy', 'week']
var tagNodes = []
function updateClock() {
  var now = new Date()
  // var milli = now.getMilliseconds(),
  const min = now.getMinutes(),
    hou = now.getHours(),
    mo = now.getMonth(),
    dy = now.getDate(),
    week = now.getDay()

    // yr = now.getFullYear()
    // (sec + '').padStart(2, '0'),
// sec = now.getSeconds(),
  const corr = [
    (hou + '').padStart(2, '0'), (min + '').padStart(2, '0'),
    mo + 1,
    dy,
    weekMap[week],
  ]
  for (var i = 0; i < tags.length; i++){
    tagNodes[i].nodeValue = corr[i]
  }
}

function initClock() {
  updateClock()
  window.setInterval(updateClock, 20000)
}

// END CLOCK SCRIPT

export default {
  data() {
    return {
      firstRenderFlag: true,
      calendarVisible: false,
      lock: false,
      mouseenterTimer: null
    }
  },
  mounted () {
    for (var j = 0; j < tags.length; j++){
      tagNodes.push(document.getElementById(tags[j]).firstChild)
    }
    initClock()
  },
  components: {
    Calendar
  },
  methods: {
    toggleCalendarView(){
      this.calendarVisible = !this.calendarVisible
    },
    showCalendar(){
      this.firstRenderFlag && (this.firstRenderFlag = false)
      this.calendarVisible = true
    },
    onMouseenter(){
      this.lock = false
      clearTimeout(this.mouseenterTimer)
      this.mouseenterTimer = setTimeout(() => {
        if (this.lock) {return}
        this.showCalendar()
      }, 500)
    },
    closeCalendar(){
      this.calendarVisible = false
    },
    onMouseleave(){
      this.closeCalendar()
      this.lock = true
    }
  }
}
</script>

<style scoped>
#timedate{
  /* width: 100px; */
  box-sizing: border-box;
  position: relative;
  user-select: none;
  padding-left: 10px;
  color: #F1F8FF;
  word-break: keep-all;
}
#timedate > span,  .date-wrap > span{
  text-shadow: 0px 0px 2px #c1e2f1;
  letter-spacing: 1.5px;
}
#timedate #h, #timedate #m{
  font-weight: 100;
}
.date-wrap{
  transform: translateY(-2px);
  display: inline-block;
  /* opacity: 0.8; */
  /* font-size: 15px; */
  line-height: 36px;
  vertical-align: top;
}
.date{
  margin-top: -10px;
}
#timedate span{
  vertical-align: top;
  transition: all 0.4s;
  line-height: 36px;
}
#grid-calendar-wrap{
  position: absolute;
  padding-top: 40px;
  top: 0px;
  left: 10px;
  width: 500px;
}
</style>