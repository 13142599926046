// 全局音效管理
import { appEventBus } from '@/service/appEventBus'
// import { throttle } from 'lodash-es'
import { appState } from '@/store/store'

const tileHoverAudio = new Audio('https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/audio/SE_HoverSystemIn.wav')
let searchFocusAudio
function registerSearchFocusAudio () {
  searchFocusAudio = new Audio('https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/audio/SE_SelectNothing.wav')
}
requestIdleCallback(registerSearchFocusAudio)


const playStrategies = {
  playSearchFocusAudio () {
    if (!searchFocusAudio) {
      return
    }
    searchFocusAudio.currentTime = 0
    searchFocusAudio.play().catch(() => {})

  },
  playTileHoverAudio () {
    tileHoverAudio.currentTime = 0
    tileHoverAudio.play().catch(() => {})
  }
}

appEventBus.on('playSound', playSound)

function playSound (soundEffectName) {
  if (!soundEffectName || appState.appConfig.isSilentMode) { return }
  playStrategies[soundEffectName]?.()
}