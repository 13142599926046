<template>
  <a-popover trigger="click" placement="bottomLeft" :visible="active">
    <!-- :class="{active}"  -->
    <div class="help-wrap hover-effect" @click="active = !active">
      <div class="tip">
        ?
      </div>
      <div class="anchor">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div>
    <template #content>
      <div @click="onPopoverClick">
        <a-button size="small" @click="appEventBus.emit('navitoCommunity')"><template #icon><MessageOutlined /></template>我要反馈</a-button>
        <a-button size="small" class="margin-left-xs" @click="appEventBus.emit('showGuidePanel')">
          <template #icon><QuestionCircleOutlined /></template>帮助</a-button>
        <!-- <a-button size="small" class="margin-left-xs" @click="onNaviToZhihu">
          <template #icon><ZhihuOutlined />
          </template>@柔情
        </a-button> -->
        <!-- <a-button size="small" class="margin-left-xs" @click="onNaviToRoadmap">
          <CalendarOutlined />
          更新日志
        </a-button> -->
        <a-button size="small" class="margin-left-xs" @click="onNaviToBili">
          <img width="22" height="22" src="https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/public-assets/icon/bilibili.svg" >
          联系作者
        </a-button>
        <a-divider style="margin: 10px 0"/>
        <a href="https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/app/cyan-chrome-extension.zip">
          <a-button size="small" >
            <DownloadOutlined />获取浏览器插件（仅供开发者测试）
          </a-button>
        </a>
        <a-divider style="margin: 10px 0"/>
        <div class="text-second text-sm">
          <div>
            Cyan 版本: {{ version }}
          </div>
          <div>
            <!-- TODO -->
            <!-- 2020.11.24 -->
            上次更新在 {{ diff }}，今天是Cyan上线的第{{ fromFirstReleaseTime }}天
          </div>
        </div>
        <a-divider style="margin: 10px 0"/>
        <a class="text-second text-sm" target="_blank" href="http://beian.miit.gov.cn/">
          备案号：赣ICP备19007313号
        </a>
      </div>
    </template>
  </a-popover>
</template>

<script>
import config from '@/../package.json'
import { MessageOutlined, ZhihuOutlined, QuestionCircleOutlined, CalendarOutlined, DownloadOutlined } from '@ant-design/icons-vue'
import {getVersionDetail} from './version'
import moment from 'moment'
export default {
  inject: ['appEventBus'],
  components: {
    MessageOutlined, ZhihuOutlined, QuestionCircleOutlined, CalendarOutlined, DownloadOutlined
  },
  watch: {
    active(newval){
      if (newval) {
        setTimeout(() => {
          document.body.addEventListener('click', this.handleBodyClick)
        }, 0)

        this.checkUpdate()

        if (!this.loaded) {
          this.loaded = true
          this.init()
        }

      } else {
       document.body.removeEventListener('click', this.handleBodyClick)
      }
    }
  },
  data() {
    return {
      active: false,
      diff: '',
      // 距离第一次上线的时间间隔
      fromFirstReleaseTime: '',
      // 用户是否主动点击过
      loaded: false
    }
  },
  mounted () {
  },
  computed: {
    version () {
      return config.version
    }
  },
  methods: {
    init(){
      // 初始化部分计算逻辑
      const firstReleaseTime = moment('2021-11-24')
      const now = moment()
      this.fromFirstReleaseTime = now.diff(firstReleaseTime, 'days')
      console.log('fromFirstReleaseTime', this.fromFirstReleaseTime)
    },
    handleBodyClick(){
      this.active = false
    },
    onPopoverClick(e){
      e.stopPropagation()
    },
    onNaviToZhihu(){
      window.open('https://www.zhihu.com/people/zhu-ke-qing-90')
    },
    onNaviToBili(){
      window.open('https://space.bilibili.com/32033168')
    },
    onNaviToRoadmap(){
      window.open('https://zhukeqing.notion.site/d7a6a6d76c6a4915a9ea576e7c6b1a51')
    },
    checkUpdate(){
      getVersionDetail()
        .then(res => {
          console.log('res', res)
          this.diff = res.diff
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.help-wrap{
  position: fixed;
  top: 6px;
  right: 14px;
  z-index: 4;
  height: 30px;
  width: 38px;
  // background-color: aqua;
  border-radius: 2px;
  // transition: all 0.3s cubic-bezier(.8,-.1,.2,2.4);
  // color: var(--font-third-color);
  color: var(--font-color);
  user-select: none;
  &:hover{
    cursor: pointer;
  //   color: var(--font-color);
  //   .bar{
  //     background-color: var(--font-color);
  //   }
  }
}
.bar{
  position: absolute;
  top: 2px;
  left: 12px;
  width: 14px;
  height: 2px;
  background-color: var(--font-color);
  transform-origin: 7px 12px;
  clip-path: polygon(0 0%, 100% 0%,  90% 100%, 10% 100%);
  transition: all 0.3s cubic-bezier(.8,-.1,.2,2.4);
}
.help-wrap.active{
  color: var(--font-color);
  // .bar{
  //   top: 1px;
  //   transform-origin: 7px 10px;
  //   background-color: var(--font-color);
  // }
  // .bar:nth-child(1) {
  //   transform: rotate(0deg + 60deg);
  // }
  // .bar:nth-child(2) {
  //   transform: rotate(72deg + 60deg);
  // }
  // .bar:nth-child(3) {
  //   transform: rotate(144deg + 60deg);
  // }
  // .bar:nth-child(4) {
  //   transform: rotate(216deg + 60deg);
  // }
  // .bar:nth-child(5) {
  //   transform: rotate(288deg + 60deg);
  // }
}
.tip{
  position: absolute;
  left: 10px;
  top: 0;
  line-height: 26px;
  width: 18px;
  text-align: center;
}
.anchor{
  position: relative;
}
.bar:nth-child(1) {
  transform: rotate(0deg);
}
.bar:nth-child(2) {
  transform: rotate(72deg);
}
.bar:nth-child(3) {
  transform: rotate(144deg);
}
.bar:nth-child(4) {
  transform: rotate(216deg);
}
.bar:nth-child(5) {
  transform: rotate(288deg);
}

</style>