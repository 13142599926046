// import { cloudApp, appState } from '@/store/store'

/**
* 记录磁贴变动
*
* @param {object} tile 一个磁贴对象
* @return {undefined}
*/
export function recordTileChange (tile) {
  console.log('recordTileChange', tile)
  window._gridChange_[tile.i] = tile
}