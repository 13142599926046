<template>
  <div class="grid-wrap" >
    <grid-layout
      class="grid"
      :class="{'not-item-transition': notItemTransition}"
      ref="grid"
      v-model:layout="appState.layout"
      :cols="{ lg: 20, md: 10, sm: 6, xs: 4, xxs: 2 }"
      :row-height="20"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[1, 1]"
      :responsive="true"
      :use-css-transforms="true"
      :useStyleCursor="false"
      @layout-ready="onLayoutReadyEvent"
      @layout-updated="onLayoutUpdated"
    >
      <grid-item
        v-for="item in appState.layout"
        :class="{ zindex1: item.type === 'search'}"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :selfData="item"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        drag-allow-from=".draggable-handle">
        <Card :type="item.type" :selfLayoutInfo="item"></Card>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout2 from '@/grid-layout/vue-grid-layout.common'
import Card from './cardCommon/Card'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'lodash-es'
// import { track } from '@/service/layout'
// import { cutomTrackLayout } from '@/store/store'
import { recordTileChange } from '@/service/grid'
import { widgetsInfo } from '@/service/widget'
// import { Modal } from 'ant-design-vue'
import { widgetStatus } from '@/service/widget'
// function dragEventHandler(eventType, i, x, y, h, w){
  // console.log('dragEventHandler', eventType, i, x, y, h, w)
// }
export default {
  // setup(){
    // return cutomTrackLayout
  // },
  name: 'MainGrid',
  components: {
    GridLayout: VueGridLayout2.GridLayout,
    GridItem: VueGridLayout2.GridItem,
    Card
  },
  inject: ['storeUtil', 'appEventBus', 'appState'],
  data() {
    return {
      // layout: [],
      firstRenderFlag: true,
      colNum: 20,
      notItemTransition: true
    }
  },
  created(){
    // this.layout = this.appState.layout
    // this.initStore()
    this.appEventBus.on('addWebsiteBox', this.addWebsiteBox)
    // this.appEventBus.on('removeWebsiteTile', this.onRemoveWebsiteTile)
    this.appEventBus.on('removeTile', this.onRemoveTile)
    this.appEventBus.on('addWidget', this.onAddWidget)
    this.appEventBus.on('addCollectionTile', this.addCollectionTile)
    setTimeout(() => {
      this.notItemTransition = false
    }, 0)
  },
  mounted(){
    // this.$refs.grid.eventBus.$on('dragEvent', dragEventHandler)
    this.appState.$grid = this
  },
  computed: {
    layout () {
      return this.appState.layout
    }
  },
  methods: {
    // 增加网站图标磁贴
    addWebsiteBox(boxData){
      const i = uuidv4()
      const newTile = {
        x: (this.layout.length * 2) % this.colNum,
        y: this.layout.length + this.colNum, // puts it at the bottom
        // x: 1,
        // y: 1, // puts it at the bottom

        'w':1,'h':3,
        i, type: 'websiteBox', boxData
      }
      this.layout.push(newTile)
      this.appEventBus.emit('layoutUpdated', this.layout)
      setTimeout(() => {
        this.$refs.grid.layoutUpdate()
      }, 10)
      recordTileChange(newTile)
      setTimeout(() => {
        const el = document.querySelector('#w' + i)
        console.log('el', el)
        el.scrollIntoView({behavior: "smooth", block: 'nearest'})
      }, 400)
    },
    /**
     * 增加集合
     * @param {object|undefined} options 可选参数
     * @param {string} options.colletionName? 集合名称
     * @return {string} i 新增集合的唯一标识
    */
    addCollectionTile(options = {}){
      console.log('addCollectionTile', options)
      const i = uuidv4()
      const newTile = {
        x: (this.layout.length * 2) % this.colNum,
        y: this.layout.length + this.colNum, // puts it at the bottom
        'w':5,'h':6,
        'i': i, type: 'Collection'
      }
      if (options.colletionName) {
        newTile.data = {name: options.colletionName}
      }
      this.layout.push(newTile)
      this.appEventBus.emit('layoutUpdated', this.layout)
      setTimeout(() => {
        this.$refs.grid.layoutUpdate()
      }, 10)
      setTimeout(() => {
        const el = document.querySelector('#a' + i)
        console.log('el', el)
        el.scrollIntoView({behavior: "smooth", block: 'nearest'})
      }, 400)
      recordTileChange(newTile)
      return i
    },
    /**
     * 添加磁贴
     *
     * @param {string} 磁贴类型： websiteBox，（及所有小组件磁贴）
     * @return {undefined}
    */
    onAddWidget(widgetKey){
      const defaultTileConfig = {
        x: (this.layout.length * 2) % this.colNum,
        y: this.layout.length + this.colNum,
        'w':10,'h':20,
        'i': uuidv4(), type: widgetKey,
        // isDynamicCom: widgetsInfo[widgetKey]?.isDynamicCom
      }
      console.log('widgetsInfo[widgetKey]',widgetsInfo[widgetKey] )
      const tileConfig = {
        ...defaultTileConfig,
        ...widgetsInfo[widgetKey].initialLayout
      }
      console.log('tileConfig', tileConfig)
      this.layout.push(tileConfig)
      recordTileChange(tileConfig)
      this.$refs.grid.layoutUpdate()
    },
    onGridRemove(){
    },
    // responsive开启后，layout-updated会被频繁触发
    // eslint-disable-next-line no-unused-vars
    onLayoutUpdated: debounce(function (newLayout)  {
      // console.log('newLayout', newLayout)
      if (this.firstRenderFlag) {
        this.firstRenderFlag = false
        return
      }
      // console.log('onLayoutUpdated')
      this.appEventBus.emit('layoutUpdated', newLayout)

    }, 1000, { maxWait: 5000 }),
    // onRemoveWebsiteTile(layoutInfo){
    //   // console.log('f', layout.findIndex(item => item === activeCard.$parent.selfLayoutInfo))
    //   const layout = this.layout
    //   layout.splice(layout.findIndex(item => item === layoutInfo), 1)
    //   this.$refs.grid.layoutUpdate()
    // },
    /**
     * 删除磁贴或小组件
     *
     * @param {string} i 磁贴的唯一标识i
     * @return {undefined}
    */
    onRemoveTile(i){
      console.log('onRemoveTile', i)
      const that = this
      // console.log('f', layout.findIndex(item => item === activeCard.$parent.selfLayoutInfo))
      const layout = this.layout
      let index
      if (i) {
        if (widgetStatus[i]) {
          index = layout.findIndex(item => item.type === i)
        } else {
          index = layout.findIndex(item => item.i === i)
        }
      }
      const item = this.layout[index]
      console.log('index', index)
      console.log('item', item)
      if (!item){return}
      console.log('item', item)
      if (item.type !== 'websiteBox') {
        console.log('widgetsInfo', widgetsInfo)
        console.log('item.type', item.type)
        // let name
        // if (widgetsInfo[item.type]) {
        //   name = widgetsInfo[item.type]?.name
        // } else if (item.type === 'Collection') {
        //   name = '书签文件夹'
        // }
        // Modal.confirm({
        //   title: `确认删除 ${name} 吗？`,
        //   okText: '确认',
        //   cancelText: '取消',
        //   onOk: () => {
        //     action.call(that)
        //   },
        // })
        action.call(this)
      } else {
        action.call(this)
      }

      function action(){
        // else if (widgetKey) {
        //   index = layout.findIndex(item => item.type === widgetKey)
        // }
        const layout = that.appState.layout
        console.log('layout.findIndex(item => item.i === i)', index)
        if (index !== -1) {
          window._gridChange_[layout[index].i] = undefined
          // delete window._gridChange_[layout[index].i]
          const tileDetail = layout[index]
          if (!['Collection', 'websiteBox'].includes(tileDetail.type)) {
            this.appState.appConfig.widgetStatus[tileDetail.type].visible = false
          }
          layout.splice(index, 1)
          setTimeout(() => {
            this.$refs.grid.layoutUpdate()
          }, 10)
        }
      }
    },
    onLayoutReadyEvent(){
      // console.log('完成mount中的所有操作')
      // window._gridChangeRecording_ = true
    },
    initStore(){
      // console.log('this.name', this.$options.name)
      // const layoutStore = this.storeUtil.getStore({name: this.$options.name + '-layout'})
      // if (layoutStore) {
      //   this.layout = layoutStore
      // }
      // console.log('initStore', localConfig)
    },

    // ----start 处理外部拖拽 ----
    onDragenter(e){
      console.log('onDragenter', e)
    },
    onDragover(e){
      console.log('onDragover', e)
    }
    // ----end 处理外部拖拽 ----
  }
}
</script>

<style>
.grid-wrap{
  padding: 0 6px;
  padding-bottom: 100px;
}
.grid{
  /* padding-top: 40px; */
  min-height: calc(100vh - 48px) !important;
  padding-bottom: 100px;
  /* overflow-x: hidden; */
}
.zindex1{
  z-index: 1;
}
.zindex2{
  z-index: 2;
}
.not-item-transition .vue-grid-item{
  transition: unset;
}

</style>