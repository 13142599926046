import { appState } from '@/store/store'
import { appEventBus } from '@/service/appEventBus'
// , appEventBus
document.addEventListener('keydown', event => {
  if (event.ctrlKey && event.keyCode === 70) {
    console.log('处理搜索快捷键')
    console.log('appState', appState)
  }
  if (event.keyCode === 27) {
    // appEventBus.emit('esc')
    if (appState.curFullscreenCard) {
      appState.curFullscreenCard.quitFullscreenMode()
    }
  }
  if (event.keyCode === 191) {
    '/'
    // appEventBus.emit()
    setTimeout(() => {
      appEventBus.emit('focusOnSearchCom')
    }, 10)
  }
})

// 按 / 即可跳到搜索框
// appEventBus.on('openDevtool', () => {

// })