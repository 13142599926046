/*eslint-disable*/
import * as vue from 'vue'
import polyfill from '@/service/polyfill'
import App from './App.vue'
import shortcut from '@/service/shortcut'
// import { Button, Input } from 'ant-design-vue'
import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.less';
import './styles/global.scss'
import './styles/context-menu.css'
import './styles/tile.css'

import contentmenuDirective from './contextMenu/directive.js'
import Contextmenu from './contextMenu/Contextmenu'
import ContextmenuGroup from './contextMenu/ContextmenuGroup'
import ContextmenuItem from './contextMenu/ContextmenuItem'
import ContextmenuSubmenu from './contextMenu/ContextmenuSubmenu'

import contextMenuRegedit from '@/service/contextMenuRegedit'
import { initDirectives } from '@/service/directives'
import { appEventBus } from '@/service/appEventBus'

import moment from 'moment'
import 'moment/dist/locale/zh-cn'; // moment.locale('zh-cn');

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';

VMdPreview.use(githubTheme);

moment.locale('zh-cn')
window.Vue = vue

let appLaunchSignalResolve
window.appLaunchSignal = new Promise((resolve, reject) => {
  appLaunchSignalResolve = resolve
})

appEventBus.on('appLauch', () => {
  const app = vue.createApp(App)
  initDirectives(app)
  app.use(Antd)
  app.use(VMdPreview);

  app.component('v-contextmenu', Contextmenu)
  app.component('v-contextmenu-group', ContextmenuGroup)
  app.component('v-contextmenu-item', ContextmenuItem)
  app.component('v-contextmenu-submenu', ContextmenuSubmenu)
  app.directive('contextmenu', contentmenuDirective)

  app.mount('#app')
  window.mainApp = app
  appLaunchSignalResolve()
  window.scrollTo(0, 0)

});

