<template>
  <!-- v-if="appState.appConfig.widgetStatus.dida.visible" -->
  <div class="iframe-weather" @click.right="onRightClick" >
    <div class="inner" v-if="!appState.isDevMode">
      <iframe
        v-if="iframe1Visible"
        width="100%"
        name="weather"
        height="100%"
        src="https://www.qweather.com/weather/pudongxinqu-101020600.html"
        frameborder="0"></iframe>
      <iframe
        v-if="iframe2Visible"
        width="100%"
        name="weather"
        height="100%"
        src="https://www.qweather.com/weather/pudongxinqu-101020600.html"
        frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['appState', 'appEventBus'],
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  data() {
    return {
      iframe1Visible: true,
      iframe2Visible: false
    }
  },
  mounted () {
    setInterval(() => {
      if (!document.hidden) {
        this.refresh()
      }
    }, 600000)
    this.appEventBus.on('LongLeaveBack', this.refresh, false)
  },
  methods: {
    onRightClick(){
      this.appState.activeCard = this
    },
    refresh(){
      console.log('Weather：刷新天气数据')
      if (!this.iframe1Visible) {
        this.iframe1Visible = !this.iframe1Visible
        setTimeout(() => {
          this.iframe2Visible = !this.iframe2Visible
        }, 5000)
      } else {
        this.iframe2Visible = !this.iframe2Visible
        setTimeout(() => {
          this.iframe1Visible = !this.iframe1Visible
        }, 5000)
      }
      console.log('this.iframe1Visible',this.iframe1Visible )
      console.log('this.iframe2Visible',this.iframe2Visible )
    }
  }
}
</script>

<style lang="less">
.resizing iframe{
  pointer-events: none;
}
.iframe-weather{
  position: relative;
  /* padding: 20px 10px 10px 10px; */
  width: 100%;
  height: 100%;
  opacity: 0.9;
  overflow: hidden;
  iframe{
    height: calc(100% + 60px);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -60px;
  }
}
// .iframe-weather .inner{
  // height: calc(100% + 60px);
  // margin-top: -60px;
// }
</style>