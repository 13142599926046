import request from '@/api/request'
import moment from 'moment'
import { appEventBus } from '@/store/store'
// import { notification, Button } from 'ant-design-vue'
// import { h } from 'vue'

appEventBus.on('LongLeaveBack', versionCheck, false)

/**
* 获取是否有版本更新及更新时间
* @param {Object} 仅计算需要的字段
*
* @return {Object} diff 距离上次更新的时间
* haveNewVersion 是否有新版本
*/
export function getVersionDetail (options) {
  return request({
    method: 'head',
    url: 'https://start.zhukeqing.xyz/',
    headers: {
      'interceptors-free': true,
      'cache-control': 'no-cache'
    }
  })
    .then(res => {
      const result = {}
      const serverLastModifiedDate = new Date(res.headers['last-modified'])
      // let now = new Date()

      // let serverLastModifiedMoment1 = moment.utc(serverLastModifiedDate).local()
      // TODO 等待腾讯云修复last-modified bug
      const serverLastModifiedMoment2 = moment(serverLastModifiedDate)
      // .subtract(8, 'hours')
      // let serverLastModifiedMoment3 = moment.utc(now).local()
      // let serverLastModifiedMoment4 = moment.utc(now)
      // console.log('serverLastModifiedMoment1', serverLastModifiedMoment1.format('YYYY/MM/DD HH:mm:ss'))
      console.log('serverLastModifiedMoment2', serverLastModifiedMoment2.format('YYYY/MM/DD HH:mm:ss'))
      // console.log('serverLastModifiedMoment3', serverLastModifiedMoment3.format('YYYY/MM/DD HH:mm:ss'))
      // console.log('serverLastModifiedMoment4', serverLastModifiedMoment4.format('YYYY/MM/DD HH:mm:ss'))
      let diff
      if (!options || options.diff) {
        diff = serverLastModifiedMoment2.fromNow()
        result.diff = diff
      }
      let haveNewVersion
      if (!options || options.haveNewVersion) {
        const localLastModifiedDate = new Date(document.lastModified)
        const localLastModifiedMoment = moment(localLastModifiedDate).subtract(8, 'hours')
        const localTimeStamp = localLastModifiedMoment.format('x')
        const serverTimeStamp = serverLastModifiedMoment2.format('x')
        console.log('localTimeStamp', localTimeStamp)
        console.log('serverTimeStamp', serverTimeStamp)
        if (serverTimeStamp === localTimeStamp) {
          console.log('本地版本与远程版本一致')
          haveNewVersion = false
        } else {
          console.log('本地版本与远程版本不一致')
          haveNewVersion = true
        }
        result.haveNewVersion = haveNewVersion
      }

      return result
    })
}

async function versionCheck () {
  if (process.env.NODE_ENV === 'development') { return }
  const res = await getVersionDetail({ haveNewVersion: true })
  if (!res.haveNewVersion) { return }
  // const key = `open${Date.now()}`
  // notification.info({
  //   message: '有新版本',
  //   description:
  //     '点击确定或刷新页面更新🔄',
  //   btn: h(
  //     Button,
  //     {
  //       type: 'primary',
  //       onClick: () => { notification.close(key); location.reload() },
  //     },
  //     '确定',
  //   ),
  //   key,
  // })
}