<template>
  <a-modal v-model:visible="addWebsiteModalVisible" :title="options.title" :footer="null">
    <div class="EditWegsiteCard" :class="{'card-common': isCardStyle}">
      <a-form
        ref="form"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules"
        autocomplete="new-password">
        <a-form-item label="网站地址" :help="tip" >
          <a-textarea
            @change="onAddressChange"
            v-model:value="form.address"
            :auto-size="{ maxRows: 10 }"
            autocomplete="new-password"
          />
        </a-form-item>
        <a-form-item label="网站名称" >
          <a-textarea v-model:value="form.name" />
        </a-form-item>

        <a-form-item label="网站显示">
          <a-radio-group v-model:value="form.type">
            <a-radio value="icon">
              图标
            </a-radio>
            <a-radio value="color">
              纯色
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <!-- <a-form-item label="显示名称" name="displayName" v-if="form.type === 'color'">
          <a-input v-model:value="form.c" />
        </a-form-item> -->

        <a-form-item label="网站图标" name="icon" help="网站图标最大1MB" v-if="form.type === 'icon'">
          <a-upload
            v-model:fileList="fileList"
            name="avatar"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="cloudBaseUpload"
            :before-upload="beforeUpload"
            @change="handleFileChange"
          >
            <img
              v-if="form.iconUrl"
              width="48"
              height="48"
              :src="form.iconUrl"
              alt="avatar"
              style="object-fit: cover"/>
            <div v-else>
              <!-- todo -->
              <LoadingOutlined v-if="uploadLoading"/>
              <PlusOutlined v-else />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="颜色" name="color" v-if="form.type === 'color'">
          <input type="color" v-model="form.color"/>
        </a-form-item>

        <a-form-item label="备注" name="remark" help="鼠标移入后显示备注信息">
          <a-input v-model:value="form.remark" />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 14, offset: 5 }">
          <a-button :loading="submitLoading" type="primary" @click="onSubmit">
            <!-- {{ submitLoading?'正在尝试解析':'确认' }} -->
            确认
          </a-button>
          <a-button style="margin-left: 10px;" @click="onCancle">
            取消
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { LoadingOutlined, PlusOutlined  } from '@ant-design/icons-vue'
import {throttle, cloneDeep} from 'lodash-es'
import { message } from 'ant-design-vue'
import {uploadFile} from '@/service/file'
import {
  formatUrl,
  getDomain
} from '@/utils/util'
const allowTypes = ['image/jpeg', 'image/png', 'image/x-icon', 'image/svg+xml']
import {recordTileChange} from '@/service/grid'

// function getBase64(img, callback) {
//   const reader = new FileReader()
//   reader.addEventListener('load', () => callback(reader.result))
//   reader.readAsDataURL(img)
// }
export default {
  inject: ['cloudApp','appState', 'appEventBus'],
  components: {
    LoadingOutlined, PlusOutlined
  },
  props: {
    // 是否展示卡片样式
    isCardStyle: {
      type: Boolean,
      default: true
    },
    // 外部传入网站磁贴数据，不为空时视为编辑状态
    // outForm: {
    //   type: [Object, null],
    //   default: null
    // },
    tempAddress: {
      type: [String],
      default: ''
    },
    modelValue : {
      type: [String],
      default: ''
    },
  },
  watch: {
    tempAddress (val) {
      this.form.address = val
      this.onAddressChange()
    }
  },
  mounted(){
    if (this.tempAddress) {
      this.form.address = this.tempAddress
      this.onAddressChange()
    }
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },

      form: {
        type: 'icon',
        color: '#000'
      },
      defaultForm: {
        type: 'icon'
      },
      rules: {
        address: [{ required: true, message: '请输入网站地址', trigger: 'change' }],
        name: [{ max: 200, message: '最大长度限制为200个字符', trigger: 'change' }],
        remark: [{ max: 200, message: '最大长度限制为200个字符', trigger: 'change' }],
      },
      // 地址表单的提示
      tip: '',

      fileList: [],
      imageUrl: '',
      uploadLoading: false,
      submitLoading: false,
      addWebsiteModalVisible: false,

      options: {},
      // 编辑时的参数
      // outForm: null,
      // 编辑时指向的组件实例或组件数据实例
      websiteTileCom: null
    }
  },
  created () {
    this.onAddressChange = throttle(this.onAddressChange, 1000)
  },
  methods: {
    /**
    * 展示新增、编辑网站磁贴弹窗
    *
    * @param {options.websiteTileCom?} 等待编辑的磁贴组件
    * @param {options.address?} 外界拖动进入时获取的地址
    * @param {options.collectionCom?} 触发操作的集合组件
    * @param {options.collectionEl?} 场景为编辑集合中站点，触发动作的集合元素（站点）
    * @param {options.source} 触发动作的来源
    * @return {undefined}
    */
    show(options){
      console.log('EditWebsiteCard show', options)
      this.options = options = options || {
        source: 'mainScreen',
        title: '添加站点',
      }
      const { websiteTileCom } = options

      if (websiteTileCom) {
        // 编辑场景
        this.websiteTileCom = websiteTileCom
        const newForm = cloneDeep(websiteTileCom.boxData)
        this.form = {
          ...this.defaultForm,
          ...newForm
        }
      } else if (options.collectionEl) {
        // 编辑集合中的站点磁贴
        this.websiteTileCom = options.collectionEl
        const newForm = cloneDeep(options.collectionEl.boxData)
        this.form = {
          ...this.defaultForm,
          ...newForm
        }
      } else {
        // 新增场景、拖动进入、单纯新增
        this.websiteTileCom = null
        this.form.address = options.address
      }
      this.addWebsiteModalVisible = true
    },
    // 重新渲染
    // rebuild(outForm){
    //   outForm = null
    //   const newForm = cloneDeep(outForm)
    //   this.form = {
    //     ...this.defaultForm,
    //     ...newForm
    //   }
    // },
    handleFileChange(info){
      console.log('info', info)
       if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      // if (info.file.status === 'done') {
      //   // Get this url from response in real world.
      //   getBase64(info.file.originFileObj, imageUrl => {
      //     this.imageUrl = imageUrl
      //     this.loading = false
      //   })
      // }
      // if (info.file.status === 'error') {
      //   this.imageUrl = imageUrl
      //   this.loading = false
      // }
    },
    beforeUpload (file) {
      console.log('file.type', file.type)
      const isJpgOrPng = allowTypes.includes(file.type)
      if (!isJpgOrPng) {
        message.error('文件格式不在允许范围内')
      }
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        message.error('图像必须小于1MB')
      }
      return isJpgOrPng && isLt2M
    },
    cloudBaseUpload(file){
      console.log('file', file)
      uploadFile(file)
        .then(res => {
          console.log('res', res)
          const baseAddress = `https://7465-test-18cfea-1256341180.tcb.qcloud.la/user-upload-store/${this.appState.loginState.user.uid}/`
          const fileName = res.fileID.split('/').pop()
          const downloadAddress =baseAddress + fileName
          console.log('downloadAddress', downloadAddress)
          this.form.iconUrl = downloadAddress
        })
    },
    onSubmit(){
      const form = this.form
      this.$refs.form
        .validate()
        .then(() => {
          if (!form.name) {
            form.name =  form.address
          }
          const source = this.options.source

          if (source === 'mainScreen') {
            // 主屏创建
            this.appEventBus.emit('addWebsiteBox', {
              ...form
            })
            // this.$emit('cancle')
          } else if (source === 'collection') {
            // 收藏夹创建
            this.options.collectionCom.addTile({boxData:form})
            console.log('collection add tile', form)
          } else if (source === 'editWebsite') {
            // 编辑主屏站点
            // this.$emit('confirm', form)
            recordTileChange(this.websiteTileCom.selfLayoutInfo)
            this.websiteTileCom.selfLayoutInfo.boxData = form
            this.appEventBus.emit('layoutUpdated', this.appState.layout)
          } else if (source === 'editCollectWebsite') {
            // 编辑集合中的站点
            // 直接修改collection中el.boxData
            // 调用集合的保存逻辑进行持久化
            this.websiteTileCom.boxData = form
            this.options.collectionCom.save()

          }

          this.form = {
            type: 'icon'
          }
          this.addWebsiteModalVisible = false
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    onCancle(){
      // this.$emit('cancle')
      // this.$emit('update', false)
      this.addWebsiteModalVisible = false
      this.form = {
        ...this.defaultForm
      }
    },
    addressAnalysis(){
      const address = this.form.address
      if (!address) {return}
      const url = formatUrl(address)
      const domain = getDomain(url)

      if (!domain) {return}

      this.submitLoading = true
      this.tip = '正在尝试解析'
      this.cloudApp
        .callFunction({
          name: 'address-analysis',
          data: {
            address: encodeURIComponent(url),
            domain: domain,
          }
        })
        .then((res) => {
          const {code, title, iconHref} = res.result
          if (code === 200) {
            console.log('title',title )
            console.log('iconHref',iconHref )
            title &&( this.form.name = title)
            iconHref && (this.form.iconUrl = iconHref)
          }
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => {
          this.submitLoading = false
          this.tip = ''
        })
    },
    onAddressChange(){
      // console.log('text', text)
      // console.log('this.form.address', this.form.address)
      const text = this.form.address
      console.log('text', text)
      if (!text) {return}
      this.addressAnalysis()
    }
  }
}
</script>

<style>
.EditWegsiteCard{
  width: 400px;
}
</style>