<template>
  <div
    class="row Collection"
    @drop="onDrop"
    @dragenter.prevent
    @dragover.prevent
    :id="'a'+selfLayoutInfo.i"
  >
    <!-- @dragenter="onDragenter" -->
    <!-- @dragover="onDragover" -->
    <transition name="fade" >
      <div class="delete-modal flex-center flex-direction" @click="onModalClick" v-if="deleteModalVisible">
        <div class="text-decorate">删除集合及其内容？</div>
        <div class="margin-top">
          <a-button size="small" type="danger" @click="deleteMyself">确定</a-button>
          <a-button size="small" class="margin-left-xs" @click="deleteModalVisible=false">取消</a-button>
        </div>
      </div>
    </transition>
    <!-- <div class="right-spot"></div> -->
    <!-- <div class="left-spot"></div> -->
    <a-input class="pure-input" size="small" :maxlength="25" v-model:value="name" v-forbidden/>
    <!-- <div class='list-group-wrap'> -->
    <draggable
      v-if="list&&list.length"
      class="list-group"
      tag="transition-group"
      :component-data="{
        tag: 'div',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
      v-model="list"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="i"
      @change="onDraggableChange"
    >
      <template #item="{ element }">
        <div
          class="list-group-item"
          :key="element.i"
          :class="{active: (element?.i===curActiveEl?.i)&&appState.activeCard}"
          @click="onElementClick(element)"
          @click.right="onElementRightClick(element)"
          :title="`${element?.boxData?.name} ${element?.boxData?.remark||''}`"
        >
          <!-- @drag="onDrag" -->
          <div
            v-if="element?.boxData?.type === 'color'"
            class="color-viewer title"
            :style="`background-color: ${element?.boxData.color}`"
          >
            {{ element?.boxData.name.substr(0, 1) }}
          </div>
          <img
            v-else-if="element?.boxData?.iconUrl"
            class="fade-in-5"
            width="16"
            height="16"
            :src="element?.boxData?.iconUrl"
            alt="">
          <ProfileOutlined v-else/>
          <!-- <i
                :class="
                  element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                "
                @click="element.fixed = !element.fixed"
                aria-hidden="true"
              ></i> -->
          <span class="title">
            {{ element.boxData?.name }}
          </span>
          <div class="custom-select-wrap" @click.prevent="(e) => onTipClick(e, element)">
            <div class="custom-select" :class="{active: appState.curSelectedTiles[element.i]}" ></div>
          </div>
        </div>
      </template>
    </draggable>
    <div class="tip flex flex-direction align-center justify-center" v-else>
      <!-- <Loading></Loading> -->
      <div class="content text-decorate">
        <span>
          <CaretRightOutlined style="animation: breathe-opacity ease-in-out 1.3s infinite;" />
          这是一个空的收藏夹，<a-button class="pure-button" type="link" @click="onAddWebsite"><PlusOutlined></PlusOutlined>添加站点</a-button>
          <!-- <CaretLeftOutlined style="animation: breathe-opacity ease-in-out 1.3s infinite;" /> -->
        </span>
      </div>
    </div>
    <div class="function-btn-bar" >
      <div class="btn" title="移动选中磁贴至该集合" v-if="appState.curSelectedTilesCount" @click="onMoveElements">
        <FullscreenExitOutlined>
        </FullscreenExitOutlined>
      </div>
      <div class="btn" title="全部打开" @click="openAll">
        <ExportOutlined />
      </div>
    </div>
    <!-- <rawDisplayer class="col-3" :value="list" title="List" /> -->
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
  FullscreenExitOutlined, ExportOutlined, CaretRightOutlined, ProfileOutlined, PlusOutlined
} from '@ant-design/icons-vue'
// CaretLeftOutlined
// eslint-disable-next-line no-unused-vars
import { saveCollection, removeCollection, db, localCollectionsStore, getCollection, collectionsComStore} from '@/service/Collection'
import {toggleSeletedTileStatus} from '@/service/tileControl'
import { recordTileChange } from '@/service/grid'
import { debounce } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
const logging = false

const ElMenuConfig =  [
  {title: '删除站点', key: 'RemoveWebsiteAction'},
  {title: '添加站点', key: 'AddWebsite'},
  {title: '编辑站点', key: 'EditWebsite'},
]
const CommonMenuConfig =  [
  {title: '添加站点', key: 'AddWebsite'},
  {title: '移动所选磁贴至该集合', key: 'MoveElements'},
]
export default {
  name: 'Collection',
  inject: ['storeUtil', 'App', 'appState', 'appEventBus'],
  display: 'Transitions',
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  order: 7,
  components: {
    draggable,
    CaretRightOutlined, ProfileOutlined,
    FullscreenExitOutlined,ExportOutlined, PlusOutlined
    // Loading
  },
  data() {
    return {
      // list: message.map((name, index) => {
      //   return { name, order: index + 1 }
      // }),,
      list:  null,
      // elements: [],
      drag: false,
      name: '新建收藏夹',
      contextmenuConfig: CommonMenuConfig,
      // 当前被右键选中的网站
      curActiveEl: null,
      deleteModalVisible: false,
      initCompleted: false,
      // 从本地缓存恢复的数据
      localData: null
    }
  },
  watch: {
    deleteModalVisible(newval){
      if (newval) {
        setTimeout(() => {
          document.body.addEventListener('click', this.handleBodyClick)
        }, 0)
      } else {
        document.body.removeEventListener('click', this.handleBodyClick)
      }
    },
    name(newval){
      if (!this.initCompleted) {
        return
      }
      this.onNameChange(newval)
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        firstRenderFlag: true
      }
    }
  },
  created(){
    if (!this.selfLayoutInfo.data?.lastUpdateTime) {
      this.createCollection()
    } else {
      this.recoverFromLocal()
    }
    this.initCompleted = true
    this.appEventBus.on('layoutSyncEnd', this.localDataVerification, this)
    collectionsComStore[this.selfLayoutInfo.i] = this
  },
  // mounted () {
  // },
  beforeUnmount(){
    delete collectionsComStore[this.selfLayoutInfo.i]
  },
  methods: {
    /**
    * 创建收藏夹
    *
    * @return {undefined}
    */
    createCollection(){
      logging && console.log('创建收藏夹')
      const selfLayoutInfo = this.selfLayoutInfo

      const name = this.selfLayoutInfo.data?.name
      if (name) {
        this.name = name
      }
      const params = {
        name: this.name,
        lastUpdateTime: new Date().getTime(),
        _id: this.selfLayoutInfo.i
      }
      logging && console.log( '创建收藏夹 params', params)
      selfLayoutInfo.data = params
      recordTileChange(this.selfLayoutInfo)
      setTimeout(() => {
        this.appEventBus.emit('layoutUpdated', this.appState.layout)
      }, 10)
      saveCollection({...params, elements: []})
    },
    /**
    * 从本地缓存恢复数据
    *
    * @return {undefined}
    */
    recoverFromLocal(){
      const localData = localCollectionsStore?.[this.selfLayoutInfo.i]
        // logging && console.log('localCollectionsStore', localCollectionsStore)
        // logging && console.log('从本地获取的数据：', localData)
      if (localData) {
        this.list = localData.elements.length ?  localData.elements : null
        const name = this.selfLayoutInfo.data?.name
        logging && console.log('name', name)
        name && (this.name = name)
        this.localData = localData
      } else{
        // 尝试从云端获取
        this.recoverFromCloud()
      }
    },
    onNameChange: debounce(function (newName)  {
      if (!newName) {return}
      const selfLayoutInfo = this.selfLayoutInfo
      if (!selfLayoutInfo.data) {return}
      if (this.firstRenderFlag) {
        this.firstRenderFlag = false
        return
      }
      logging && console.log('selfLayoutInfo.data.name赋值', newName)
      selfLayoutInfo.data.name = newName
      recordTileChange(this.selfLayoutInfo)
      this.appEventBus.emit('layoutUpdated')
      logging && console.log('onNameChange', newName)
      this.save()
    }, 1000, { maxWait: 5000 }),
    /**
     * 从云端恢复集合数据
     *
     * @return {undefined}
    */
    recoverFromCloud(){
      logging && console.log('从云端恢复集合数据', this.selfLayoutInfo.i)
      getCollection(this.selfLayoutInfo.i)
        ?.then(res => {
          if (!res) return
          console.log('getCollection', res)
          this.list = res.elements
          this.name = res.name
        })
    },
    sort() {
      this.list = this.list.filter((el) => !!el.boxData)
    },
    onDrop(e){
      logging && console.log('e', e)
      e.preventDefault()
      e.stopPropagation()
    },
    onRightClick(e){
      logging && console.log('onRightClick', e)
      logging && console.log('onRightClick', e.path)
      if (e.path[0].classList.contains('list-group-item') ||e.path[1].classList.contains('list-group-item') ) {
        logging && console.log('has')
        this.contextmenuConfig = ElMenuConfig
      } else {
        this.curActiveEl = null
        this.contextmenuConfig = CommonMenuConfig
      }
      this.appState.activeCard = this
    },
    onElementRightClick(el){
      logging && console.log('curActiveEl', el)
      this.curActiveEl = el
    },
    onElementClick(el){
      const openHabit = this.appState.appConfig.openHabit
      if (openHabit === 'newPage') {
        window.open(el.boxData.address)
      } else if (openHabit === 'inPlace') {
        window.open(el.boxData.address,'_self')
      }
    },
    /**
     * 删除站点
     *
     * @param {array} target 被删除的磁贴i
     * @return {undefined}
    */
    onRemoveWebsite(target){
      const index = this.list.findIndex(item => item.i === target)
      this.list.splice(index, 1)
      logging && console.log('onRemoveWebsite')
      this.save()
    },
    // 删除站点，动作源为右键点击
    onRemoveWebsiteAction(){
      this.onRemoveWebsite(this.curActiveEl.i)
    },
    handleBodyClick(){
      console.trace()
      logging && console.log('handleBodyClick')
      this.deleteModalVisible = false
    },
    onModalClick(event){
      logging && console.log('onModalClick')
      event.stopPropagation()
    },
    deleteMyself(){
      removeCollection(this.selfLayoutInfo.i)
    },
    // ------START 集合内部的磁贴增删改逻辑 ------
    /**
    * 右键菜单动作的前置判断，自删除不走该函数
    *
    * @return {Boolean} 是否允许删除
    */
    beforeTileRemove(){
      logging && console.log('beforeTileRemove')
      this.deleteModalVisible = true
      return false
    },
    // 唤醒新增子磁贴弹窗
    onAddWebsite(){
      // 展示EditWebsiteCard组件
      this.appEventBus.emit('showAddWebsiteModal', {
        source: 'collection',
        collectionCom: this,
        title: `为收藏夹 ${this.name} 添加站点`
      })
    },
    // 唤醒编辑站点弹窗
    onEditWebsite(){
      const curActiveEl = this.curActiveEl
      // 展示EditWebsiteCard组件
      this.appEventBus.emit('showAddWebsiteModal', {
        source: 'editCollectWebsite',
        collectionCom: this,
        collectionEl: curActiveEl,
        title: '编辑站点'
      })
    },
    /**
     * 在集合下新增子磁贴
     *
     * @param {object|array} websitexEl 一个或多个磁贴信息，元素示例：
     * boxData:{
     * address: "https://next.antdv.com/components/modal-cn"
     * color: "#000"
     * iconUrl: "//aliyuncdn.antdv.com/favicon.ico"
     * name: "Ant Design Vue"
     * type: "icon"}
     * @return {undefined}
    */
    addTile(websitexEl){
      if (Array.isArray(websitexEl)) {
        websitexEl.forEach(el => {
          this.addSingleTile(el)
        })
      } else {
        this.addSingleTile(websitexEl)
      }
      this.save()
    },
    addSingleTile(websitexEl){
      const newTile = {}
      newTile.boxData = websitexEl.boxData
      logging && console.log('addTile', websitexEl)
      newTile.i = uuidv4()
      newTile.type = 'collection-item'
      newTile.parentCollectionIndex = this.selfLayoutInfo.i
      if (this.list) {
        this.list.push(newTile)
      } else {
        this.list = [newTile]
      }
    },
    // change event is triggered when list prop is not
    // null and the corresponding array is altered due to drag-and-drop operation.
    // newIndex, oldIndex, element
    onDraggableChange() {
      logging && console.log('arguments', arguments)
      this.save()
    },
    /**
     * 保存本集合数据至本地和远程
     *
     * @return {undefined}
    */
    save(){
      logging && console.log('保存本集合数据至本地和远程')
      const selfLayoutInfo = this.selfLayoutInfo
      const data = {
        name: this.name,
        lastUpdateTime: new Date().getTime(),
        _id: this.selfLayoutInfo.i
      }
      selfLayoutInfo.data = data
      recordTileChange(this.selfLayoutInfo)
      setTimeout(() => {
        this.appEventBus.emit('layoutUpdated')
      }, 10)
      saveCollection({...data, elements: this.list})
    },
    localDataVerification(){
      // 本地数据校验
      console.log('本地数据校验 this.localData?.lastUpdateTime', this.localData?.lastUpdateTime)
      console.log('本地数据校验 this.selfLayoutInfo.data.lastUpdateTime', this.selfLayoutInfo.data.lastUpdateTime)
      if (!this.localData || this.localData?.lastUpdateTime !== this.selfLayoutInfo.data.lastUpdateTime) {
        this.recoverFromCloud()
      }
    },
    // ------END 集合内部的磁贴增删改逻辑 ------
    // ----start 处理拖拽 ----
    onDragenter(e){
      console.log('onDragenter', e)
    },
    onDragover(e){
      console.log('onDragover', e)
    },
    onDrag(e){
      console.log('onDrag', e)
    },
    // ----end 处理拖拽 ----
    // ----start 集合按钮操作事件 ----
    openAll(){
      this.list?.forEach(item => {
        window.open(item.boxData.address)
      })
    },
    // 移动所有当前选中的网站磁贴至当前集合
    onMoveElements(){
      this.appEventBus.emit('moveCollectionElements', this)
    },
    // ----end 集合按钮操作事件 ----
    onTipClick(e, ele){
     toggleSeletedTileStatus(ele)
      e.stopPropagation()
    },
  }
}
</script>

<style lang="less" scoped>
.Collection{
  overflow-y: auto;
  // overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-top: 30px;
  background-color: var(--theme-bg-color-light);
  // opacity: 0.22;
  transition: opacity 0.3s;

  // &:hover{
  //   opacity: 1;
  // }
  .delete-modal{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
  }

  .pure-input {
    position: absolute;
    top: 0px;
  }
  .tip{
    width: 100%;
    height: 100%;
    // border-radius: 100px;
    // background-color: rgba(139, 201, 233, 0.7);
    .content{
      // line-height: 30px;
      // padding: 14px;
      background-color: rgba(57, 128, 161, 0.2);
      box-shadow: 0px 0px 8px 6px rgba(57, 128, 161, 0.2);
      user-select: none;
      // background-image: url("/rec-large.png");
      // border: 3px solid rgba(193,226,241, 0.4);
    }
  }
  .function-btn-bar{
    display: flex;
    justify-content: end;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: var(--font-color);
    // padding: 3px;
    opacity: 0;
    height: 2rem;
    transition: all .25s ;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1) ;

    .btn{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.4rem;
      width: 1.4rem;
      // line-height: 1.4rem;
      // text-align: center;
      // vertical-align: center;
      margin-left: 8px;
      background-color: var(--theme-bg-color-dark);
      cursor: pointer;
      transition: all cubic-bezier(0.68, -0.55, 0.265, 1) 0.3s;
      &:hover{
        transform: scale(1.1);
        filter: brightness(1.2);
        background-color: var(--theme-bg-color-active);
      }
    }
  }
  &:hover{
    .function-btn-bar{
      opacity: 1;
      top: -1.6rem;
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  // filter: brightness(120%);
}
.list-group-wrap{
  // position: relative;
  overflow: hidden;
  height: 20% !important;
}
.list-group {
  padding: 0 2px;

  // overflow-y: auto;
  // height: 100px;
  // max-height: calc(100% - 50px);
  // max-height: calc(100% - 50px);
  // min-height: 20px;
}
.card.active .list-group-item.active{
  background-color: var(--theme-bg-color-active);
}
.list-group-item {
  position: relative;

  color: #fff;
  padding: 2px 6px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 2px;
  background: var(--theme-bg-color-dark);
  transition: background 0.3s;
  display: inline-block;
  cursor: auto;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
  .title{
    display: inline-block;
    max-width: 120px;
    overflow:hidden;
    text-overflow:ellipsis; //溢出用省略号显示
    white-space: nowrap;
  }

  &:hover .custom-select{
    // transition-delay: 1s;
    border-radius: 10%;
    opacity: 0.9;
    // background-color: var(--theme-bg-color-active);
    border: 2px solid  #CBCBCB;
    width: 0.8rem;
    height: 0.8rem;
  }


  &:hover{
    background-color: var(--theme-bg-color-active);
    animation: breathe ease-in-out 3.75s infinite;
    // animation: btn-flicker ease-in-out 1.3s infinite;
    z-index: 1;
  }
  img{
    vertical-align: middle;
  }
  .title{
    padding-left: 8px;
    vertical-align: middle;
  }
  .color-viewer{
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    text-align: center;
    padding: 0 2px;
    border-radius: 2px;
    overflow: hidden;
    font-size: 12px;
  }
  // i {
  //   margin-left: 4px;
  //   cursor: default;
  //   vertical-align: middle;
  // }
}
.ray-divider{
  margin-top: 6px;
  --color: #F9FEFF;
  --blur: 1rem;
  --box-blur: calc(0.5 * var(--blur));
  --glow: var(--color);
  --size: 12rem;
  height: 2px;
  background-color: var(--color);
  // background-color: rgba(1,239,242,0.55);
  border-radius: 2px;

  box-shadow:
  // inset 0 0 0 2px rgba(0, 0, 0, 0.15),
  // 0 0 0 2px rgba(0, 0, 0, 0.15),
  // inset 0 0 var(--box-blur) var(--glow),
  0 5px 12px 1px rgba(61,137,210,1);
  // filter: brightness(120%);
}
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.not-draggable {
  cursor: no-drop;
}
.ant-input.pure-input{
  margin: 0 0 4px 0;
  background: linear-gradient(90deg, rgba(61,84,116,1) 0%,rgba(61,84,116,0.3) 10%,rgba(255,255,255,0) 100%) ;
  outline: unset;
  color: #fff;
  border: none;
  font-size: 16px;
  letter-spacing: 2px;
  box-shadow: 0 3px 3px 1px rgba(0,0,0,0.15);

  // background-image: linear-gradient(top, rgba(61,137,210,1) 0%,rgba(61,137,210,0.99) 1%,rgba(255,255,255,0) 100%);
  &:focus{
    box-shadow: none;
  }
}
</style>