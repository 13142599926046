<template>
  <div class="trending ">
    <div class="left-spot"></div>
    <div class="right-spot"></div>

    <div class="brand-select-wrap" :class="[sidebarPostion]">
      <div class="inner">
        <div
          v-for="(item, index) in nodeDisplayList"
          @dblclick="toggleDefaultBrand(index)"
          class="brand scale-hover-effect"
          @click="onToggleBrand(item, index)"
          :key="index"
          :class="{
            active: index === activeIndex,
            defaultActive: index === defaultActiveIndex
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <template v-if="activeNode.data">
      <!-- <transition name="fade"> -->
      <div class="title">
        <a-avatar :src="activeNode.data.iconSrc" size="small" class="margin-right-xs">
        </a-avatar>
        {{ activeNode.name }}
        <span class="margin-left-xs text-sm opacity-4" style="fr">
          {{ activeNode.data.updateTime.toLocaleTimeString() }}
        </span>
      </div>
      <div class="node-wrap" v-forbidden>
        <a
          v-for="(item, index) in activeNode.data.textList"
          class="node-text-item"
          target="_blank"
          :href="item.href"
          :key="item.text"
        >
          <div class="sequence" :class="{hot: index < 3}">{{ index + 1 }}</div>
          {{ item.text }}

          <span class="heat">{{ item.heat }}</span>
        </a>
      </div>
      <!-- </transition> -->
    </template>
    <!-- <a-skeleton v-else :paragraph="{ rows: 7 }" active/> -->
  </div>
</template>

<script>
import { cloudApp } from '@/store/store'
let tophubCollection
const trendingDefaultActiveIndex = +(localStorage.getItem('trendingDefaultActiveIndex') || 0)
import {throttle} from 'lodash-es'
// const contextmenuConfig = [
//   {title: '编辑数据节点', key: 'editNode'},
// ]
export default {
  data() {
    return {
      nodeDisplayList: [
        {
          id: '1',
          name: '微博热搜榜',
          visible: true
        },
        {
          id: '6',
          name: '知乎热榜',
          visible: false
        },
        {
          id: '125',
          name: '知乎日报',
          visible: false
        },
        {
          id: '2476',
          name: '站酷',
          visible: false
        },
        {
          id: '139',
          name: '豆瓣话题广场',
          visible: false
        },
        {
          id: '19',
          name: '哔哩哔哩全站日榜',
          visible: false
        },
        {
          id: '11',
          name: '36氪',
          visible: false
        },
        {
          id: '85',
          name: '豆瓣新片榜',
          visible: false
        },
        {
          id: '138',
          name: '少数派',
          visible: false
        },
        {
          id: '213',
          name: '人人都是产品经理',
          visible: false
        }
      ],
      activeIndex: trendingDefaultActiveIndex,
      defaultActiveIndex: trendingDefaultActiveIndex,
      sidebarPostion: 'left',
      // contextmenuConfig
    }
  },
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  computed: {
    activeNode () {
      return this.nodeDisplayList[this.activeIndex]
    }
  },
  watch: {
    activeIndex () {
      this.load({isFefresh: true})
    },
    selfLayoutInfo:{
      handler: throttle(function (val) {
        // console.log('val', val)
        if (val.x < 5) {
          this.sidebarPostion = 'right'
        } else {
          this.sidebarPostion = 'left'
        }
      }, 1000),
      deep: true,
      immediate: true
    }
  },
  created () {
    tophubCollection = cloudApp.database().collection('tophub-spider-data')
    this.load({isFefresh: true})
    setInterval(() => {
      this.load({isFefresh: true})
    }, 300000)
  },
  methods: {
    load (options = {}) {
      const node = this.activeNode
      if (options.isFefresh)  {
        tophubCollection.doc(node.id).get()
         .then(res => {
           node.data = res.data[0]
          //  console.log('res', res)
         })
      }
      // this.nodeDisplayList.forEach(val => {
      //   tophubCollection.doc(val.id).get()
      //    .then(res => {
      //      val.data = res.data[0]
      //      console.log('res', res)
      //    })
      // })
    },
    toggleDefaultBrand(index){
      this.defaultActiveIndex = index
      localStorage.setItem('trendingDefaultActiveIndex', index)
    },
    onToggleBrand(item, index){
      this.activeIndex = index
    },
    /**
     * 编辑数据节点
     *
     * @param {string} p1 参数1的说明
     * @return {undefined}
    */
    editNode(){

    }
  }
}
</script>


<style scoped lang="scss">
.brand-select-wrap{
  opacity: 0;
  .inner{
    display: flex;
    flex-flow: column wrap;
    height: 400px;
  }
// transform: translate3d(0,0,0);
  width: 100px;
  height: 100%;
  position: absolute;
  background-color: var(--theme-bg-color-dark);
  border-radius: 2px;
  // opacity: 0;
  padding: 4px 0 4px 4px;
  z-index: -1;
  top: 0;
  transition: opacity  0.1s, left 0.2s;
  // transition-delay: 0.4s;
  overflow-x: hidden;
  overflow-y: scroll;
  &.left{
    left: 0;
    // left: -100px;
  }
  &.right{
    left: calc(100% - 100px);
    // left: 100%;
  }
  &:hover{
    opacity: 1;
    display: block;
  }

 /* 强制使内容块分列的隐藏列 */
  &::before,
  &::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
  }

  /* 重新定义内容块排序优先级，让其横向排序 */
  .brand:nth-child(3n+1) { order: 1; }
  .brand:nth-child(3n+2) { order: 2; }
  .brand:nth-child(3n)   { order: 3; }

  .brand{
    position: relative;
    display: inline-block;
    z-index: 1;
    // width: 15px;
    width: 24px;
    line-height: 17px;
    background-color: var(--theme-bg-color-dark);
    // transform: translateX(5px);
    margin-right: 4px;
    margin-top: 4px;
    padding: 4px;
    color: #fff;
    vertical-align:text-top;
    cursor: pointer;
    transition: all 0.15s;
    border-radius: 2px;

    &.active{
      background-color: var(--primary-bgColor);
    }
    &.defaultActive:before {
      position: absolute;
      height: 3px;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--decorative-color);
      content: ' ';
      border-radius: 2px;
      // border-top: 5px solid #3F70F1;
    }
    &:hover{
      animation: breathe ease-in-out 3.75s infinite;
    }
  }
}
.trending{
  height: 100%;
  background-color: var(--theme-bg-color);
  // background-image: url("/dot.png");

  // background-color: rgba(234,239,242,0.95);
  overflow: hidden;
  // padding: 4px;
  z-index: 50000;

  .title{
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 18px;
    color: var( --font-color);
    background: linear-gradient(90deg, rgba(61,84,116,1) 0%,rgba(61,84,116,0.99) 1%,rgba(255,255,255,0) 100%) ;
    padding: 4px 6px;
    box-shadow: 0 0  3px 3px rgba(0,0,0,0.15);
  }

  a{
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    color: var( --font-color);
    // text-shadow: 0px 0px 1px #c1e2f1;
    line-height: 20px;
    // margin-bottom: 6px;
    padding: 2px 6px;

    &:visited{
      // color: #bbb;
      color: var(--font-second-color);
    }
    &:hover{
    //   color: #3D5474;
      background-color: var(--main-color);
      animation: breathe ease-in-out 3.75s infinite;
    }
  }

  &:hover{
    .brand-select-wrap{
      display: block;
      opacity: 1;
    }
    .brand-select-wrap.left{
      left: -100px;
    }
    .brand-select-wrap.right{
      left: 100%;
    }
  }

  .heat{
    text-align: right;
    flex-grow: 2;
    color: var(--font-third-color);
    flex-shrink: 0;
    padding-left: 4px;
  }
}
.node-wrap{
  padding: 8px;
  width: 100%;
  height: calc(100% - 30px);
  overflow-y: auto;

  .sequence{
    height: 100%;
    display: inline-block;
    // color: var( --font-color);
    // color: #999;
    // width: 30px;
    flex-shrink: 0;
    margin-right: 1rem;
  }
  .sequence.hot{
     color: #F1403C;
  }
}
.node-text-item{
  display: block;
}
</style>