import { appState, appEventBus } from '../store/store'
// cloudApp,
let db
let bingWallpaperCollection
export function initwallpaperService (dbIns) {
  db = dbIns
  bingWallpaperCollection = db.collection('bing-wallpaper-spider-data')

  appEventBus.on('downloadWallpaper', () => {
    window.open(appState.curWallpaperSourceUrl)
  })

  updateLocalWallpaper()
}

// 根据用户设置调整当前壁纸
export function updateLocalWallpaper () {
  if (appState.appConfig.wallpaperConfig.updateBehavior === 'dailyUpdate') {
    getLatestWallpaper()
  }
}

const defaultWallpaper = {
  sourceUrl: 'https://grid-new-tab-1256341180.file.myqcloud.com/wallpaper/66285e108231415.5fb91cabb0b49.jpg'
}
export function getLatestWallpaper () {
  // let id = new Date().toLocaleDateString().replaceAll('/', '-')
  return bingWallpaperCollection
    .limit(1)
    .orderBy('time', 'desc')
    .get()
    .then((res) => {
      const wallpaperConfig = appState.appConfig.wallpaperConfig
      if (wallpaperConfig?.curWallpaper?._id !== res.data[0]._id) {
        const cloudWallpaper = res.data[0]
        if (cloudWallpaper.sourceUrl) {
          wallpaperConfig.curWallpaper = cloudWallpaper
        } else {
          wallpaperConfig.curWallpaper = defaultWallpaper
        }
        // console.log('res.data[0]', res.data[0])
      }
    })
}

export function getWallpaperList (limit = 30, skip = 0) {
  console.log('getWallpaperList', limit, skip)
  return bingWallpaperCollection
    ?.skip(skip)
    .limit(limit)
    .orderBy('time', 'desc')
    .get()
}

