<template>
  <a-modal
    centered
    :width="800"
    v-model:visible="visible"
    :footer="null"
    class="wallpaper-modal"
    title="搜索引擎启用及顺序设置"
  >
    <a-alert
      message="拖动引擎图标以调整顺序，点击切换启用/禁用"
      showIcon
      class="margin-bottom-xs "
      type="info" />
    <draggable
      class="list-group"
      tag="transition-group"
      :component-data="{
        tag: 'div',
        type: 'transition-group',
      }"
      v-model="engineList"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      @change="onDraggableChange"
      :item-key="keyFun"
    >
      <template #item="{ element }">
        <div
          class="hover-effect modal-engine-item"
          :class="{inactive: !engineListActiveMap[element]}"
          @click="toggleEngineStatus(element)"
          :key="element">
          <img
            width="18"
            height="18"
            style="display: block; margin: 0 auto"

            :src="engineInfo[element].iconUrl"
            :alt="engineInfo[element].name">
          <div class="text-lg margin-top-xs text-center">
            {{ engineInfo[element].name }}
          </div>
        </div>
      </template>
    </draggable>

  </a-modal>
</template>

<script>
import draggable from 'vuedraggable'
import {engineInfo, engineList, engineListActiveMap} from './search'
export default {
  inject: ['appState'],
  data() {
    return {
      visible: false,
      engineList: engineList.slice(),
      // 表示引擎的启用状态，false为未启用
      engineListActiveMap,
      engineInfo,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        firstRenderFlag: true
      },
      drag: false
    }
  },
  created () {
  },
  components: {
    draggable
  },
  methods: {
    keyFun(el) {
      return el
    },
    show(){
      this.visible = true
    },
    toggleEngineStatus(engineName){
      const engineListActiveMap= this.engineListActiveMap
      let i = 0
      Object.keys(engineListActiveMap).forEach(engineName => {
        engineListActiveMap[engineName]&&i++
      })
      if (i<=1 && engineListActiveMap[engineName])  return
      engineListActiveMap[engineName] = !engineListActiveMap[engineName]
      this.save()
    },
    onDraggableChange(e){
      console.log('e', e)
      this.save()
    },
    save(){
      localStorage.setItem('engineList', JSON.stringify(this.engineList))
      localStorage.setItem('engineListActiveMap', JSON.stringify(this.engineListActiveMap))

      const result = []
      this.engineList.forEach(engineName => {
        if(this.engineListActiveMap[engineName]){
          result.push(engineName)
        }
      })
      this.appState.module.activeEngineList = result
    }
  }
}
</script>

<style lang="scss">
.modal-engine-item{
  display: inline-block;
  position: relative;
  margin-right: 10px;
  padding: 6px;
  border-radius: 4px;
  min-width: 110px;

  img{
    width: 40px;
    height: 40px;
  }
  // background-color: #ebeef1 !important;
  // background: rgba(255,255,255,0.5) !important;
  &.inactive{
    filter: grayscale(1);
  }
  &.active::before {
    position: absolute;
    top: 40%;
    left: 2px;
    content: " ";
    height: 10px;
    width: 1.5px;
    background-color: var(--active-color);
    z-index: 10;
    animation: breathe-opacity-ltx ease 1.5s infinite;
    border-radius: 50%;
  }
  &.active::after {
    position: absolute;
    top: 40%;
    right: 2px;
    content: " ";
    height: 10px;
    width: 1.5px;
    background-color: var(--active-color);
    z-index: 10;
    animation: breathe-opacity-rtx ease 1.5s infinite;
    border-radius: 50%;
  }
}
</style>