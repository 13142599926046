<template>
  <transition name="fade">
    <div class="tile-control-wrap" v-if="appState.curSelectedTilesCount">

      <div class="left">
        <div class="bar" @click="showCollectionModal">
          移动至其他集合<SelectOutlined class="margin-left-xs" />
        </div>
        <div class="bar" @click="() => combine()">
          组合成新集合<MergeCellsOutlined class="margin-left-xs" />
        </div>
      </div>
      <div class="count">{{ appState.curSelectedTilesCount }}
        <div class="count-circle"></div>
        <div class="count-circle-2"></div>
        <div class="clear-btn" @click="clear"> <CloseOutlined />取消</div>
      </div>
      <div class="right">
        <div class="bar" @click="teleportToMainscreen">
          <SwapOutlined class="margin-right-xs"/>从集合移动至主屏
        </div>
        <div class="bar" @click="()=>removeTiles()">
          <DeleteOutlined class="margin-right-xs" />删除
        </div>
      </div>
      <!-- <div class="bg"></div> -->

      <a-modal
        v-model:visible="collectionModalVisible"
        okText="移动"
        :footer="collectionValue?undefined:null"
        cancelText="取消"
        title="选择一个集合进行移动"
        @ok="() => moveElements()">
        <!-- <a-input-search class="margin-bottom-sm" :maxlength="25" v-model:value="searchText"></a-input-search> -->
        <a-radio-group v-model:value="collectionValue">
          <a-radio-button
            class="colletion-item"
            :value="item.i"
            v-for="item in collectionsArr"
            :key="item.i">{{ item.data.name }}</a-radio-button>
        </a-radio-group>
      </a-modal>
    </div>
  </transition>
</template>

<script>
import {
  SelectOutlined, MergeCellsOutlined, DeleteOutlined, ClearOutlined, CloseOutlined, SwapOutlined,
} from '@ant-design/icons-vue'
import { collectionsComStore } from '@/service/Collection'
export default {
  inject: ['appEventBus', 'appState'],
  components: {
    SelectOutlined, MergeCellsOutlined, DeleteOutlined, ClearOutlined, CloseOutlined, SwapOutlined,
  },
  data() {
    return {
      collectionModalVisible: false,

      // 当前所有集合的数组
      collectionsArr: [],
      collectionValue: null,

      searchText: ''
    }
  },
  mounted () {
    this.appEventBus.on('moveCollectionElements', this.moveElements)
    this.appState.$tileControlBar = this
  },
  methods: {
    clear(){
      this.appState.curSelectedTiles = {}
      this.appState.curSelectedTilesCount = 0
    },
    // ----start 处理移动至其他集合 ----
    showCollectionModal(){
      this.collectionModalVisible = true
      this.collectionsArr = Object.keys(collectionsComStore).map(e => {
        return {
          ...collectionsComStore[e].selfLayoutInfo,
          context: collectionsComStore[e]
        }
      })
      console.log('collectionsArr', this.collectionsArr)
    },
    /**
     * 移动所有当前选中的网站磁贴至当前集合
     *
     * @param {object} targetParam 目标集合，vue组件
     * @return {undefined}
    */
    moveElements(targetParam){
      let target
      if (!targetParam) {
        target = collectionsComStore[this.collectionValue]
      } else {
        target = targetParam
      }
      console.log('移动至目标集合')
      // eslint-disable-next-line no-unused-vars
      // const target = collectionsComStore[this.collectionValue]
      // 遍历
      // this.clearCurSelectedTiles()
      // eslint-disable-next-line no-unused-vars
      const curSelectedTilesCache = this.appState.curSelectedTiles
      // 1. 从原有位置删除
      this.removeTiles()

      // console.log('JSON.strin curSelectedTilesCache', JSON.stringify(curSelectedTilesCache))
      // 2. 添加到目标位置
      Object.keys(curSelectedTilesCache).forEach(key => {
        const el = curSelectedTilesCache[key]
        console.log('target', target, key)
        // if (el.type === 'websiteBox') {
          // this.appEventBus.emit('addWebsiteBox', el.boxData)
        // }else if (el.type === 'collection-item') {
          target.addTile({boxData: el.boxData})
        // }
      })
      this.collectionModalVisible = false
    },
    /**
     * 删除所有当前选中的网站磁贴或指定的磁贴
     *
     * @param {object} tile? 指定的磁贴，不传时默认删除所有当前选中的网站磁贴
     * @return {undefined}
    */
    removeTiles(tile){
      console.log('removeTiles', tile)
      let target
      if (tile) {
        target = {
          [tile.i]: tile
        }
      } else {
        target = this.appState.curSelectedTiles
      }
      console.log('collectionsComStore', collectionsComStore)
      // const curSelectedTiles = this.appState.curSelectedTiles
      // console.log('删除所有当前选中的网站磁贴', curSelectedTiles)
      Object.keys(target).forEach(key => {
        const el = target[key]
        console.log('el', el)
        if (el.type === 'websiteBox') {
          this.appEventBus.emit('removeTile', el.i)
        }else if (el.type === 'collection-item') {
          collectionsComStore[el.parentCollectionIndex].onRemoveWebsite(el.i)
        }
        // else if (el.type === 'tabs-item') {
          // tabs-item类型的磁贴不做删除动作
        // }
        if (tile) {
          delete this.appState.curSelectedTiles[tile.i]
          this.appState.curSelectedTilesCount--
        }
      })
      !tile && this.clearCurSelectedTiles()
    },
    clearCurSelectedTiles(){
      console.log('clearCurSelectedTiles')
      this.appState.curSelectedTiles = {}
      this.appState.curSelectedTilesCount = 0
    },
    /**
     * 接收处理函数，对当前选中的每一项磁贴数据进行调用
     *
     * @param {function} fun 处理函数
     * @param {object} tiles？ 待处理的磁贴数据
     * @return {undefined}
    */
    curSelectedTilesIterator(fun, tiles){
      !tiles && (tiles = this.appState.curSelectedTiles)
      console.log('tiles', tiles)
      // console.log('curSelectedTilesIterator')
      // const curSelectedTiles = this.appState.curSelectedTiles
      Object.keys(tiles).forEach(key => {
        fun.call(this, tiles[key])
      })
    },
    //
    /**
     * 将已选中磁贴或传入的磁贴组合成新集合
     *
     * @param {arrary|undefined} targetsParams? 传入的磁贴数据，用于组合成新集合，不传入代表使用已选中的集合
     * @param {object|undefined} options 可选参数
     * @param {string} options.colletionName 集合名称
     * @return {undefined}
    */
    combine(targetsParams, options={}){
      console.log(' combine targetsParams', targetsParams ,options)
      const targets = targetsParams || Object.values(this.appState.curSelectedTiles)
      // 1. 新增集合
      const newElI = this.appState.$grid.addCollectionTile({colletionName: options.colletionName})
      this.$nextTick(() => {
        const targetCollection = collectionsComStore[newElI]
        console.log('targetCollection')
        // 2. 删除其他位置的选中磁贴
        // const curSelectedTilesCache = this.appState.curSelectedTiles
        // this.removeTiles()
        // // 3. 将选中集合移动到新增的集合
        // const targetCollection = collectionsComStore[newElI]
        // this.curSelectedTilesIterator((el) => {
        //   targetCollection.addTile({...el.boxData})
        // }, curSelectedTilesCache)

        const boxDatas = targets
        this.removeTiles()
        setTimeout(() => {
          targetCollection.addTile(boxDatas)
        }, 200)

        // this.curSelectedTilesIterator((el) => {
        //   console.log('el addWebsiteBox', el)
        //   boxDatas.push(el)
        // })
      })
    },
    // 将已选中磁贴中属于集合内的磁贴传送至主屏
    teleportToMainscreen(){
      console.log('teleportToMainscreen')
      this.curSelectedTilesIterator((el) => {
        if (el.type === 'collection-item'){
          console.log('el addWebsiteBox', el)
          collectionsComStore[el.parentCollectionIndex].onRemoveWebsite(el.i)
          delete this.appState.curSelectedTiles[el.i]
          this.appState.curSelectedTilesCount--
          this.appEventBus.emit('addWebsiteBox', el.boxData)
        }
      })
      // 1. 遍历选中集合内磁贴
      // 2. 从集合中删除
      // 3. 调用主屏grid的新增接口
    }
    // ----end 处理移动至其他集合 ----
  }
}
</script>

<style scoped lang="scss">
.colletion-item{
  margin-bottom: 4px;
  margin-right: 4px;
}
.tile-control-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;

  filter: drop-shadow(1px 4px 2px rgba(0,0,0,0.4));
  position: fixed;
  bottom: 20px;
  right: calc(50vw - 200px);
  width: 440px;
  box-sizing: border-box;
  border-radius: 2%;
  padding: 10px;

  z-index: 2;
  user-select: none;
  .count{
    position: relative;
    font-size: 1.5rem;
    // height: 1rem;
    width: 10rem;
    text-align: center;
    z-index: -1;

    .clear-btn{
      position: absolute;
      bottom: -40px;
      left: calc(50% - 40px);
      width: 80px;
      height: 1.5rem;
      line-height: 1.5rem;
      background-color: var(--main-color);
      z-index: 1;
      font-size: 1rem;
      background-color: rgba(91, 110, 126, 0.3);
      // box-shadow: 0px 0px 2px 2px rgba(61,137,210,0.9);
      transition: all cubic-bezier(0.68, -0.55, 0.265, 1) 0.3s;
      cursor: pointer;
      clip-path: polygon(0 0%, 100% 0%,  80% 100%, 20% 100%);
      border-radius: 20px;

      &:hover{
        transform: scale(1.1);
      }
    }
  }
  .count-circle{
    position: absolute;
    top: 0px;
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    // border-radius: 100%;
    background-color: var(--theme-bg-color-active2);
    transform: rotate(45deg);
    // border: 2px solid var(--font-fourth-color);
    // animation: breathe-scale-opacity ease-in-out 1s infinite;
    box-shadow: 0px 0px 2px 2px var(--theme-bg-color-active);
    z-index: -1;
  }
  .count-circle-2{
    position: absolute;
    top: 0px;
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    // background-color: rgba(61,137,210,0.8);
    transform: rotate(45deg);
    border: 0.5px solid var(--font-color);
    animation: breathe-scale-opacity ease-in-out 1.3s infinite;
    box-shadow: 0px 0px 5px 1px rgba(61,137,210,0.4), 0px 0px 5px 2px rgba(61,137,210,0.4) inset;
    z-index: 1;
  }
  .bg{
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    // background: radial-gradient(rgba(75, 125, 163, 1), rgba(75, 125, 163, 0.5));
    // background: radial-gradient(#333, rgba(75, 125, 163, 0.5));
  }
}

@keyframes breathe-scale-opacity {
  0% {
    opacity: 0;
    transform: scale(0.7) rotate(45deg);
  }

  // 40% {
  //   transform: scale(0.9) rotate(45deg);
  // }

  40% {
    transform: scale(3.5) rotate(45deg);
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    transform: scale(3.7) rotate(45deg);
  }
}
.bar{
  position: relative;
  margin-top: 4px;
  width: 160px;
  height: 24px;
  line-height: 24px;
  vertical-align: center;
  padding-left: 1rem;
  cursor: pointer;
  transition: all cubic-bezier(0.68, -0.55, 0.265, 1) 0.3s;
  &:hover{
    transform: scale(1.1);
    filter: brightness(1.4);
  }
}
.right{
  .bar{
    background: linear-gradient(135deg, var(--theme-bg-color-active2) 0%,rgba(255,255,255,0) 100%) ;
    &::before{
      position: absolute;
      top: 3px;
      left: -9px;
      content: " ";
      width: 17px;
      height: 17px;
      background-color: var(--theme-bg-color-active);
      z-index: 10;
      transform: rotate(45deg);
    }
  }
}
.left{
  text-align: right;
  .bar{
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%,  var(--theme-bg-color-active2) 100%,) ;
    padding-right: 1rem;
    &::before{
      position: absolute;
      top: 3px;
      right: -9px;
      content: " ";
      width: 17px;
      height: 17px;
      background-color: var(--theme-bg-color-active);
      z-index: 10;
      transform: rotate(45deg);
    }
  }
}
</style>