<template>
  <a-calendar class="g-calendar" v-model:value="value" :locale="locale">
    <template v-if="festivalData[fullYear]" #dateCellRender="{ current: current }">
      <DateCell :info="getDateInfo(current)"></DateCell>
    </template>
    <!-- <template #monthCellRender="{ current: value }">
      <div v-if="getMonthData(value)" class="notes-month">
        <section>{{ getMonthData(value) }}</section>
        <span>Backlog number</span>
      </div>
    </template> -->
  </a-calendar>
</template>
<script>
import request from '@/api/request'
import DateCell from './DateCell'

export default {
  components: {
    DateCell
  },
  data() {
    return {
      value: '',
      locale: {
        lang: {
          'month': '月',
          'year': '年',
        }
      },
      festivalData: {},

      fullYear: 2021
    }
  },
  created(){
    this.fullYear = new Date().getFullYear()
    if (this.festivalData[this.fullYear]) {
      return
    }
    request({
      method: 'get',
      url: `assets/${this.fullYear}.json`
    })
      .then(res => {
        this.festivalData[this.fullYear] = res
      })
  },
  methods: {
    getDateInfo(value) {
      const dateStr = value.format('YYYY-MM-DD')
      const year = value.format('YYYY')
      const info = this.festivalData[year]?.[dateStr]

      return info
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394
      }
    },
    getFestivalData(){
    }
  },
}
</script>
<style>
.ant-fullcalendar-date{
  height: 60px !important;
}
</style>
<style scoped>
.g-calendar{
  background-color: #fff;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>