import { appState } from '@/store/store'

// element示例
// {
//   boxData:
//   {
//     address: 'https://www.zaozao.run/video/c34',
//     iconUrl: 'https://static.zaozao.run/website/1.0.12/favicon.ico',
//     name: '前端早早聊',
//     type: 'icon' // 用于控制图标类型
//   },
//   i: "46cbe9e9-8442-4197-95e4-0768b645fee4"
//   parentCollectionIndex: "b254c549-6d30-4b56-8965-47c3e5147305"
//   type: "collection-item" // 用于表示源数据类型
// }
/**
 * 用于切换磁贴的选中与取消选择效果，更新相关数据
 *
 * @param {Object} element 磁贴数据结构
 * @return {undefined}
*/
export function toggleSeletedTileStatus(element){
  // console.log('element', element)
  const i = element.i
  const curSelectedTiles = appState.curSelectedTiles
  if (curSelectedTiles[i]) {
    delete curSelectedTiles[i]
    appState.curSelectedTilesCount--
  } else {
   curSelectedTiles[i] = element
    appState.curSelectedTilesCount++
  }
  console.log('curSelectedTiles',curSelectedTiles )
  // e.stopPropagation()
}