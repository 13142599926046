<template>
  <div class="widget-manager">
    <!-- <a-checkbox
      v-for="item in appState.widgetDisplayList"
      :key="item.key"
      @change="checkedValue => {onWidgetVisibleChange(checkedValue, item.key)}"
      v-model:checked="appState.appConfig.widgetStatus[item.key].visible">
      {{ item.value }}
    </a-checkbox> -->
    <!-- <ChildModal style="margin-top: 15px" title="添加自定义组件" v-model:visible="modalVisible">
      开发中
    </ChildModal> -->
    <div class="widgets-wrap">
      <WidgetCard v-for="item in appState.widgetDisplayList" :key="item.key" :detail="item"></WidgetCard>
    </div>

    <!-- <a-collapse v-model:activeKey="activeKey" class="margin-top" :bordered="false">
      <a-collapse-panel key="1" header="已安装组件" :style="customStyle">
        <div class="widgets-wrap">
          <WidgetCard v-for="item in appState.widgetDisplayList" :key="item.key" :detail="item"></WidgetCard>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="小组件商店" :style="customStyle">
        开发中
        <template #extra>
          <a-button type="primary" v-if="appState.appConfig.isDeveloperMode" size="small" @click="onWidgetAddBtnClick">
            + 添加自定义组件
          </a-button>
        </template>
      </a-collapse-panel>
    </a-collapse> -->
  </div>
</template>

<script>
import WidgetCard from './WidgetCard'
// import ChildModal from '@/components/ChildModal/ChildModal'
export default {
  inject: ['appState', 'appEventBus'],
  components: {
    WidgetCard,
    // ChildModal
  },
  data() {
    return {
      activeKey: ['1'],
      customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
      modalVisible: false
    }
  },
  methods: {
    onWidgetAddBtnClick(e){
      e.stopPropagation()
      this.modalVisible = true
    }
  }
}
</script>

<style lang="scss">
.widget-manager{
  // min-width: 974px;
  // min-height: 300px;

  .widgets-wrap{
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(4, 220px);
  }
  .ant-card{
    padding-bottom: 30px;
  }
  .ant-card-actions{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .ant-card-actions > li {
    margin: 2px 0;
  }
}
</style>