<template>
  <transition name="fade">
    <div class="guide" v-if="visible">
      <CloseCircleOutlined class="close-btn" @click="onClose"/>
      <a-carousel arrows>
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px;zIndex: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined />
          </div>
        </template>
        <div>
          <div>
            <img src="https://grid-new-tab-1256341180.file.myqcloud.com/help/%E7%A3%81%E8%B4%B4%E6%93%8D%E4%BD%9C.gif" width="600" alt="">
          </div>
          <div class="guide-des">
            <h2>基本操作</h2>
            <h3>
              在起始页中，网站、小组件的基本承载元素为磁贴，它们可以被拖动、更改位置及大小。右键点击可触达更多操作入口。
            </h3>
            <!-- <a-button type="primary" @click="visible = false">我知道了</a-button> -->
          </div>
        </div>
        <div>
          <div>
            <img src="https://grid-new-tab-1256341180.file.myqcloud.com/help/%E8%87%AA%E5%8A%A8%E5%90%8C%E6%AD%A5.gif" width="600" alt="">
          </div>
          <div class="guide-des">
            <h2>实时同步</h2>
            <h3>
              登录后，它能实时同步你的数据到云，你的其他同账号设备会自动执行同步。
            </h3>
            <!-- <a-button type="primary" @click="visible = false">我知道了</a-button> -->
          </div>
        </div>
      <!-- <div><h3>3</h3></div> -->
      <!-- <div><h3>4</h3></div> -->
      </a-carousel>
    </div>
  </transition>
</template>

<script>
import { CloseCircleOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    CloseCircleOutlined, RightCircleOutlined, LeftCircleOutlined
  },
  inject: [ 'appEventBus'],
  data() {
    return {
      visible: false
    }
  },
  created () {
    const guideFlag = localStorage.getItem('guideFlag')
    if (!guideFlag) {
      this.visible = true
      localStorage.setItem('guideFlag', '1')
    }
    this.appEventBus.on("showGuidePanel", this.onShow, this)
  },
  methods: {
    onClose(){
      this.visible = false
    },
    onShow(){
      this.visible = true
    }
  }
}
</script>
<style lang="scss">
.guide{
  .slick-dots{
    li {
      background-color: #ccc;
    }
  }
  .ant-carousel .slick-dots li button{
    height: 12px;
    width: 34px;
  }
  .ant-carousel .slick-dots li.slick-active button{
    background-color: #3D5474;
  }
  .ant-carousel .slick-dots-bottom{
    bottom: 20px;
  }
}
</style>
<style scoped lang="scss">
.guide-des{
  padding: 10px;
  text-align: left;
  margin-top: 10px;
  line-height: 22px;
  color: #333;

  h3{
    color: grey;
  }
}
.guide{
  position: fixed;
  width: 600px;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 2px;
  overflow: hidden;
  background-color: #F2EEED;

  .close-btn{
    font-size: 26px;
    position: absolute;
    right: 6px;
    color: #3D5474;
    top: 6px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.25s;
    box-shadow: 0 0  3px 3px rgba(56,100,173,0.45);
    border-radius: 100%;

    &:hover{
      // color: #888;
      filter: brightness(1.3);
    }
  }
}
/* For demo */
.ant-carousel ::v-deep(.slick-slide) {
  text-align: center;
  /* height: 160px; */
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel ::v-deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  /* background-color: rgba(235, 240, 245, 0.11); */
  opacity: 0.3;
}
.ant-carousel ::v-deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel ::v-deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

</style>