<template>
  <div
    class="website-box draggable-handle"
    :class="mode"
    @mousedown="onMousedown"
    @mouseup="onMouseup"
    @click.right="onRightClick"
    :title="`${boxData.name} ${boxData.remark||''}`"
    @dragenter="onDragenter"
    @dragover="onDragover"
    @drag="onDrag"
    :id="'w'+selfLayoutInfo.i"
  >
    <!-- <template >
      {{ boxData.displayName }}
    </template> -->
    <div class="color-viewer" v-if="boxData.type === 'color'" :style="colorStyle">
      {{ boxData.name.substr(0, 1) }}
    </div>
    <img
      v-else
      width="32"
      height="32"
      :src="boxData.iconUrl"
      alt="">
    <div class="text-sm title name">
      {{ boxData.name }}
    </div>
    <div class="custom-select-wrap" @click.prevent="onTipClick">
      <div class="custom-select " :class="{active: appState.curSelectedTiles[selfLayoutInfo.i]}" >
      </div>
    </div>
  </div>
</template>

<script>
import {toggleSeletedTileStatus} from '@/service/tileControl'
// import { CheckOutlined } from '@ant-design/icons-vue'
export default {
  inject: ['appEventBus', 'appState'],
  // components: {
  //   CheckOutlined
  // },
  data() {
    return {
      contextmenuConfig: [
        {title: '编辑站点', key: 'Edit'},
      ],
      beginTimestamp: 0,
      // 布局方式：normal,horizontal
      // mode: 'normal'
    }
  },
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  computed: {
    // boxData.type: 组件类型，color
    boxData () {
      // return this.selfLayoutInfo.boxData
      return this.selfLayoutInfo.boxData
    },
    mode(){
      if (this.selfLayoutInfo.w > 2) {
        return 'horizontal'
      } else {
        return ''
      }
    },
    colorStyle () {
      return `
        width: 42px;
        height: 32px;
        background-color: ${this.boxData.color}
      `
    }
  },
  methods: {
    onMousedown(){
      this.beginTimestamp = new Date().getTime()
    },
    onMouseup(e){
      console.log('onMouseup', e)
      if (e.srcElement.classList[0] === 'custom-select') {
        return e
      }
      if (e.button !== 0) {return e}
      const endTimestamp = new Date().getTime()
      if ((endTimestamp - this.beginTimestamp) < 200) {
        this.navigate()
      }
    },
    navigate(){
      const openHabit = this.appState.appConfig.openHabit
      if (openHabit === 'newPage') {
        window.open(this.boxData.address)
      } else if (openHabit === 'inPlace') {
        window.open(this.boxData.address,'_self')
      }
    },
    onRightClick(){
      console.log('this', this)
      this.appState.activeCard = this
    },
    onEdit(){
      this.appEventBus.emit('showEditWebsiteModal', this)
      console.log('onEdit')
    },
    onTipClick(e){
      toggleSeletedTileStatus(this.selfLayoutInfo)
      e.stopPropagation()
    },
    // ----start 磁贴拖放事件 ----
    onDrag(e){
      console.log('onDrag', e)
    },
    onDragenter(e){
      console.log('onDragenter', e)
    },
    onDragover(e){
      console.log('onDragover', e)
    }
    // ----end 磁贴拖放事件 ----
  }
}
</script>

<style lang="less">
.website-box{
  user-select:none;
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-top: 8px;
  overflow: hidden;
  color: #fff;
  transition: filter 0.3s,  background 0.15s, box-shadow  0.3s;
  border-radius: 8px;

  &:hover .custom-select{
    border-radius: 10%;
    opacity: 0.9;
    border: 2px solid  #CBCBCB;
    width: 0.8rem;
    height: 0.8rem;
  }
  .custom-select.active{
    opacity: 1;
    animation: breathe-opacity ease-in-out 1.5s infinite;
    transform: rotate(45deg);
    background-color: #e6f7f8;
    box-shadow: 1px 0px 6px 2px rgba(206, 219, 230, 0.9);
  }
  &:active{
    cursor: grabbing;
    // animation: delay ease 20s infinite;
  }

  &.horizontal{
    padding: 8px 6px 0 15px;
    flex-direction: row;
    align-items: start;
    padding-bottom: 4px;

    .title{
      position: relative;
      flex: 1;
      margin: 0 10px;
      max-height: 100%;
      text-align: left;
      white-space:normal;
      word-break:break-all;
    }
  }
  .title{
    margin-top: 2px;
    text-align: center;
    text-shadow: 1px 1px 3px #3D5474;
    max-width: 100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }

  img{
    flex-shrink: 0;
  }

  .color-viewer{
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    padding: 0 4px;
    font-size: 24px;
    overflow: hidden;
    border-radius: 4px;
  }
}


// .delay-cursor-resize {
//   animation: delay ease 60s;
// }

// @keyframes delay {

//   /*设置内容由显示变为隐藏*/
//   0% {
//     cursor: pointer;
//     background-color: #f00;
//   }

//   10% {
//     cursor: move !important;
//     background-color: #0f0;
//   }

//   100% {
//     cursor: move !important;
//     background-color: #f00;
//   }
// }
</style>