import { appState } from '@/store/store'
import { initwallpaperService } from './wallpaper'
import { loadQweather } from './weather'
import '@/service/sound'

let launchFlag = false
// 启动流程
export function launch (db) {
  if (launchFlag) { return }
  console.log('------启动流程------')
  // 初始化壁纸数据
  initwallpaperService(db)
  // getLatestWallpaper()
  launchFlag = true
  loadQweather()

  if (appState.isLogin) {
    window.__bl && window.__bl.setConfig({
      uid: appState.userInfo.email,
      setUsername: function () {
        return appState.userInfo.email
      }
    })
  }
}