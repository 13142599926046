import request from './request'

export const getBaiduSuggestion = (key) => {
  return request({
    url: `http://suggestion.baidu.com/su?wd=${key}&cb=window.baidu.sug`,
    method: 'get'
  })
}

export const getZhihuSuggestion = (key) => {
  return request({
    url: `https://www.zhihu.com/api/v4/search/suggest?q=${key}`,
    method: 'get'
  })
}

export const getIcon = (domain) => {
  return request({
    url: `http://${domain}/favicon.ico`,
    method: 'get'
  })
}

export const getBingImage = () => {
  return request({
    url: 'https://bing.biturl.top/?resolution=1920&format=json&index=0&mkt=zh-CN',
    method: 'get'
  })
}

export const getChinaColors = () => {
  return request({
    url: 'https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/colors.json',
    method: 'get'
  })
}

export const getChangelog = () => {
  return request({
    url: '/assets/CHANGELOG.md',
    method: 'get'
  })
}