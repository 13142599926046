<template>
  <a-modal
    centered
    :width="800"
    v-model:visible="appState.wallpaperSelectModalVisible"
    :footer="null"
    class="wallpaper-modal"
    title="壁纸"
    @ok="handleOk">
    <template v-if="appState.curWallpaper">
      <div class="preview-wrap">
        <div :style="App.appStyle" class="preview-image" :src="appState.curWallpaperSourceUrl" alt="">
        </div>
        <a style="display: block;" class="margin-top-xs title" @click="onTitleCick(appState.curWallpaper)">
          {{ appState.curWallpaper.title }}
        </a>
      </div>
    </template>
    <a-tabs class="tab" @change="onTabsChange">
      <a-tab-pane key="image" tab="图片">
        <!-- help="仅供学习交流，严禁用于商业用途" -->
        <a-form-item labelAlign="left" :labelCol="labelCol" label="图源" >
          <a-radio-group
            v-model:value="wallpaperConfig.repositoryType"
            @change="onRepositoryTypeChange"
          >
            <a-radio-button value="bing">
              必应中国
            </a-radio-button>
            <a-radio-button value="grid">
              Cyan精选（beta）
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- '图片来源：必应中国。' +  -->
        <!-- <a-form-item labelAlign="left" :labelCol="labelCol" label="图片质量" :help="(wallpaperConfig.quality==='4k'?'4k壁纸加载时间稍长，请耐心等待。':'')">
          <a-radio-group
            :disabled="wallpaperConfig.repositoryType!=='bing'"
            v-model:value="wallpaperConfig.quality">
            <a-radio-button value="1080p">
              1080p
            </a-radio-button>
            <a-radio-button value="2k">
              2k
            </a-radio-button>
            <a-radio-button value="4k">
              4k
            </a-radio-button>
          </a-radio-group>
        </a-form-item> -->

        <a-form-item labelAlign="left" :labelCol="labelCol" label="更新行为">
          <a-radio-group v-model:value="wallpaperConfig.updateBehavior">
            <a-radio-button value="dailyUpdate">
              每日更新
            </a-radio-button>
            <!-- <a-radio-button value="random">
              每日随机选取壁纸
            </a-radio-button> -->
            <a-radio-button value="keep">
              保持已选中的
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item labelAlign="left" :labelCol="labelCol" label="模糊效果">
          <a-switch v-model:checked="wallpaperConfig.isBlur"/>
        </a-form-item>

        <template v-if="wallpaperConfig">
          <div class="thumbnail-wrap">
            <div v-show="wallpaperConfig.repositoryType === 'bing'">
              <img
                v-for="item in bingWallpaperList"
                @click="toggleDefaultWallpaper(item)"
                class="margin-right-xs margin-bottom-xs"
                :class="{active: item.title === appState.curWallpaper.title}"
                :key="item.title"
                :src="item.sourceUrl"
                alt="">
              <div class="list-completed" v-if="bingWallpapersLoadComplete">
                —— 没有更多了 ——
              </div>
            </div>
            <div v-show="wallpaperConfig.repositoryType === 'grid'">
              <img
                v-for="item in gridWallpaperList"
                @click="toggleDefaultWallpaper(item)"
                class="margin-right-xs margin-bottom-xs"
                :class="{active: item.title === appState.curWallpaper.title}"
                :key="item.title"
                :src="item.sourceUrl"
                alt="">
              <div class="list-completed" v-if="gridWallpapersLoadComplete">
                —— 没有更多了 ——
              </div>
            </div>
            <div class="wallpaper-model-reference"></div>
          </div>
        </template>

      </a-tab-pane>
      <a-tab-pane key="pureColor" tab="纯色">
        <div class="pure-wrap">
          <div
            class="pure-color-card"
            v-for="item in chinaColorList"
            @click="onPureColorClick(item)"
            :key="item.title"
            :style="'background-color:' + item.hex">
            <span>{{ item.title }}</span>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>
<script>
// import { getBingImage } from '@/api/base'
import { getWallpaperList } from '@/service/wallpaper'
import { cloudApp } from '@/store/store'
const db = cloudApp.database()
const gridWallpaperCollection = db.collection('grid-wallpaper')
import { getChinaColors } from '@/api/base'
import {engineInfo} from '@/components/search/search'

export default {
  inject: ['appState', 'App'],
  data() {
    return {
      visible: false,
      bingWallpaperList: [],
      bingWallpapersLoadComplete: false,
      wallpaperLoading: false,
      gridWallpaperList: [],
      gridWallpapersLoadComplete: false,
      gridWallpaperLoading: false,
      chinaColorList: [],
      labelCol: { span: 3 },
      // 是否打开过
      opened: false,
      $observer: null
    }
  },
  watch: {
    'appState.wallpaperSelectModalVisible' (val) {
      if(val){
        this.onRepositoryTypeChange()
        if (!this.opened) {
          console.log('this.opened',this.opened )
          setTimeout(this.initObservation, 1000)
          // this.$nextTick(this.initObservation)
          this.opened = true
        }
      }
    }
  },
  // created(){
  //   if (!wallpaperConfig) {
  //     this.appState.appConfig.wallpaperConfig = {
  //       behavior: 'image'
  //     }
  //   }
  // },
  computed: {
  wallpaperConfig(){
      return this.appState.appConfig.wallpaperConfig
    }
  },
  mounted () {
  },
  methods: {
    showModal() {
      console.log('showModal')
      this.visible = true
    },
    handleOk() {
      this.visible = false
    },
    toggleDefaultWallpaper(item){
      console.log('toggleDefaultWallpaper', item)
      this.appState.appConfig.wallpaperConfig.curWallpaper = item
      this.wallpaperConfig.updateBehavior = 'keep'
    },
    onTitleCick(wallpaper){
      if (wallpaper.titleHref) {
        window.open(wallpaper.titleHref)
      } else {
        engineInfo[this.appState.searchConfig.curEngineName].onSearch(wallpaper.title)
      }
    },
    getZhongguoselist(){
      getChinaColors()
        .then(res => {
          this.chinaColorList = res
        })
      // zhongguosev2Collection
      //   .skip(this.chinaColorList.length)
      //   .get()
      //   .then(res=>{
      //     console.log('res.data', res.data)
      //     this.chinaColorList = this.chinaColorList.concat(res.data)
      //   })

    },
    onTabsChange(key){
      console.log('key', key)
      if (key === 'pureColor') {
        !this.chinaColorList.length && this.getZhongguoselist()
      }
    },
    onPureColorClick(item){
      this.appState.appConfig.wallpaperConfig.curWallpaper = item
      this.wallpaperConfig.updateBehavior = 'keep'
    },
    onRepositoryTypeChange(){
      const type = this.wallpaperConfig.repositoryType
      if (type === 'grid') {
        if (this.gridWallpaperList.length) {return}
        this.getGridWallpaperList()
      } else if (type === 'bing') {
        if (this.bingWallpaperList.length) {return}
        this.loadWallpaperThumbnailList()
      }
    },
    // ------START 加载壁纸列表 ------
    loadWallpaper(){
      // console.log('loadWallpaper')
      if (this.wallpaperConfig.repositoryType === 'grid') {
        this.getGridWallpaperList()
      } else if (this.wallpaperConfig.repositoryType === 'bing') {
        this.loadWallpaperThumbnailList()
      }
    },
    loadWallpaperThumbnailList(){
      if (this.wallpaperLoading) {return}
      console.log('loadWallpaperThumbnailList', this.bingWallpaperList)
      // getBingImage()
      // if (this.bingWallpaperList.length) {return}
      if (this.bingWallpapersLoadComplete) return
      getWallpaperList(30, this.bingWallpaperList.length)
        ?.then(res => {
          if (res.data.length === 0){
            this.bingWallpapersLoadComplete = true
            return
          }
          console.log('getWallpaperList', res)
          // this.bingWallpaperList = res.data
          this.bingWallpaperList = this.bingWallpaperList.concat(res.data)
        })
        .finally(() => {
          this.wallpaperLoading = false
        })
        .catch(e => {
          console.log('e', e)
        })
    },
    getGridWallpaperList(){
      if (this.gridWallpaperLoading) {return}
      this.gridWallpaperLoading = true
      console.log('getGridWallpaperList', this.gridWallpaperList.length)
      if (this.gridWallpapersLoadComplete) {return}
      gridWallpaperCollection
        .skip(this.gridWallpaperList.length)
        .get()
        .then(res=>{
          if (res.data.length === 0){
            this.gridWallpapersLoadComplete = true
            return
          }
          console.log('res.data', res.data)
          this.gridWallpaperList = this.gridWallpaperList.concat(res.data)
          // this.gridWallpaperList = res.data
        })
        .finally(() => {
          this.gridWallpaperLoading = false
        })
    },
    // ------END 加载壁纸列表 ------
    initObservation(){
      console.log('---initObservation')
      const root = document.querySelector(".wallpaper-modal .tab")
      const reference = document.querySelector(".wallpaper-model-reference")

      console.log('root', root)
      console.log('reference', reference)
      this.$observer = new IntersectionObserver(entries => {
        const item = entries[0] // 拿第一个就行，反正只有一个
        console.log('监听')
        if (item.isIntersecting)
          console.log("滚动到了底部，开始请求数据")
          this.loadWallpaper()
        }, {
          root
        }).observe(reference) // 监听参照元素
      }
  },
}
</script>

<style scoped lang="less">
.wallpaper-modal{
  // vertical-align: bottom;
  // line-height: 0;

  .preview-wrap{
    .preview-image{
      width: 320px;
      height: 180px;
      object-fit: cover;
      // display: inline-block;
    }
  }
  .tab{
    max-height: calc(100vh - 400px);
    overflow: scroll;

    /deep/ .ant-tabs-bar{
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 2;
    }
  }
  .pure-wrap {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 400px);

    .pure-color-card{
      // display: inline-block;
      // margin: 0 2px 4px 4px;
      // vertical-align: bottom;
      // font-size: 12px;
      text-align: right;
      line-height: 30px;
      width: 80px;
      height: 30px;
      // transition: all 0.3s cubic-bezier(0.930, 0.000, 0.870, 0.600);
      transition: all 1s cubic-bezier(1.000, -0.330, 0.870, 0.600);
      // vertical-align: baseline;
      // color: #eee;
      // z-index: -1;
      &:hover{
        // border: 1px solid #ccc;
        // inset
        z-index: 1;
        box-shadow: 0px 0px 3px 1px rgba(61, 61, 61, 0.3);
        transform: scale(1.3);
        transition: all 0.3s ease-out;
        // box-shadow: 0px 0px 5px 0px inherit;
        cursor: pointer;
        border-radius: 2px;
      }
      span{
        margin-top: 8px;
        display: inline-block;
        padding-right: 2px;
        font-size: 10px;
        line-height: 30px;
        vertical-align: middle;
        color: #fff;
        text-shadow: #333 1px 0 10px;
        // transform: scale(0.9);
        letter-spacing: 2px;
        // mix-blend-mode: difference;
      }
    }
  }

  .thumbnail-wrap{
    min-height: 50vh;
    padding: 0 3px;
    width: 100%;
    img{
      width: 100px;
      height: 100px;
      object-fit: cover;
      transition: box-shadow 0.1s;

      &:hover{
        // outline: #2b5a83 solid 2px ;
        // box-shadow: inset 1px 1px 5px 0px rgba(61, 61, 61, 0.26);
        // box-shadow: 1px 0px 5px 0px rgba(61, 61, 61, 0.60);
        box-shadow: 0px 0px 1px 2px #2b5a83;
        cursor: pointer;
      }
    }
  }

  .wallpaper-model-reference{
    position: relative;
    width: 1px;
    height: 1px;
    top: -200px;
  }
}
</style>