<template>
  <!-- v-if="appState.appConfig.widgetStatus.dida.visible" -->
  <div class="iframe-dida" @click.right="onRightClick" v-if="!appState.isDevMode">
    <iframe width="100%" height="100%" src="https://www.dida365.com/webapp/#q/all/tasks" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  inject: ['appState'],
  props: {
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  methods: {
    onRightClick(){
      this.appState.activeCard = this
    }
  }
}
</script>

<style>
.resizing iframe{
   pointer-events: none;
}
.iframe-dida{
  /* padding: 20px 10px 10px 10px; */
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
</style>