<template>
  <!-- :style="appStyle" -->
  <!--   -->
  <!-- @dblclick="onDbclick" -->
  <div
    class="app"
    :class="{'pure-mode': isPureMode}"
    @drop="onDrop"
    @dragenter.prevent
    @dragover.prevent
  >
    <!-- :style="{'background-color':!appState?.curWallpaper?.sourceUrl && appState.curWallpaper?.hex || 'unset'}"> -->
    <div class="bg-wrap" :class="{blur: appState.appConfig.wallpaperConfig.isBlur}">
      <img
        class="wallpaper"
        :src="appState.appConfig.wallpaperConfig.curWallpaper.sourceUrl"
        alt="">
    </div>
    <!-- 右键菜单 -->
    <v-contextmenu ref="contextmenu" @show="onShow" @hide="onHide">
      <template v-if="appState.activeCard">
        <template v-if="appState.activeCard.contextmenuConfig">
          <v-contextmenu-item
            v-for="item in appState.activeCard.contextmenuConfig"
            @click="onItemClick(item)"
            :disabled="item.disabled"
            :key="item.title">
            {{ item.title }}
          </v-contextmenu-item>

          <v-contextmenu-item v-show="appState.activeCard.contextmenuConfig.length" divider></v-contextmenu-item>
        </template>
        <div v-for="item in commonContextmenuItems" :key="item.title">
          <!-- v-for="item in commonContextmenuItems" -->
          <!-- :key="item.title" -->
          <v-contextmenu-item
            v-if="item.key==='fix'"
            :divider="item.divider"
            @click="onCommonItemClick(item)"
            :disabled="item.disabled"
            :selected="!!appState.activeCard.selfLayoutInfo.static"
          >
            {{ item.title }}
          </v-contextmenu-item>
          <v-contextmenu-item
            v-else
            :divider="item.divider"
            @click="onCommonItemClick(item)"
            :disabled="item.disabled"
          >
            {{ item.title }}
          </v-contextmenu-item>
        </div>
      </template>

      <template v-if="!appState.activeCard">
        <v-contextmenu-item
          v-for="item in appContextmenuItems"
          :divider="item.divider"
          :disabled="item.disabled"
          :key="item.title"
          @click="appEventBusEmit(item.key)">
          {{ item.title }}
        </v-contextmenu-item>
        <!-- </template> -->
      </template></v-contextmenu>
    <!-- 顶部操作台 -->
    <TopOperate @dblclick="logDebugInfo"></TopOperate>
    <!-- grid系统的基本元件 -->
    <Grid v-contextmenu:contextmenu></Grid>
    <EditWebsiteModal ref="EditWebsiteModalRef"></EditWebsiteModal>
    <WallpaperSelectModal ref="WallpaperSelectModal"></WallpaperSelectModal>
    <Guide></Guide>
    <Help></Help>
    <TileControlBar></TileControlBar>
    <!-- <TabsSidebar v-if="appState.env === 'extension'"></TabsSidebar> -->
    <!-- <TabsSidebar ></TabsSidebar> -->

    <a class="record hover-effect" target="_blank" href="http://beian.miit.gov.cn/">
      赣ICP备19007313号
    </a>
    <!-- v-show="appState.widgetList.getvisible" -->
    <!-- <BookmarkEntry></BookmarkEntry> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Grid from './components/Grid.vue'
import TopOperate from './components/TopOperate/TopOperate.vue'
import Guide from './components/Guide/Guide.vue'
import Help from './components/Guide/Help.vue'
import TileControlBar from './components/TileControlBar/TileControlBar'
import * as storeUtil from './store/storeUtil.js'
import {appState,cloudApp, appEventBus} from './store/store'
import EditWebsiteModal from '@/components/TopOperate/EditWebsite/EditWebsiteModal.vue'
// import BookmarkEntry from '@/components/BookmarkEntry/BookmarkEntry'
import WallpaperSelectModal from '@/components/WallpaperSelectModal/WallpaperSelectModal.vue'
// import TabsSidebar from '@/components/TabsSidebar/TabsSidebar.vue'

import { provide, onMounted, ref, computed } from 'vue'


export default {
  provide () {
    return {
      App: this,
    }
  },
  setup(){
    provide('storeUtil', storeUtil)
    // provide('App', this)
    provide('appState', appState)
    provide('cloudApp', cloudApp)
    provide('appEventBus', appEventBus)

    let contextmenuItems = []

    const commonContextmenuItems = [
      { title: '锁定磁贴', key: 'fix' },
      { title: '删除磁贴', key: 'remove' },
    ]
    const appContextmenuItems = [
      { title: '添加站点', key: 'showAddWebsiteModal' },
      { title: '添加集合', key: 'addCollectionTile' },
      // { title: '更换壁纸', key: 'toggleWallpaper' },
      { title: '查看壁纸', key: 'downloadWallpaper' },
      // { title: '检查', key: 'openDevtool' },
    ]


    function logDebugInfo (){
      console.log('appState',appState )
    }

    appEventBus.on('contextmenuUpdate', (options) => {
      console.log('contextmenuUpdate', options)
      contextmenuItems = options
    })

    appEventBus.on('navitoCommunity', () => {
      window.open('https://support.qq.com/products/296845?')
    })

    const EditWebsiteModalRef = ref(null)
    onMounted(() => {
      console.log('app开始首次渲染')
      appEventBus.on('showEditWebsiteModal', (websiteTileCom) => {
        EditWebsiteModalRef.value.showModal(websiteTileCom)
      })
    })

    const appStyle = computed(() => {
      if (!appState.curWallpaper) {return ''}
      let result
      // if (appState.curWallpaperSourceUrl) {
      //   result = `background:center / cover no-repeat  url(${appState.curWallpaperSourceUrl});
      //   transition: background 1s;`
      // } else {
      //   result = `transition: background-color 1s;
      //           background-size: unset;
      //           background-repeat: repeat;
      //           background-color: ${appState.curWallpaper.hex};
      //           background-image: url(https://grid-new-tab-1256341180.file.myqcloud.com/public-assets/texture.png);
      //   `
      // }
      return result
    })

    return {
      contextmenuItems,
      commonContextmenuItems,
      appContextmenuItems,

      appState,
      logDebugInfo,

      EditWebsiteModalRef,
      appStyle,
      // 是否是精简模式
      isPureMode: ref(false)
    }
  },
  name: 'App',
  components: {
    Grid,
    TopOperate, EditWebsiteModal, WallpaperSelectModal, Guide, Help, TileControlBar
  },
  methods: {
    // 供子磁贴注册右键菜单
    onContextmenuUpdate(options){
      // console.log('options', options)
      this.contextmenuItems = options
    },
    // 组件专用按钮被点击时
    onItemClick(item){
      this.appState.activeCard['on' + item.key]?.()
    },
    // 通用按钮被点击时
    onCommonItemClick(item){
      const activeCard = this.appState.activeCard
      // console.log('item', item)
      // console.log('item.parent', activeCard.$parent.selfLayoutInfo)
      // const layout = this.appState.layout
      // console.log('f', layout.findIndex(item => item === activeCard.$parent.selfLayoutInfo))
      const strategies = {
        remove: async () => {
          // console.log('onCommonItemClick remove', activeCard.$parent.selfLayoutInfo)
          let result = true
          if (activeCard.beforeTileRemove) {
            result = await activeCard.beforeTileRemove()
          }
          result && appEventBus.emit('removeTile', activeCard.selfLayoutInfo.i)
        },
        fix: () => {
          activeCard.selfLayoutInfo.static = !activeCard.selfLayoutInfo.static
        }
      }
      strategies[item.key]()
    },
    onShow(){
      console.log('this.apps', this.appState.activeCard)
      // console.log('onShow')
      // this.isActive = true
    },
    onHide(){
      // console.log('onHide')
      this.appState.activeCard = null
      // this.isActive = false
    },
    // 生成grid自身的右键菜单
    updateContextmenu(){
      this.contextmenuItems = []
    },
    appEventBusEmit(eventName){
      appEventBus.emit(eventName)
    },
    onDrop(e){
      console.log('onDrop', e)
      e.preventDefault()
      // console.log('e.dataTransfer.getData()', e.dataTransfer.getData('text'))
      // console.log('dataTransfer.items', e.dataTransfer.items)
      // if (e.dataTransfer.items != null) {
      //   for (var i=0; i < e.dataTransfer.items.length; i++) {
      //     console.log('... items[' + i + '].kind = ' + e.dataTransfer.items[i].kind + ' ; type = ' + e.dataTransfer.items[i].type)
      //   }
      // }
      handleDataTransferItems(e.dataTransfer.items)
        function handleDataTransferItems (items) {
          console.log('items', items)
          for (var i = 0; i < items.length; i += 1) {
            var kind = items[i].kind
            var type = items[i].type
            console.log('type', type)
            // 逻辑开始
            if (kind == 'string') {
              if (type.indexOf('text/plain') != -1) {
                items[i].getAsString(function (str) {
                  // str是纯文本，该怎么处理，就在这里处理
                  console.log('str', str)
                  appEventBus.emit('showAddWebsiteModal', {address: str})
                })
              }
              // else if (type.indexOf('text/html') != -1) {
              //   items[i].getAsString(function (str) {
              //     // str是富文本，就在这里处理
              //     console.log('str', str)
              //   })
              // }
               else if (type.indexOf('text/uri-list') != -1) {
                items[i].getAsString(function (str) {
                  // str是uri地址，在这里进行处理
                  console.log('str', str)
                })
              }
            } else if (kind == 'file') {
              // 如果是图片
              if (type.indexOf('image/') != -1) {
                var file = items[i].getAsFile()
                // file就是图片文件对象，可以上传，或者其他处理
                console.log('file', file)
              }
            }
          }
        }
    },

    onDbclick(){
      // console.log('currentTarget', e)
      // if (e.srcElement.className?.indexOf('vue-grid-layout') === -1) {
      //   return
      // }
      // appEventBus.emit('focusOnSearchCom')
      // appEventBus.emit('playSound', 'playSearchFocusAudio')

      this.isPureMode = !this.isPureMode
    },
  }
}
</script>
<style lang="less">
@import '~ant-design-vue/dist/antd.less'; // 引入官方提供的 less 样式入口文件
@import './styles/custom.less'; // 用于覆盖上面定义的变量

.app{
  // height: 100vh;
  // overflow: auto;
  padding-top: 40px;
  // background:center / cover no-repeat  url(https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/wallpaper/2022-12-31%20%E4%B8%A8%E5%B9%BF%E5%B7%9E%E5%A1%94%20%283%29.jpg);
  background-image: url(https://grid-new-tab-1256341180.cos.ap-shanghai.myqcloud.com/wallpaper/%E5%B9%BF%E5%B7%9E%E5%A1%94.jpg);
    background-position: center;
    background-size: cover;
}
.bg-wrap{
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  // z-index: -1;

  img{
    // display: none;
    pointer-events: none;
    user-select: none;
  }
  // right: 0px;
  // bottom: 0px;
}
.blur{
  // filter: blur(10px);
  // transform: scale(1.1);
  img{
    filter: blur(15px);
    transform: scale(1.1);
  }
  // width: 100vw;
  // height: 100vh;

  // overflow: hidden;
  // top: -10% !important;
  // left: -10% !important;
  // right: -50px !important;
  // bottom: -50px !important;E
  // width: 120vw  !important;
  // height: 120vh  !important;
}
.wallpaper{
  // display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: all 0.3s;
  // background-color: #0f0;
  // object-position: -50% -100%;
}
.record{
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  // width: 200px;
  // text-align: center;
  padding: 2px 4px;
  bottom: 8px;
  // left: calc(50vw - 100px);
  right: 6px;
  color: var(--font-fourth-color);
  z-index: 1;

  &:hover{
    color: var(--font-fourth-color);
  }
}
.pure-mode{
 .vue-grid-item{
   opacity: 0.2;
 }
}
</style>