<template>
  <!-- 全局卡片公共父级 -->
  <!-- :class="{active: isActive}" -->
  <div
    class="card"
    :class="{
      active: appState.activeCard && (appState.activeCard.selfLayoutInfo === selfLayoutInfo),
      fullscreen: isFullscreen
    }"
    @click.right="onRightClick"
    @mouseenter="onMouseenter"
  >

    <Header v-if="type !== 'websiteBox'" @dblclick="toggleFullscreenMode"></Header>
    <div class="card-inner" >
      <!-- v-if="!selfLayoutInfo.isDynamicCom"  -->
      <component v-if="type!=='bookmarks'" ref="child" :is="type" :selfLayoutInfo="selfLayoutInfo"></component>
      <Collection v-else ref="child" :selfLayoutInfo="selfLayoutInfo"></Collection>
      <!-- <component v-else ref="child" :is="'AsyncComponent'" :type="type"></component> -->
    </div>
  </div>
</template>

<script>
import Header from './Header'
import Search from '../search/Search.vue'
import Dida from '../dida/Dida.vue'
import Weather from '../weather/Weather.vue'
import TilePlus from '../TilePlus/TilePlus.vue'
import WebsiteBox from '../websiteBox/websiteBox.vue'
// import AsyncComponent from '@/components/AsyncComponent/AsyncComponent'
import Trending from '@/components/Trending/Trending'
import Collection from '@/components/Collection/Collection'

export default {
  // provide () {
  //   return {
  //     $$card: this,
  //   }
  // },
  inject: ['appState', 'appEventBus'],
  data() {
    return {
      isFullscreen: false
    }
  },
  props: {
    // 磁贴类型
    type: {
      type: String,
      default: ''
    },
    selfLayoutInfo: {
      type: Object,
      default: () => {return {}}
    }
  },
  computed: {
    self () {
      console.log('this', this)
      return this
    }
  },
  components: {
    Header, Search, WebsiteBox, Dida, TilePlus, Trending, Collection, Weather
  },
  created () {
  },
  methods: {
    onRightClick(e){
      const child = this.$refs.child
      const childRightClickHandler = child.onRightClick
      if(!window.$$VContextmenuCounteract) {
        this.appState.activeCard = child
      }
      if (childRightClickHandler) {
        child.onRightClick.call(child, e)
      }
      console.log('child', child.contextmenuConfig)
      // this.updateContextmenu()
    },
    toggleFullscreenMode(){
      const parentClassList = this.$parent.$refs.item.classList
      if (parentClassList.contains('fullscreen')) {
        parentClassList.remove('fullscreen')
        this.appState.curFullscreenCard = null
      } else {
        parentClassList.add('fullscreen')
        this.appState.curFullscreenCard = this
      }
    },
    quitFullscreenMode(){
      const parentClassList = this.$parent.$refs.item.classList
      if (parentClassList.contains('fullscreen')) {
        parentClassList.remove('fullscreen')
        this.appState.curFullscreenCard = null
      }
    },
    onMouseenter(){
      this.appEventBus.emit('playSound', 'playTileHoverAudio')
    }
  }
}
</script>

<style lang="less" scoped>
.card{
  height: 100%;
  // transition: background ease-in-out 0.2s, box-shadow ease-in-out  0.2s;
  border: 2px solid transparent;
  border-radius: 8px;

  // overflow: hidden;
  // background-color: rgba(100,100,100,0.1);
  // background-color: rgba(241,241,241,0.9);
  // background-color: #DBE4EA;

  // background-color: rgba(241,241,241,0.9);
  // box-shadow: 0 0  3px 3px rgba(0,0,0,0.05);

  &:hover{
    // border: 2px solid #B2C5E6;
    // background-color: rgba(55, 54, 54, 0.25);
    // backdrop-filter: blur(6px);
    // box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.25);

    // 造成异常的文字模糊，原因未知
    /deep/ .card-header .draggable-handle{
      // animation: imagefadein ease-in-out 0.3s forwards;
      opacity: 1;
    }
  }
  @keyframes imagefadein {

    /*设置内容由显示变为隐藏*/
    0% {
      /* transform: scale(1); */
      opacity: 0.1;
    }
    // 10% {
    //   /* transform: scale(1); */
    //   opacity: 0.5;
    // }
    // 20% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.1;
    // }
    // 30% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.5;
    // }
    // 40% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.1;
    // }
    50% {
      /* transform: scale(1.06); */
      opacity: 0.7;
    }
    // 60% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.1;
    // }
    // 70% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.5;
    // }
    // 80% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.1;
    // }
    // 90% {
    //   /* transform: scale(1.06); */
    //   opacity: 0.5;
    // }
    100% {
      /* transform: scale(1); */
      opacity: 0.7;
    }
  }

  &.active{
    // border: 2px solid #4A72B8;
    box-shadow: 0 0 1px 2px #4A72B8;
  }
}
// .card-blur{
//   backdrop-filter: blur(15px);
// }
.card-inner{
  position: relative;
  height: 100%;
}
</style>