/*eslint-disable*/
import { CollectionStore, safeSetItem, getInsAllData } from '@/service/localforage'
import { appEventBus } from '@/store/store'
import { notification, Button } from 'ant-design-vue'

// 维护的所有collection信息
export let collectionsComStore = {}

export let db
let appState
let co
export let localCollectionsStore
export async function initCollectionService (dbIns, appStatePara) {
  db = dbIns
  appState = appStatePara
  co = db.collection('user-collections')
  console.log('初始化集合服务', co)
}

export async function recoverCollectionLocalData () {
  localCollectionsStore = await getInsAllData(CollectionStore)
  console.log('recoverCollectionLocalData', localCollectionsStore)
}

/**
* 新增集合，更新集合数据
*
* @param {Object} params 收藏夹的基本数据
* @return {undefined}
*/
export function saveCollection (params) {
  console.log('saveCollection', params)
  // 本地数据操作
  safeSetItem(params._id, params, CollectionStore)
  // 云端数据操作
  if (appState?.isLogin) {
    console.log('co.doc set')
    let setParams = { ...params }
    delete setParams._id
    co.doc(params._id).set(setParams)
  }
}
/**
* removeCollection
*
* @param {string} i 唯一标识
* @return {undefined}
*/
export async function removeCollection (i) {
  console.log('removeCollection')
  if (appState?.isLogin) {
    // 云端数据操作
    await co.doc(i).remove()
  }
  // 本地数据操作
  appEventBus.emit('removeTile', i)
  CollectionStore.removeItem(i)
  delete CollectionStore[i]
}

/**
 * 获取collection数据，并根据需要刷新本地缓存
 *
 * @param {string} id collention的唯一标识
 * @param {boolean} update=true 是否同时更新本地缓存数据
 * @return {undefined}
*/
export async function getCollection (i, update = true) {
  return co?.doc(i).get().then(res => {
    let collection = res.data[0]
    if (!collection) {
      // notification.warn({
      //   message: `同步集合${i}数据失败`,
      //   description: '错误码：B1001。可能因网络原因造成同步失败，有关此问题的详细信息，请移步反馈区询问。',
      // })
      return
    }
    if (update) {
      safeSetItem(collection._id, collection, CollectionStore)
    }
    return collection
  })
}

/**
 * 批量移动元素
 *
 * @param {string} type home: 移动至主屏，new: 传值含义为移动至集合, other: 移动至其他集合
 * @param {string} index? home: 移动至其他集合，目标集合的唯一标识
 * @return {undefined}
*/
export function moveBatchElements(){

}