<template>
  <a-card hoverable style="width: 220px, height: 300px" size="small">
    <!-- <template #cover>
      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
      />
    </template> -->
    <template class="ant-card-actions" #actions>
      <!-- <setting-outlined key="setting" /> -->
      <!-- <edit-outlined key="edit" /> -->
      <!-- v-model:checked="checked" @change="onChange" -->
      <EllipsisOutlined />

      <a-switch
        @change="(checkedValue, event) => {onWidgetVisibleChange(checkedValue, detail.key)}"
        v-model:checked="appState.appConfig.widgetStatus[detail.key].visible"
        size="small"/>
    </template>
    <a-card-meta :title="detail.name" :description="detail.description || '-'">
      <template #avatar>
        <a-avatar :src="detail.iconHref" />
      </template>
    </a-card-meta>
  </a-card>
</template>

<script>
import {onWidgetVisibleChange} from '@/service/widget'
import { EllipsisOutlined } from '@ant-design/icons-vue'
export default {
  inject: ['appState'],
  components: {
    EllipsisOutlined,
  },
  props: {
    detail: {
      type: Object,
      default: () => {return {}}
    }
  },
  methods: {
    onWidgetVisibleChange
  }
}
</script>

<style>

</style>